angular.module('regFormApp').directive('ctDonation', ['CartService', '$rootScope', function (CartService, $rootScope) {
    var currencySymbol = CartService.getCurrencySymbol();
    var charities = regFormApp.resources.eventInfo.charities;
    var defaultDonationAmounts = regFormApp.resources.eventInfo.defaultDonationAmounts;
    return {
      restrict: 'A',
      require: 'ngModel',
      // Isolate scope doesn't seem to work in conjunction with controller.
      scope: {},
      // The controller stuff gives us a private scope we can use for the "donation.*" variables in our template.
      controllerAs: 'donation',
      bindToController: true,
      controller: function () {
        this.defaultDonation = defaultDonationAmounts;
        this.amount = 0;
        this.name = '';
        this.message = '';
        this.canChangeName = true;
        this.showAmountError = false;
        this.charities = charities;
        this.types = {
          self: 'myself',
          anon: 'anonymous',
          other: 'other'
        };
        this.type = Object.keys(this.types)[2];
        this.selected = 0;
        this.orderedTypes = ['other', 'anon', 'self'];
      },
      template:
        '<div class="col-xs-12 charityContainer" ng-style="{ \'margin-top\' : (!$first) ? \'30px\' : \'0\' }" ng-repeat="charity in donation.charities">' +
          '<div class="innerContainer" ng-if="charity.regOptions.includes(selectedRegOptionId) || !charity.regOptions || !charity.regOptions.length">' +
            '<div class="col-xs-2 text-align-center" style="min-width: 100px">' +
              '<img width="75" height="75" src="{{charity.image_url}}"/>' +
              '<div style="padding-left:32px;">' +
                '<input type="radio" name="select_donation_{{charity.id}}" ng-value="{{charity.id}}" ng-model="donation.selected">' +
              '</div>'+
              '<div style="font-size:16px; padding-left: 14px">Select</div> ' +
            '</div>' +
            '<div class="col-xs-8 col-sm-9">' +
              '<div style="font-size:16px; font-weight:bold;">{{charity.name}}</div>' +
            '</div>' +
            '<div class="col-xs-8 col-sm-9">{{charity.description}}</div>' +
            '<div class="col-xs-12" ng-if="donation.selected == charity.id">' +
              '<div class="col-xs-12">' +
                '<hr />' +
                '<div class="col-xs-3">On Behalf of</div>' +
                '<div class="col-xs-9">' +
                  '<span ng-repeat="key in donation.orderedTypes">' +
                  '<label class="labelPadding"> '+
                    '<input type="radio" name="donation.type" ng-model="donation.type" ng-value="key"/>'+
                    '<span class="lbl labelPadding"> {{donation.types[key].toUpperCase()}} </span> ' +
                  '</label>' +
                  '</span>' +
                '</div>' +
              '</div>' +
              '<div class="col-xs-2">&nbsp;</div>' +
              '<div class="col-xs-9">' +
                '<input type="text" ' +
                  'ng-if="donation.type !== \'anon\'" ' +
                  'name="donation.name"' +
                  'placeholder="Name"' +
                  'ng-model="donation.name" ' +
                  'ng-disabled="!donation.canChangeName" ' +
                  'class="textInput" ' +
                  'maxlength="128"/>' +
                '<input type="textarea" ' +
                  'placeholder="Message"' +
                  'name="donation.message"' +
                  'ng-model="donation.message" ' +
                  'class="textInput" ' +
                  'maxlength="512"/>' +
                '<p style="font-weight:bold">Amount</p>' +
                '<span>' +
                  '<div style="display: flex">' +
                    '<div class="smallInput" style="display: flex; flex-direction: column; justify-content: center; text-align:center; width: 20px !important; margin-top: 5px; margin-bottom: 5px">{{::currencySymbol}}</div>' +
                    '<input type="text" ' +
                    '      name="donation.amount"' +
                    '      ng-model="donation.amount" ' +
                    '      class="tx donationOtherAmount" ' +
                    '      ct-min-value="{{min}}"/>' +
                  '</div>' +
                  '<div id="amount-error" ng-show="donation.showAmountError" class="errorsInit" style="color:#ff0000; display: inline-block; margin-left: 10px">Amount cannot be less than minimum predefined amount.</div>' +
                '</span>' +
                '<br>' +
                '<span ng-if="!charity.customDonations.length">' +
                '  <span ng-repeat="amount in donation.defaultDonation">' +
                '    <input type="button" ng-click="donation.amount = amount" value="{{::currencySymbol}}{{::amount}}" name="donation.amount">' +
                '  </span>'+
                '</span>'+
                '<span ng-if="charity.customDonations.length">' +
                '  <span ng-repeat="customDonation in charity.customDonations">' +
                '    <div>' +
                '      <span style="display: inline-block;text-align: left;">' +
                '        <input type="button" class="customDonationButton" ng-click="donation.amount = customDonation.value" value="{{::currencySymbol}}{{::customDonation.value}}" name="donation.amount">' +
                '      </span>' +
                '      <p style="display: inline-block;width: 38%;text-align: left;margin-left: 20px;">{{customDonation.name}}</p>' +
                '    </div>' +
                '  </span>' +
                '</span>'+
                '<div style="width: 100%; text-align: center" ct-donation-transparent charity-id="charity.id"></span>'+
              '</div>' +
            '</div>' +
            '<div ng-if="!$last" data-onlast class="separator"></div>' +
          '</div>' +
        '</div>',
      link: function (scope, elem, attrs, ngModel) {
        scope.currencySymbol = CartService.getCurrencySymbol();
        var MIN = 0;
        var NON_NULL_MIN = 1;
        var entryID = scope.$parent.$parent.$parent.$parent.$parent.currentEntry;
        scope.donation.name = CartService.getEntryName(entryID);
        scope.setDonationPropertyToEntry = function(key, value) {
          var index = scope.$parent.$parent.$parent.$parent.$parent.cart.entries.findIndex(function(e) {return e._id === entryID;});
          if (index !== -1) {
            scope.$parent.$parent.$parent.$parent.$parent.cart.entries[index][key] = value;
            scope.$parent.$parent.$parent.$parent.$parent.entries[entryID][key] = value;
          }
        };
        scope.min = MIN;
        attrs.$observe('selectedRegOptionId', function () {
          scope.selectedRegOptionId = attrs.selectedRegOptionId
        });
        // @performance Change these to ng-change instead of using a watch.
        scope.$watch('donation.amount', function(newValue) {
          if (scope.donation.amount) {
            scope.donation.showAmountError = scope.donation.amount < NON_NULL_MIN;
            ngModel.$setViewValue(scope.donation.amount);
          }
          if (scope.donation.amount === 0) {
            ngModel.$setViewValue(scope.donation.amount);
          }
        });
        scope.$watch('donation.message', function(newValue) {
          scope.setDonationPropertyToEntry('donationMessage', newValue);
        });
        scope.$watch('donation.name', function(newValue) {
          scope.setDonationPropertyToEntry('donationName', newValue);
        });
        scope.$watch('donation.selected', function(newValue) {
          const selected = charities.find((charity) => charity.id == newValue)
          if (selected) {
            scope.setDonationPropertyToEntry('donationFeeAllocation', selected.fee_allocation);
            scope.setDonationPropertyToEntry('charityId', selected.id);
            scope.setDonationPropertyToEntry('donationSelectedName', selected.name);
          }
          scope.donation.amount = 0
          scope.donation.name = ''
          scope.donation.message = ''
          scope.donation.canChangeName = true
          scope.donation.showAmountError = false
          scope.donation.type = Object.keys(scope.donation.types)[2]
        })
        scope.$watch('donation.type', function(newValue) {
          if (newValue.toString() === 'self') {
            scope.donation.name = CartService.getEntryName(entryID);
          } else {
            scope.donation.name = '';
          }
          scope.setDonationPropertyToEntry('donationType', newValue.toString());
        });
        ngModel.$validators.minimum = function(newValue) {
          // We had a bug where a user could allow pre-set amounts smaller than the minimum.
          // The contains() lets us allow those to be selected.
          var amount = parseInt(newValue === '' ? 0 : newValue, 10);
          if (amount < NON_NULL_MIN && amount !== MIN) {
            scope.donation.showAmountError = true;
          }
          return amount === MIN || amount >= scope.min;
        };
      }
    };
  }]);