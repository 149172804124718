/* Directives */
var regFormAppControllers = angular.module('regFormApp');

/* Email directive */
regFormAppControllers.directive('ctEmail', function () {
  var EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return {
    require: 'ngModel',
    link: function (scope, elem, attr, ngModel) {
      ngModel.$validators.email = function emailValidator(modelValue, viewValue) {
        var value = modelValue || viewValue || "";
        return value && EMAIL.test(value);
      };
      ngModel.$parsers.push(angular.lowercase);
      ngModel.$formatters.push(angular.lowercase);
    }
  };
});

regFormAppControllers.directive('roWithTransparent', ['CartService', '$compile', function (CartService, $compile) {
  return {
    restrict: 'A',
    scope: {
      optionPrice: '=',
      hideFlat: '=',
      hidePrice: '=',
    },
    template: function(elem, attr) {
      return "<span ng-show=\"calculated\">+</span>{{calculated}} <span ng-show=\"calculated\" translate=\"SERVICE-FEE\"></span>"
    },
    link: function(scope, element, attrs) {
      const fees = regFormApp.resources.fees
      //only first reg option should pring flat part
      if ((+scope.optionPrice) && scope.hidePrice === '0') {
        
        const customFees = regFormApp.resources.customFees.REG_OPTION
        let customFeeSum = 0
        if (customFees) {
            customFeeSum = customFees.reduce(function(accumulator, customFee) {
            return accumulator + (+(scope.optionPrice * customFee.rate) + (scope.hideFlat ? 0 : +customFee.flat))
          }, 0);
        }

        const serviceFee = +((+scope.optionPrice + +customFeeSum ) * +fees.rate) + (scope.hideFlat ? 0 : +fees.flat)
        scope.calculated = CartService.formatPrice(serviceFee + +customFeeSum) + ' '
      } else {
        scope.calculated = ''
      }
    }
  }
}]);

regFormAppControllers.directive('sfProductWithTransparent', ['CartService', '$compile', function (CartService, $compile) {
  return {
    restrict: 'A',
    scope: {
      hideFlat: '=',
      cart: '=',
      productId: '=',
      entry: '=',
    },
    template: function(elem, attr) {
      return "<span ng-show=\"calculated\">+</span>{{calculated}} <span ng-show=\"calculated\" translate=\"SERVICE-FEE\"></span>"
    },
    link: function(scope, element, attrs) {

      function calcFlatToAdd(flatToAdd) {
        let flatToAddNew = flatToAdd
        
        let itemWithFee = CartService.getStorefrontItemWithFee()
        //list all storefront items in a cart
        const storefrontItemsInCart = scope.cart.entries[0].items.filter(x => x.storeFrontProductID)
        if (storefrontItemsInCart) {
    
          // check if current item is still added to cart
          // it can happen that user can add product to a cart and then remove it, so we need to remove the
          // reference to it
          const itemIsStillInCart = itemWithFee ? storefrontItemsInCart.find(x => x.storeFrontProductID === itemWithFee) : false
    
          if (!itemIsStillInCart) {
    
            CartService.setStorefrontItemWithFee(null)
            itemWithFee = null
          }
    
          if (!itemWithFee) {
            CartService.setStorefrontItemWithFee(scope.productId)  
          } else {
            if (itemWithFee !== scope.productId) {
              flatToAddNew = 0
            }
          }
        }
        return flatToAddNew
      }

      const fees = regFormApp.resources.fees

      scope.$watch('cart.entries', function(newEntries, oldEntries) {
        const wanstTransparent = regFormApp.resources.eventInfo.wantsTransparentPricing
        if (wanstTransparent) {
          let total = 0
          newEntries.forEach(entry => {
            entry.items.forEach(item => {
              if (item.storeFrontProductID) {
                total += +item.price;
              }
            });
          });
          let flatToAdd = 0
          // this means that we should add flat to calculated service fee for product
          // but only for first entry, and only for one product
          const currentEntry = newEntries.find(x => x._id === scope.entry._id)
          const item = currentEntry.items.find(x => x.storeFrontProductID === scope.productId)
          if (total === +scope.cart.subtotal || +scope.cart.subtotal === 0) {
            if (scope.productId) {
              if (item) {
                let flatToAdd = scope.hideFlat ? 0 : +fees.flat
                flatToAdd = calcFlatToAdd(flatToAdd)
                const productFee = (+item.price * parseFloat(fees.rate)) + flatToAdd
                scope.calculated = CartService.formatPrice(productFee)
              } else {
                scope.calculated = ''
              }
            } else {
              scope.calculated = ''
            }
          } else {
            if (item) {
              const productFee = +item.price * parseFloat(fees.rate)
              scope.calculated = CartService.formatPrice(productFee)
            } else {
              scope.calculated = ''
            }
          }
        } else {
          scope.calculated = ''
        }
      });
    }
  }
}]);

regFormAppControllers.directive('ctDonationTransparent', function () {
  return {
    restrict: 'A',
    scope: {
      charityId: '=',
    },
    template: function(elem, attr) {
      return "{{charityText}}"
    },
    link: function(scope, element, attrs) {
      const charity = regFormApp.resources.eventInfo.charities.find(x => +x.id === +scope.charityId)
      if (charity && charity.wants_transparent_pricing === '1') {
        scope.charityText = '5% processing fee will be added to donation'
      } else {
        scope.charityText = ''
      }
    }
  }
});

/* postalCode code directive */
regFormAppControllers.directive('ctPostalCode', function postalCode() {
  var ZIP = /^[0-9]{5}?$/;
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      postalCode: '=ngModel',
      countryCode: '=postalCodeCountry'
    },
    link: function (scope, elem, attr, ngModel) {
      ngModel.$validators.postalCode = function postalCodeValidator(modelValue, viewValue) {
        if (scope.countryCode !== "US") {
          // Not USA, always valid. We aren't going to try and validate everything in the world.
          return true;
        }
        return ZIP.test(modelValue || viewValue);
      };
    }
  };
});

regFormAppControllers.directive('ctPhone', [function () {
    return {
      restrict: 'C',
      require: ['ngModel', '^form'],
      scope: {
        phone: '=ngModel',
        id: '@',
        phoneRequired: '=',
      }, 
      template: function(elem, attr) {
        if (typeof attr.phoneRequired === 'undefined') {
          throw "ct-phone element must have phone-required attribute set";
        }
        return '<div class="ct-phone-code input-group-btn" ng-model="phone" id="{{::id}}Code"></div>' +
               '<input type="tel" class="ct-phone-number tx form-control fullWidth" ' +
               'ng-model="phone.number" phone-code="{{phone.code}}" name="number" ' +
               'phone-required="{{phoneRequired}}" '  + 
               'id="{{::id}}Number" />';
      },
      compile: function compile(tElement, tAttrs) {
        tElement.addClass('input-group phone-group');
      }
    };
  }]);

regFormAppControllers.directive('ctUsat',  ['UsatService', '$compile', function (UsatService, $compile) {
  return {
    restrict: 'CEA',
    require: [ '^form'],
    scope: false,
    link: function (scope, elem, attr) {
      scope.$watch("entries[currentEntry].dob",function(dob) {
        if (dob) {
          var usatForm = scope.regForm.entryForm.regUSATform;
          currentEntry = scope.currentEntry;
          const entryObj = scope.entries[currentEntry]
          if (entryObj.regOptionID) {
            var response = UsatService.getUsatOptions(dob, entryObj.regOptionID)
            .then(function(response) {
              var d = $compile(response)(scope);
              elem.html(d);
              scope.entries[currentEntry].usatOption     = 'EXISTING';
              scope.entries[currentEntry].usatMembership = 0;
              usatForm.$invalid                          = true;
            });
          }
        }
      });

      scope.$watch("entries[currentEntry].regOptionID",function(regOptionID) {
        if (regOptionID) {
          var usatForm = scope.regForm.entryForm.regUSATform;
          const currentEntry = scope.currentEntry
          const entryObj = scope.entries[currentEntry]
           if (entryObj.dob) {

            var response = UsatService.getUsatOptions(entryObj.dob, regOptionID)
            .then(function(response) {
              var d = $compile(response)(scope);
              elem.html(d);
              scope.entries[currentEntry].usatOption     = 'EXISTING';
              scope.entries[currentEntry].usatMembership = 0;
              usatForm.$invalid                          = true;
            });
          }
        }
      });
    }
  };
}]);

regFormAppControllers.directive('ctUsatWaiverText',
  ['UsatService', 'LoaderService', '$compile', function (UsatService, LoaderService, $compile) {
  return {
    restrict: 'CEA',
    require: [ '^form'],
    scope: false,
    link: function (scope, elem, attr) {
      var usatForm = scope.regForm.entryForm.regUSATform;
      currentEntry = scope.currentEntry;

      scope.$watch("entries[currentEntry].usatOption",function(membershipID) {
        LoaderService.show();
        //clear all currently populated waivers
        Array.from(document.getElementsByClassName("usat-waiver-checkbox")).forEach(
          function(element, index, array) {
            //fetch id of checkbox
            var inputElement = document.getElementById(element.id);

            //set singature as invalid
            usatForm[angular.element(inputElement)[0].name].$setValidity('usatSignature', false);

            //clear checked state
            delete scope.entries[currentEntry][element.name];

            //fetch id of signature input in order to clear it
            var usatSignatureID         = angular.element(inputElement).attr('usat-signature-id')
            var guardianUsatSignatureID = angular.element(inputElement).attr('guardian-usat-signature-id')
            var guardianUsatDob         = angular.element(inputElement).attr('guardian-usat-dob')
            var textField               = document.getElementById(usatSignatureID);
            angular.element(textField).text('');
            delete scope.entries[currentEntry][usatSignatureID];
            delete scope.entries[currentEntry][guardianUsatSignatureID];
            delete scope.entries[currentEntry][guardianUsatDob];

            //clear waiver versions
            var versionPropertyName = element.name + 'Version';
            delete scope.entries[currentEntry][versionPropertyName];

            //fetch its ng model in order to remove bind from form controller
            var ngModel = angular.element(inputElement).controller("ngModel");
            usatForm.$removeControl(ngModel);
          }
        );

        //set usat form to invalid state in order to disable next button
        usatForm.$invalid = true;
           
         var response = UsatService.getWaiverForMembership(membershipID)
        .then(function(response) {
          var d = $compile(response)(scope);
          elem.html(d);

          if (membershipID != 'EXISTING') {
            const classes = [
              "usat-waiver-checkbox",
              "guardian-usat-dob"
              ]

            classes.map(oneClass => {
              Array.from(document.getElementsByClassName(oneClass)).forEach(
              function(element, index, array) {
                var myElement = document.getElementById(element.id);
                var ngModel   = angular.element(myElement).controller("ngModel");
                usatForm.$addControl(ngModel);    
              });  
            })
          }
        });
      });
    }
  };
}]);

regFormAppControllers.directive('ctPhoneCode', ['RegionService', function (RegionService) {
    return {
      restrict: 'C',
      scope: {
        phone: '=ngModel',
        id: '@'
      },
      template:
              '<input id="{{::id}}CountryID" type="hidden" name="countryID" ng-model="phone.countryID">' +
              '<input id="{{::id}}Code" type="hidden" name="code" ng-model="phone.code">' +
              '<button id="{{id}}Button" type="button" class="btn select-styled dropdown-toggle phoneCode" ' +
              'data-toggle="dropdown">' +
              '<img class="flag flag-{{phone.countryID | lowercase}}">' +
              '<span>(+{{phone.code}})</span>' +
              '</button>' +
              '<ul class="dropdown-menu country-menu ct-phone-code-list">' +
              '<li ng-repeat="country in countries | orderObjectBy:\'order\' track by country.id">' +
              '<a href="#" data-country-id="{{country.id | lowercase}}" ' +
              'data-code="{{country.itu_code}}">' +
              '<img class="flag flag-{{country.id | lowercase}}">' +
              '{{country.name}} (+{{country.itu_code}})' +
              '</a>' +
              '</li>' +
              '</ul>',
      link: function (scope, elem, attrs) {
        RegionService.countries.then(function (countries) {
          scope.countries = countries;
        });
        elem.find('.ct-phone-code-list').on('click', 'a', function (event) {
          var code = $(this).data('code');
          var countryID = $(this).data('country-id');
          scope.$apply(function () {
            scope.phone.code = code;
            scope.phone.countryID = countryID;
          });
        });
      }
    };
  }]);

regFormAppControllers.directive('ctPhoneNumber', ['RegionService', function (RegionService) {
    return {
      restrict: 'CA',
      require: 'ngModel',
      scope: {
        number: '=ngModel',
        code: '@phoneCode',
        phoneRequired: '@',
      },
      link: function (scope, elem, attrs, ngModel) {
        function validate(number) {
          var isValid = RegionService.phoneValidate(attrs.phoneCode, number);
          ngModel.$setValidity('invalid', isValid);
          if (typeof scope.phoneRequired !== "undefined") {
            ngModel.$setValidity('required', isRequired(number));
          }
        }
        
        var isRequired = function isRequired(number) {
          var ret = scope.phoneRequired === '0' || number !== "";
          if(number === null) {
            ret = false;
          }
          return ret;
        };
        
        // If the phone code changes we need to validate for the new code.
        attrs.$observe('phoneCode', function () {
          var formatted = RegionService.phoneFormat(attrs.phoneCode, ngModel.$viewValue);
          ngModel.$setViewValue(formatted);
          ngModel.$render();
          validate(ngModel.$viewValue);
        });
        
        scope.$watch('phoneRequired', function() {
          ngModel.$validate();
        });
        
        if (typeof scope.phoneRequired !== "undefined") {
          ngModel.$validators.required = isRequired;
        }
        
        ngModel.$formatters.unshift(function ctPhoneNumberFormatter(number) {
          validate(number);
          return RegionService.phoneFormat(attrs.phoneCode, number);
        });
        ngModel.$parsers.unshift(function ctPhoneNumberParser(number) {
          validate(number);
          // There is some weird issue where if we don't defer this execution then
          // the first 1-3 characters never get disaplayed.
          scope.$evalAsync(function () {
            var formatted = RegionService.phoneFormat(attrs.phoneCode, number);
            if (formatted !== number) {
              ngModel.$setViewValue(formatted);
              ngModel.$render();
            }
          });
          return RegionService.phoneNormalized(attrs.phoneCode, number);
        });
      }
    };
  }]);

regFormAppControllers.filter('phoneWithCode', function () {
  return function (input) {
    return '+' + input.code + ' ' + input.number;
  };
});


/**
 * Create a link function for basic filter type directives.
 */
function ctFilterLinkConstructor(filter) {
  return function(scope, elem, attrs, ngModel) {
    ngModel.$parsers.push(function(oldValue) {
      var newValue = filter(oldValue);
      if (newValue !== oldValue) {
        ngModel.$setViewValue(newValue);
        ngModel.$render();
      }
      return newValue;
    });
    ngModel.$formatters.push(filter);
  };
}

regFormAppControllers.directive('ctDowncase', [function ($filter) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: ctFilterLinkConstructor(angular.lowercase)
  };
}]);

regFormAppControllers.directive('ctUpcase', [function ($filter) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: ctFilterLinkConstructor(angular.uppercase)
  };
}]);

regFormAppControllers.directive('ctUpfirstcase', [function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: ctFilterLinkConstructor(function(value) {
      if (typeof value === "undefined") {
        return value;
      }
      if (value === null) {
        return value;
      }
      return value.replace(/(^|\s)\S/g, function (m) {
        return m.toUpperCase();
      });
    })
  };
}]);


function ctGetCardType(cardNbr) {
  var VISA_CARD = /^4/;
  var MASTER_CARD = /^5[1-5]/;
  //Discover CC is disabled for Adyen, but we only commented it, in case if it will be enabled again.
  //var DISCOVER_CARD = /^6(?:011|22(?:1(?=[\ \-]?(?:2[6-9]|[3-9]))|[2-8]|9(?=[\ \-]?(?:[01]|2[0-5])))|4[4-9]\d|5\d\d)([\ \-]?)\d{4}\1\d{4}\1\d{4}$/;
  var AMEX_CARD = /^3[47]/;

  if (VISA_CARD.test(cardNbr)) {
    return 'visa_card';
  }
//  if (DISCOVER_CARD.test(cardNbr)) {
//    return 'discover_card';
//  }
  if (MASTER_CARD.test(cardNbr)) {
    return 'master_card';
  }
  if (AMEX_CARD.test(cardNbr)) {
    return 'amex_card';
  }
  return "";
}

/* Credit card number */
regFormAppControllers.directive('creditcardnumber', function () {
  var CREDIT_CARD_NUMBER = /\b(?:3[47]\d{2}([\ \-]?)\d{6}\1\d|(?:(?:4\d|5[1-5]|65)\d{2}|6011)([\ \-]?)\d{4}\2\d{4}\2)\d{4}\b/;
  return {
    require: 'ngModel',
    link: function (scope, elem, attr, ngModel) {
      ngModel.$validators.creditcardnumber = function validateCreditCardNumber(value) {
        // I don't like setting a global scope variable for card type, but this will do for now.
        if (!value || CREDIT_CARD_NUMBER.test(value) && ctGetCardType(value) !== "") {
          scope.cardType = ctGetCardType(value);
          return true;
        }
        scope.cardType = 'unknown';
        return false;
      };
    }
  };
});

/* Credit card exp date */
regFormAppControllers.directive('expdate', function () {
  var CREDIT_CARD_EXP_DATE = /^(1[0-2]|0[1-9]|\d)\/((2[0-9])?[2-9]\d[1-9]\d|[1-9]\d)$/;
  return {
    require: 'ngModel',
    link: function (scope, elem, attr, ngModel) {
      ngModel.$validators.expdate = function validateExpdate(modelValue, viewValue) {
        var value = (modelValue || viewValue);
        if (!angular.isString(value)) {
          return false;
        }
        var match = value.match(CREDIT_CARD_EXP_DATE);
        if (match === null) {
          return false;
        }
        var month = match[1];
        var year = match[2];
        if (year < 100) {
          year = parseInt(year, 10) + 2000;
        }
        var now = new Date();
        var curYear = now.getFullYear();
        var curMonth = now.getMonth() + 1; // js 0 indexes months!
        if (year < curYear) {
          return false;
        }
        if (year == curYear && month < curMonth) {
          return false;
        }
        if (year > curYear + 15){
            return false;  
        }
        if (month === 0){
           return false;
        }
        return true;
      };
    }
  };
});

regFormAppControllers.directive('lotteryexpdate', function () {
  var CREDIT_CARD_EXP_DATE = /^(1[0-2]|0[1-9]|\d)\/((2[0-9])?[2-9]\d[1-9]\d|[1-9]\d)$/;
  return {
    restrict: 'E',
    link: function(scope, elem, attr) {
      if(!regFormApp.resources.wantsLottery) {
        return;
      }
      /**
       * Check if lottery warning should be displayed
       *
       * @param entries
       * @param expDate
       */
      function checkLotteryDate(entries, expDate) {
        if(!expDate) {
          return;
        }
        var lottery = regFormApp.resources.lotteryData;
        var warn = false;
        var match = expDate.match(CREDIT_CARD_EXP_DATE);
        var year = match[2];
        if (year < 100) {
          year = parseInt(year, 10) + 2000;
        }
        var month = match[1] - 1;
        var expUnix = new Date(year, month, 30).getTime() / 1000;
        
        for(var i in entries) {
          var regOptionId = entries[i].regOptionID;
          if(!lottery[regOptionId]) {
            continue;
          }
          var selectionDate = lottery[regOptionId].selection_date_timestamp;
          if(expUnix < selectionDate) {
            warn = true;
            break;
          }
        }
        scope.warn = warn;
      }
      scope.$watch(attr.cardExpire, function(expDate) {
        checkLotteryDate(scope.$eval(attr.entries), expDate);
      });
      scope.$watch(attr.entries, function(entries) {
        checkLotteryDate(entries, scope.$eval(attr.cardExpire));
      });
    },
    template: '<div class="card-exp-lottery" ng-show="warn == true">{{ "LOTTERY-PAYMENT-WARNING" | translate }}</div>'
  };
});

/* Credit card security code */
regFormAppControllers.directive('cvv', function () {
  return {
    require: 'ngModel',
    scope: {
      cardNumber: '='
    },
    link: function (scope, elem, attr, ngModel) {
      scope.$watch('cardNumber', function(value) {
        ngModel.$validate();
      });
      // Remember whatever the last valid digits were, so if they are re-typing
      // their card number we don't automatically invalidate. 
      var digits = 3;
      ngModel.$validators.cvv = function (value) {
        switch (ctGetCardType(scope.cardNumber)) {
          case 'visa_card':
          case 'discover_card':
          case 'master_card':
            digits = 3;
            break;
            case 'amex_card':
            digits = 4;
            break;
          default:
            // Unknown card type, I guess we call any security code valid.
            return true;
        }
        var regExp = new RegExp('[0-9]{' + digits + '}');
        return angular.isString(value) && value.length == digits && regExp.test(value);
      };
    }
  };
});

/* Equals validation */
regFormAppControllers.directive('equals', function () {
    return {
        restrict: 'A', // only activate on element attribute
        require: '?ngModel', // get a hold of NgModelController
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel)
                return; // do nothing if no ng-model
            // watch own value and re-validate on change
            scope.$watch(attrs.ngModel, function () {
                validate();
            });

            // observe the other value and re-validate on change
            attrs.$observe('equals', function (val) {
                validate();
            });

            var validate = function () {
                // values
                var val1 = ngModel.$modelValue;
                var val2 = attrs.equals;

                // set validity
                if (typeof val1 !== 'undefined' && typeof val2 !== 'undefined') {
                  ngModel.$setValidity('equals', val1 === val2);
                }
            };
        }
    };
});

/**
 * Valiated required checkboxes. Make sure the model value equals the "yes" value.
 */
regFormAppControllers.directive('ctCheckboxRequired', [function () {
    return {
      restrict: 'CA',
      require: 'ngModel',
      scope: {
        ngTrueValue: '@',
      },
      link: function (scope, elem, attr, ngModel) {
        ngModel.$validators.checkboxRequired = function validateCheckboxRequired(modelValue, viewValue) {
          return scope.$eval(scope.ngTrueValue) === modelValue;
        };
      }
    };
  }]);

/**
 * Validates if number is integer
 */
regFormAppControllers.directive('ctInteger', function () {
  var INTEGER_REGEXP = /^\-?\d+$/;
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ngModel) {
      ngModel.$validators.integer = function (viewValue) {
        return typeof viewValue === "undefined" || INTEGER_REGEXP.test(viewValue) || viewValue === "";
      };
    }
  };
});

regFormAppControllers.directive('ctMinValue', function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ngModel) {
      ngModel.$validators.minValue = function (viewValue) {
        return typeof viewValue === "undefined" || parseFloat(viewValue) >= attrs.ctMinValue || viewValue === "";
      };
    }
  };
});

regFormAppControllers.directive('ctMaxValue', function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ngModel) {
      ngModel.$validators.maxValue = function (viewValue) {
        return typeof viewValue === "undefined" || parseFloat(viewValue) <= attrs.ctMaxValue || viewValue === "";
      };
    }
  };
});

// Decimal number directive
regFormAppControllers.directive('ctDecimal', function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ngModel) {
      ngModel.$validators.decimal = function (viewValue) {
        return typeof viewValue === "undefined" || !isNaN(viewValue);
      };
    }
  };
});

regFormAppControllers.directive('ctHpp', function () {
  return {
    restrict: 'A',
    scope: {
      hpp: '=ctHpp',
    },
    link: function (scope, ele, attrs) {
      scope.$watch(attrs.ctHpp, function(html) {
        ele.html(html);
        if (ele[0].lastElementChild) {
          window.onbeforeunload = null;
          ele[0].lastElementChild.submit();
        }
      });
    }
  };
});

// Decimal number directive
regFormAppControllers.directive('ctImagePreview', function () {
    return {
        template: function(elem, attr){
            return '<img class="imgPreview img-responsive" src="' + attr.ctImagePreview + '" href="'+attr.ctImagePreview + '">' +
                '<img class="imgPreviewLarge preview-' + attr.position +'" src="' + attr.ctImagePreview + '" href="'+attr.ctImagePreview + '">';
        },
        link: function (scope, elem, attrs, ngModel) {
            elem.bind('mouseenter', function(event){
                elem.find('.imgPreviewLarge').css("display","block");
            });
            elem.bind('mouseleave', function(event){
                elem.find('.imgPreviewLarge').css("display","none");
            });
        }
    };
});

//validate field on change
regFormAppControllers.directive('ctRevalidate', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            scope.$watch(attrs.ngModel, function () {    
                ngModel.$setValidity('ctRevalidate', true);
            });
        }
    };
});

//validate field on change
regFormAppControllers.directive('alreadyUsed', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            scope.$watch(attrs.ngModel, function () {    
                ngModel.$setValidity('alreadyUsed', true);
            });
        }
    };
});