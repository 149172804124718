
// @todo require bcmath
function bcadd5(left, right) { return bcadd(left, right, 5); }
function bcmul5(left, right) { return bcmul(left, right, 5); }
function bcsub5(left, right) { return bcsub(left, right, 5); }
function bccomp5(left, right) { return bccomp(left, right, 5); }

angular.module('regFormApp').factory('CartService',
  ['$filter', '$timeout', '$rootScope', 'TeamService', 'CouponService', 'StepService', '$q', '$translate','LoaderService', 'StorefrontService', '$http',
  function($filter, $timeout, $rootScope, TeamService, CouponService, StepService, $q, $translate, LoaderService, StorefrontService, $http) {

  /**
   * map elementName -> product description 
   * 
   * @type Object
   */
  var products = {};

  /**
   * Allocation of fees to the organization.
   * @type string
   */
  var orgFeeAllocation = '0.00';
  
  /**
   * @type Object
   */
  var regOptions = {};
  
  /**
   * RegOption -> fee map
   * 
   * @type Object
   */
  var fees = {};
  
  /**
   * Custom fees 
   * 
   * @type Object
   */
  var customFees = {};
  var lotteryCustomFees = {};

  /**
   * This is only for new donations. For old donations use default fee rate
   * @type {number}
   */
  var donationRate = 0.05;

  var decimal  =   {};
  /**
   * Series events 
   * 
   * @type Object
   */
  var seriesEvents = {};
  /**
   * Series  
   * 
   * @type Object
   */
  var series = {};

    /**
     *  This is tax value holder. Should be displayed as sum above total price
     *  and should be added to total
     * @type {number}
     */
  var tax = 0;

  var lotteryTax = 0;

  // when transparent pricing is ON, we want to show flat portion of
  // service fee only for first added product, and only for first entry, and only
  // if there are no other chargable items in a cart
  var storefrontItemWithFee = null

  /**
   * @type Object
   */
  var _defaultEntry = {
    _id: null,
    regOptionID: null,
    name: 'CART_NEW_ENTRY',
    translate: true,
    items: [],
    steps: [],
    total: '0',
    customFeesTotal: '0',
    customFees: [],
    lotteryCustomFees: [],
    noCurrency: false,
    decimal: 2
  };
  
  var currencySymbol = '$';
  var exclusionFeesArray = [];
  
  /**
   * Creates default entry object.
   * 
   * @returns {Object}
   */
  function defaultEntry() {
    return angular.copy(_defaultEntry);
  }
  
  var paymentElementsSetup = false;
  var stripeElement        = null;
  var stripeElementTest    = null;
  var elements = null;
  var stripe = null;
  /**
   * @type Object
   */
  var _defaultCart = {
    entries: [],
    subtotal: '0.00',
    fee: '0.00',
    total: '0.00',
    customFeeTotal: '0.00',
    shippingFee: '0.00',
    wantsShippingAddress: false,
    showFee: false,
    customFees: [],
    lotteryCustomFees: [],
    customFeesOptions: [],
    lotteryEntries: [],
    lotteryFee: 0,
    lotteryCustomFeesTotal: 0,
    showLotteryFee: false,
    lotteryTotal: 0,
    hasLottery: false,
    selectionDate: null,
    stripeTester: regFormApp.resources.eventInfo.stripeTester,
    isTestForm: false,
    paymentMethod: 'cc',
    taxRate: 0,
    tax: 0,
    country: 'US',
    isStripeListenerSet: false,
    requiredPostalCode: true,
    showPaymentForm: function() {
      var showForm = this.hasLottery || this.total > 0;
      if(showForm && $('#card-element').size() > 0 && !this.isAdyen()) {
        if(!paymentElementsSetup) { 
          stripeElement = $.paymentProcessor.setupElements();
          elements = $.paymentProcessor.provider[$.paymentProcessor.currentProvider].elements;
          stripe = $.paymentProcessor.provider[$.paymentProcessor.currentProvider].object;
          if (this.stripeTester) {
            stripeElementTest = $.paymentProcessorTest.setupElements();
          }
          this.setStripeListeners(this.getElements())
          paymentElementsSetup = true;
        }
      } else {
          paymentElementsSetup = false;
      }
      return showForm;
    },
    setStripeListeners: function(elements) {
      if (this.isStripeListenerSet) return

      if (!$rootScope.stripeFieldsValidity) {
        $rootScope.stripeFieldsValidity = {
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false,
          postalCode: false
        }
      }

      const handler = async (event) => {
        $rootScope.$evalAsync(() => {
          $rootScope.stripeFieldsValidity[event.elementType] = event.complete;
        })

        if (['cardNumber', 'cardExpiry', 'cardCvc'].includes(event.elementType)) {
          const { cardNumber, cardCvc, cardExpiry } = $rootScope.stripeFieldsValidity;
          const fields = {
            cardNumber,
            cardCvc,
            cardExpiry,
            [event.elementType]: event.complete
          }
          const postalCode = elements.getElement('postalCode')
          if (Object.values(fields).every(Boolean)) {
            const tokenResponse = await stripe.createToken(elements.getElement('cardNumber'))

            if (tokenResponse.error && tokenResponse.error.code === 'incomplete_zip') {
              $rootScope.$evalAsync(() => {
                $rootScope.stripeFieldsValidity.postalCode = postalCode._complete
              })
              this.requiredPostalCode = true
            } else {
              $rootScope.$evalAsync(() => {
                $rootScope.stripeFieldsValidity.postalCode = true
              })
              this.requiredPostalCode = false
            }
          } else {
            $rootScope.$evalAsync(() => {
              $rootScope.stripeFieldsValidity.postalCode = postalCode._complete
            })
            this.requiredPostalCode = true
          }
        }
      }
        
      const card = elements.getElement('cardNumber')
      const cardExpiry = elements.getElement('cardExpiry')
      const cardCvc = elements.getElement('cardCvc')
      const cardPostal = elements.getElement('postalCode');

      card.on('change', handler)
      cardExpiry.on('change', handler)
      cardCvc.on('change', handler)
      cardPostal.on('change', handler)

      $rootScope.$evalAsync(() => {
        this.isStripeListenerSet = true
      })
    },
    getIsStripeElementsValid: function() {
      const elements = this.getElements()
      if (!regFormApp.resources.paymentProcessor.isHpp && elements) {
        if (this.isStripeListenerSet) {
          const fields = [$rootScope.stripeFieldsValidity.cardNumber, $rootScope.stripeFieldsValidity.cardExpiry, $rootScope.stripeFieldsValidity.cardCvc]
          if (this.requiredPostalCode) {
            fields.push($rootScope.stripeFieldsValidity.postalCode)
          }
          return fields.every(Boolean)
        }
        if (elements) {
          this.setStripeListeners(elements)
        }
      }
      return false
    },
    isAdyen: function() {
      return regFormApp.resources.paymentProcessor.isHpp;
    },
    getStripeElement: function() {
      return stripeElement;
    },
    getStripeElementTest: function() {
      return stripeElementTest;
    },
    isTestFormCheck: function() {
      return this.isTestForm;
    },
    getElements: function() {
      return elements;
    }
  };
  
  
  /**
   * 
   * @type type@type Object
   */
  var _defaultCustomFees = {
    REG_OPTION: 0,
    PRODUCT: 0,
    DONATION: 0,
    PRODUCTS: []
  };

  function defaultCustomFees() {
    return angular.copy(_defaultCustomFees);
  }

  /**
   * Default cart. Making a copy prevents clients from overwritting the default.
   * 
   * @return {Object}
   */
  function defaultCart() {
    return angular.copy(_defaultCart);
  }
  
  /**
   * @type Object
   */
  var cart = defaultCart();

  var isSeries = false;
  /**
   * @type Object
   *
   * Reference to main entries. We keep a reference so we can update ourself 
   * without having to be called explicitly from the entry controller.
   */
  var entries;

  /**
   * Initialize cart
   * 
   * @param {Object} theProducts
   * @param {Object} theEntries
   * @param {Object} options
   * @returns {undefined} 
   */
  function init(theRegOptions, theEntries, options) {
    products          = options.theProducts;
    customFees        = options.theCustomFees;
    decimal           = Number(options.theCurrency.decimal);
    orgFeeAllocation  = options.theFees.orgFeeAllocation;
    regOptions        = theRegOptions;
    eventInfo         = options.theEventInfo;
    seriesEvents      = options.theSeriesEvents;
    isSeries          = options.theIsSeries;
    series            = options.theSeries;
    customFeesOptions = options.theCustomFeesOptions;
    fees = isSeries ? options.theFees : _.object(_.map(regOptions, function(ro, key) {
      return [key, ro.fees];
    }));
    currencySymbol = eventInfo.currencySymbol;
    entries = theEntries;
    $rootScope.$watch(function() { return entries; }, _update, true);
    $rootScope.$on('ctlive.reg.remove-store-product', _update);
    $rootScope.$on('ctlive.reg.sales-processing-fee-changed', _update);
    $rootScope.$on('ctlive.reg.step.hide-change', _update);
    $rootScope.$on('ctlive.reg.country-change', _update);
    $rootScope.$on('ctlive.reg.state-change', _update);
    $rootScope.$on('ctlive.reg.shipping-country-change', _update);

    $.paymentProcessor.init($q, options.thePaymentProcessor.name, options.thePaymentProcessor.config, options.thePaymentProcessor.keys);
    if (regFormApp.resources.eventInfo.stripeTester) {
      $.paymentProcessorTest.init($q, options.thePaymentProcessor.name, 'test', options.thePaymentProcessor.keys);
    }
    return cart;
  }

  /**
   * Compute the value for a coupon.
   *
   * @param {Object} coupon
   * @param {Object} roPrice Reg Option price for this entry
   * @param {Number} adjust Sum of all negative productsconsole.log(lotteryTax,'lotteryTax')
   * @return {Number}
   */
  function computeCouponValue(coupon, roPrice, adjust) {
    var couponValue = '0';
    if (bccomp5(adjust, 0) === -1) {
      roPrice = bcround(bcadd5(roPrice, adjust, 4), 4);
    }
    if (!coupon || !coupon.type || bccomp5(roPrice, '0') === 0) {
      return couponValue;
    }
    if (coupon.type === "AMOUNT") {
      couponValue = coupon.discount;
    }
    else if (coupon.type === "SPECIFIC_PRICE") {
      couponValue = bcsub(roPrice, coupon.discount, 4);
    }
    else {
      // roPrice * (coupon.discount / 100)
      couponValue = bcmul(roPrice, bcmul(coupon.discount, '0.01', 4), 4);
    }
    // if (couponValue > roPrice)
    if (bccomp5(couponValue, roPrice) === 1) {
      // don't allow coupon value to exceed roPrice
      couponValue = roPrice;
    }
    return bcround(couponValue, 4);
  }

  /**
   * Compute fee.
   *
   * Counterpoint to this in PHP app is RegTransacion::updateFee.
   *
   * @param {Number} subtotal
   * @return {Number}
   */
  function computeFee(entries) {
    var memo = _.reduce(entries, function(memo, entry) {
      var isExclusion = 0;
      angular.forEach(customFees, function(customFee, feeKey) {
        angular.forEach(customFee, function(singleFee, key) {
          if(singleFee.is_exclusion == 1) isExclusion = 1;
         });
      });
      if (bccomp5(entry.total, '0') <= 0 && !isExclusion) {
        return memo;
      }
      angular.forEach(entry.items, function(item) {
        if (item.name === "CART_DONATION") {
          entry.total = bcsub(entry.total, item.price);
          if (entry.donationFeeAllocation === 'athlete') {
            entry.donationFee = bcmul5(donationRate, item.price);
          }
        }
      })
      var fee = isSeries ? fees : fees[entry.regOptionID];
      memo.fee = bcround(memo.fee, 4);
      var tempFeeFlat  = fee.flat;
      var tempMemoFee  = memo.fee;
      var tempMemeFlat = memo.flat;
      var tempFeeRate  = fee.rate;
      var addShippingFee = newCart.shippingFee !== '0.00' ?
        entry.items.filter(item => item.productDeliveryType === 'SHIP_ITEM').length > 0 : false
      return {
        // set flat amount to max of fee.flat and flat amount
        flat: bccomp5(entry.total, 0) === 0 ? 0 : (bcround(bccomp5(tempFeeFlat, tempMemeFlat), 4) == 1 ? fee.flat : memo.flat),
        fee:
          bcround(
           bcadd5(
             parseFloat(entry.donationFee),
               bcadd5(
                 tempMemoFee,
                 bcmul5(
                  tempFeeRate,
                    bcadd5(
                      bcadd5(entry.total, entry.customFeesTotal),
                        addShippingFee ? bcdiv(newCart.shippingFee, entries.length) : 0 //maybe something better instead spliting shipping fees per entry
                    )
                 )
             )
           )
          , 3)
      };
    }, {
      flat: '0.00',
      fee: '0.00',
    });
    // (flat + fee) * (1 - orgFeeAllocation) rounded to 2 places
    var fee = bcround(bcmul5(bcadd5(memo.flat, memo.fee), bcsub5('1', orgFeeAllocation)), decimal);
    return fee;
  }

    /**
     * Compute fee.
     *
     * Counterpoint to this in PHP app is RegTransacion::updateFee.
     *
     * @param {Number} subtotal
     * @return {Number}
     */
    function computeDonationFee(entries) {
      var memo = _.reduce(entries, function (memo, entry) {
        angular.forEach(entry.items, function (item) {
          if (item.name === "CART_DONATION") {
            entry.donation = item.price;
          }
        })
        memo.fee = bcround(memo.fee, 4);
        var tempMemoFee = memo.fee;
        var tempMemoFlat = memo.flat;
        return {
          // set flat amount to max of fee.flat and flat amount
          flat: 0,
          fee: bcround(bcadd5(tempMemoFee, bcmul5(donationRate, parseFloat(entry.donation))), 3)
        };
      }, {
        flat: '0.00',
        fee: '0.00',
      });
      var fee = bcround(bcadd5(memo.flat, memo.fee), decimal);
      return fee;
    }

  /**
   * Computes reg option price based on team selection and team's payment type.
   * 
   * @param {Object} entry
   * @param {Object} regOption
   * @param {Object} teamEntryCounts
   * @returns {Number}
   */
  function computeRegOptionPrice(entry, regOption, teamEntryCounts) {
    var price = regOption.price;
    var team = entry.team;
    if (team && entry.teamRegType !== 'REGISTER_AS_INDIVIDUAL') {
      // Track how many times this team was used.
      if (typeof teamEntryCounts[regOption.id] === "undefined") {
        teamEntryCounts[regOption.id] = {};
      }
      if (typeof teamEntryCounts[regOption.id][team.name] === "undefined") {
        teamEntryCounts[regOption.id][team.name] = team.entries;
      }
      teamEntryCounts[regOption.id][team.name] ++;
      if(team.prepaidEntriesNum && team.prepaidEntriesNum !== 0 &&
        team.paymentType === "CAPTAIN_PRE_PAYS" &&
        entry.teamRegType === 'CREATE_TEAM_AND_PREPAY') {
        price = bcadd5(price, bcmul5(price, bcsub(team.prepaidEntriesNum, 1)));
      }
      if (team.paymentType === 'CAPTAIN_PAYS_AFTER' ||
          ( entry.teamRegType === 'JOIN_TEAM' && 
            team.paymentType === 'CAPTAIN_PRE_PAYS' &&
            (
              ( team.prepaid_entries && 
                team.prepaid_entries !== "0" &&
                ( parseInt(team.prepaid_entries) > parseInt(team.reg_prepaid_entries) &&
                  parseInt(team.prepaid_entries) >= teamEntryCounts[regOption.id][team.name]
                )
              ) ||
              ( team.prepaidEntriesNum &&
                team.prepaidEntriesNum !== "0" &&
                parseInt(team.prepaidEntriesNum) > parseInt(team.reg_prepaid_entries)
              )
            )
          ) ||
          ( regOption.paymentStructure === 'FLAT_RATE' &&
            TeamService.isJoinedToTeam(entry) &&
            // CTLIVE-5439 If a team was manually created and then they try and join
            // we don't want them to get free membership.
            teamEntryCounts[regOption.id][team.name] > 1
          )
      ) {
        price = 0;
      }
    }
    return price;
  }

  /**
   * Update cart.
   */
  var _update = _.debounce(function(e, data) {
    var lotteryData = regFormApp.resources.lotteryData;
    var stripeTester = regFormApp.resources.eventInfo.stripeTester;
    newCart = defaultCart();
    // Running total of team entry counts.
    var teamEntryCounts = {};
    angular.forEach(entries, function(entry) {
      if (!entry.regOptionID && !isSeries) {
        return;
      }
      var entryCustomFees = defaultCustomFees();
      var ro = regOptions[entry.regOptionID];
      var newEntry = defaultEntry();
      newEntry._id = entry._id;
      newEntry.regOptionID = entry.regOptionID;

      if (entry.firstName || entry.lastName) {
        var name = '';
        if (entry.firstName) {
          name = entry.firstName + ' ';
        }
        if (entry.lastName) {
          name = name + entry.lastName;
        }
        newEntry.name = name;
        newEntry.translate = false;
      }
      var roPrice = 0;
      if (!isSeries) {
        roPrice = computeRegOptionPrice(entry, ro, teamEntryCounts);
        var prepaidEntries = '';
        if(typeof entry.team !== 'undefined' && typeof entry.team.prepaidEntriesNum !== 'undefined'){
          prepaidEntries = ' Prepaid Entries (' + entry.team.prepaidEntriesNum + ')';
        }
        taxed              = 0;
        totalAfterTax      = 0;
        hideVatData        = false;
        preTaxed      = roPrice;
        if (eventInfo.wantsVat) {
          totalPercent     = bcadd(100, eventInfo.regOptionTax, 4);
          newTaxMultiplier = bcdiv(totalPercent, 100, 4);
          preTaxed         = bcdiv(roPrice, newTaxMultiplier, 4);
          taxed            = bcsub(roPrice, preTaxed, 3);
          
          totalAfterTax    = bcround(roPrice, 3);
          roPrice          = bcround(preTaxed, 3);
          if (taxed == 0) {
            hideVatData = true;
          }
        }

        newEntry.items.push({
          name: ro.name + prepaidEntries,
          translate: false,
          price: preTaxed,
          track: ro.sku + entry._id,
          taxed: bcround(taxed, 2),
          totalAfterTax: bcround(totalAfterTax, 2),
          hideVatData: hideVatData,
        });

        if (eventInfo.wantsVat) {
          newEntry.items.push({
            name: ro.name + ' VAT',
            translate: false,
            price: taxed,
            track: ro.sku + '_VAT_' + entry._id,
            hideInCart: true
          });
        }

        // When we did the responsive reg rewrite the original code had a parseFloat. I speculate that
        // we can get bad data from the server and this is to handle that. So we keep the parse float.
        entryCustomFees.REG_OPTION = bcadd5(entryCustomFees.REG_OPTION, parseFloat(roPrice));
      }
      else {         
        var isPackage = series.discount_option === 'PACKAGE';
        if (isPackage) { 
          newEntry.items.push({
            name: eventInfo.eventName,
            translate: false,
            price: series.package_price,
            track: 'package-series-' + eventInfo.eventID            
          }); 
        }
        angular.forEach(entry.seriesEvents, function(regOptionId, eventId) {
          var seriesEvent = seriesEvents[eventId];
          if (!seriesEvent) {
            return;
          }
          var regOption = seriesEvent.reg_options[regOptionId];
          if (!regOption) {
            return;
          } 
          if (isPackage) {
            roPrice = series.package_price;            
          }
          else {
            roPrice = bcadd5(roPrice, regOption.price);
          }
          newEntry.items.push({
            name: seriesEvent.event.name + ' - ' + regOption.name,
            translate: false,
            price: isPackage ? $translate.instant('INCLUDE') : regOption.price,
            track: 'series-' + eventId + '-' + regOptionId,
            noCurrency: isPackage
          });          
        });
        var isIndividualPricing = series.discount_option === 'INDIVIDUAL_PRICING';
        if (isIndividualPricing) {
          var isFound = false;

          angular.forEach(series.custom_discount, function(obj, regOptionId) {
            if ((obj.max_event_custom_discount >= Object.keys(entry.seriesEvents).length) && (obj.min_event_custom_discount <= Object.keys(entry.seriesEvents).length)) {
              entry.coupon.type     = "PERCENTAGE";
              entry.coupon.discount = obj.discount;
              isFound               = true;
            }
          });
          
          if (!isFound) {
            if (series.min_events_discount <= Object.keys(entry.seriesEvents).length) {
              entry.coupon.type     = "PERCENTAGE";
              if (series.discount) {
                entry.coupon.discount = series.discount;
              } else {
                entry.coupon.discount = 0;
              }
            } else {
              entry.coupon = CouponService.defaultCoupon();
            }
          }
        }
      }
      newCart.stripeTester = stripeTester;
      //make check for reg option
      var isRoLottery = false;
      angular.forEach(lotteryData, function(obj, regOptionId) {
        if (regOptionId == entry.regOptionID) {
          isRoLottery = true;
          newCart.hasLottery = true;
          newEntry.wantsLottery = true;
        }
      });

      var totalProductPrice = 0;
      var affectedProducts  = [];
      // Iterate through products, if selected add them
      angular.forEach(products, function(product, elementName) {
        var value = entry[elementName];
        var price = '0';
        var translate = false;
        var description = product.description;
        var vatRate = product.tax_percentage;
        var salesFee = 0;
        
        if (product.valueType === "multi_price_multi_opt") {
          var multioption = elementName.split("_");
          var elName      = multioption[0];
          if (!entry[elName] || entry[elName] !== multioption[1]) {
            return;
          }
          description = product.name;
        }
        else if (!entry[elementName]) {
          return;
        }
        if (StepService.isCustomElementHidden(entry._id, elementName)) {
          return;
        }

        if(elementName === 'usatMembership') {
          price = entry.usatMembership;
          description = entry.usatOptionName;
          translate = true;
        } else if (product.valueType === 'quantity' || product.valueType === 'selected') {
          if (product.sku !== 'shirt' && !isNaN(value) && bccomp5(value, '0') === 0) {
            return; 
          }
          if(product.valueType !== 'selected') {
            price = bcmul5(value, product.price);
          }
          else {
            price = product.price;
          }

          entryCustomFees = processCustomFeesOptions(customFeesOptions, 'PRODUCT', product, 'element_id', entryCustomFees,  price);
          if (bccomp5(price, '0') == 1) {
            entryCustomFees.PRODUCT = bcadd5(entryCustomFees.PRODUCT, parseFloat(price));
          }
        }
        else if(product.valueType === 'donation') {
          if (typeof value === "undefined" || bccomp5(value, '0') <= 0) {
            // no value, or value is less than 0
            return;
          }
          price = value;
          if (elementName === 'donation') {
            // This is the built in donation question. 
            // Custom donation questions have names of the form 'element12345'.
            description = 'CART_DONATION';
            translate = true;
            entryCustomFees.DONATION = bcadd5(entryCustomFees.DONATION, parseFloat(price));
            newEntry.donationFeeAllocation = entry.donationFeeAllocation;
            newEntry.charityId = entry.charityId;
            newEntry.donationSelectedName = entry.donationSelectedName;
            newEntry.donationName = entry.donationName;
          } else {
            entryCustomFees.PRODUCT = bcadd5(entryCustomFees.PRODUCT, parseFloat(price));
            entryCustomFees         = processCustomFeesOptions(customFeesOptions, 'PRODUCT', product, 'element_id', entryCustomFees,  price);
          }
        }
        else {
          price                   = product.price;
          entryCustomFees         = processCustomFeesOptions(customFeesOptions, 'PRODUCT', product, 'element_id', entryCustomFees, price);
          if (bccomp5(price, '0') == 1) {
            entryCustomFees.PRODUCT = bcadd5(entryCustomFees.PRODUCT, parseFloat(product.price));
          }
        }
        
        if (bccomp5(price, '0') === 0 || (!isNaN(value) && bccomp5(entry[elementName], '0') === 0)) {
          // Don't show 0 values in the cart.
          return;
        }
        if (elementName != 'donation' && elementName != 'usatMembership') {
          totalProductPrice = bcadd5(totalProductPrice, price);
          affectedProducts.push(product.element_id);
        }
        taxed              = 0;
        totalAfterTax      = 0;
        hideVatData        = false;
        if (eventInfo.wantsVat) {
          taxed         = bcdiv(bcmul(price, vatRate, 4), 100, 4);
          totalAfterTax = bcadd(taxed, price, 3);
          taxed         = bcround(taxed, 3);
          if (taxed == 0) {
            hideVatData = true;
          }
        }

        newEntry.items.push({
          name: description,
          translate: translate,
          price: bcround(price, 2),
          taxed: bcround(taxed, 2),
          track: elementName + entry._id,
          elementID: product.element_id,
          totalAfterTax: bcround(totalAfterTax, 2),
          hideVatData: hideVatData
        });
              
        if (eventInfo.wantsVat) {
          newEntry.items.push({
            name: description,
            translate: translate,
            price: bcround(taxed, 2),
            track: elementName + '_VAT_' + entry._id,
            elementID: product.element_id,
            hideInCart: true
          });
        }

      });
      angular.forEach(ro?.products, function (product) {
        if (e?.name !== 'ctlive.reg.remove-store-product' || data?.productId !== product.id || entry._id !== data?.entry?._id) {
          console.log('add-store-product-to-cart')
          StorefrontService.addStoreProductToCart(newEntry, entry, entries, product, newCart);
        } else {
          entry.storefront.products[product.id].optionsInCart = entry.storefront.products[product.id].optionsInCart.filter(o => o.id != data?.optionId);
        }
      });
      angular.forEach(ro?.products, function (product) {
        const qty = entry.storefront.products[product.id]?.option.selectedQuantity

        if (!qty && qty != 0 && entry.storefront.products[product.id]?.option) {
          entry.storefront.products[product.id].option.selectedQuantity = 0;
        }
      });
      if (newEntry.shippingFee > 0) {
        newCart.shippingFee = bcadd5(newCart.shippingFee, newEntry.shippingFee)
      }

      if (bccomp5(bcadd5(roPrice, totalProductPrice), '0') == -1) {
        var alreadyAdjusted = false;
        angular.forEach(newEntry.items, function(a, b) {
          if (typeof a.elementID != 'undefined' && inArray(a.elementID, affectedProducts)) {
            if (!alreadyAdjusted) {
              newEntry.items[b].price = -roPrice;
              alreadyAdjusted = true;
            } else {
              newEntry.items[b].price = 0;
            }
          }
        });
      }

      //get sum of all negative value products
      var adjust = _.reduce(_.filter(_.pluck(newEntry.items, 'price'), function(item) {
          return item < 0;
        }), bcadd5, '0');
      //At this point, adjust can be negative value. So it needs to "added" to roPrice (deducting from roPrice)
      if (eventInfo.wantsVat) {
        var totalPercent     = bcadd(100, eventInfo.regOptionTax, 4);
        var newTaxMultiplier = bcdiv(totalPercent, 100, 3);
        if (entry.coupon.type === "AMOUNT") {
          roPrice = bcmul(roPrice, newTaxMultiplier, 3);
        }
      }

      entryCustomFees.REG_OPTION = bcadd5(roPrice, adjust);
      var couponValue = computeCouponValue(entry.coupon, roPrice, adjust);
      price         = bcmul5('-1', couponValue);
      totalAfterTax = bcmul5('-1', couponValue);
      taxed = 0;
      if (eventInfo.wantsVat) {
        // VAT requires additional work for specific price and amount discounts
        if (entry.coupon.type === "AMOUNT") {
          price                = bcround(bcdiv(price, newTaxMultiplier, 4), 3);
          taxed                = bcsub(totalAfterTax, price, 3);
        } else if (entry.coupon.type === "SPECIFIC_PRICE") {
          totalAfterTax = bcsub(entry.coupon.discount, bcmul(roPrice, newTaxMultiplier, 4), 3);
          couponValue   = bccomp5(couponValue, '0') ? couponValue : totalAfterTax;
          price         = bcdiv(totalAfterTax, newTaxMultiplier, 3);
          taxed         = bcsub(totalAfterTax, price, 3);
        } else {
          taxed         = bcdiv(bcmul(totalAfterTax, eventInfo.regOptionTax, 4), 100, 4);
          totalAfterTax = bcadd(taxed, price, 3);
          taxed         = bcround(taxed, 3);
        }
      }

      // couponValue > 0
      if (bccomp5(couponValue, '0')) {
        entryCustomFees.REG_OPTION  = bcsub5(entryCustomFees.REG_OPTION, couponValue);
        newEntry.items.push({ 
          name: isSeries ? 'SERIES_CART_COUPON' : 'CART_COUPON',
          translate: true,
          price: price,
          totalAfterTax: bcround(totalAfterTax, 2),
          taxed: bcround(taxed, 2),
          track: 'CART_COUPON',
          hideVatData: false
        });

        if (eventInfo.wantsVat) {
          newEntry.items.push({
            translate: true,
            price: taxed,
            track: isSeries ? 'SERIES_CART_COUPON' : 'CART_COUPON' + '_VAT_' + entry._id,
            hideInCart: true
          });
        }
      }       
      if (bccomp5(couponValue, '0') === 0) {
        var membershipCouponValue = computeCouponValue(entry.membershipCoupon, roPrice, adjust);
        // couponValue > 0
        if (bccomp5(membershipCouponValue, '0')) {
          entryCustomFees.REG_OPTION  = entryCustomFees.REG_OPTION  - membershipCouponValue;

          price         = bcmul5('-1', membershipCouponValue);
          totalAfterTax = bcmul5('-1', membershipCouponValue);
          if (eventInfo.wantsVat) {
            // VAT requires additional work for specific price and amount discounts
            if (entry.membershipCoupon.type === "AMOUNT") {
              price                = bcround(bcdiv(price, newTaxMultiplier, 4), 3);
              taxed                = bcsub(totalAfterTax, price, 3);
            } else if (entry.membershipCoupon.type === "SPECIFIC_PRICE") {
              totalAfterTax        = bcsub(entry.membershipCoupon.discount, bcmul(roPrice, newTaxMultiplier, 4), 3);
              price                = bcdiv(totalAfterTax, newTaxMultiplier, 3);
              taxed                = bcsub(totalAfterTax, price, 3);
            } else {
              taxed         = bcdiv(bcmul(totalAfterTax, eventInfo.regOptionTax, 4), 100, 4);
              totalAfterTax = bcadd(taxed, price, 3);
              taxed         = bcround(taxed, 3);
            }
          }
          newEntry.items.push({
            name: 'CART_MEMBERSHIP_COUPON',
            translate: true,
            price: price,
            totalAfterTax: bcround(totalAfterTax, 2),
            taxed: bcround(taxed, 2),
            track: 'CART_MEMBERSHIP_COUPON',
            hideVatData: false
          });

          if (eventInfo.wantsVat) {
            newEntry.items.push({
              translate: true,
              price: taxed,
              track: 'CART_MEMBERSHIP_COUPON' + '_VAT_' + entry._id,
              hideInCart: true
            });
          }
        }
      }
      if (bccomp5(couponValue, '0') === 0) {
        var teamDiscountCouponValue = computeCouponValue(entry.teamDiscountCoupon, roPrice, adjust);
        var couponPercentage = '';
        if(entry.teamDiscountCoupon !== null && entry.teamDiscountCoupon.discount !== null){
          couponPercentage = entry.teamDiscountCoupon.discount + '%';
        }

        price         = bcmul5('-1', teamDiscountCouponValue);
        totalAfterTax = bcmul5('-1', teamDiscountCouponValue);
        if (eventInfo.wantsVat) {
          taxed         = bcdiv(bcmul(totalAfterTax, eventInfo.regOptionTax, 4), 100, 4);
          totalAfterTax = bcadd(taxed, price, 3);
          taxed         = bcround(taxed, 3);
        }
        // couponValue > 0
        if (bccomp5(teamDiscountCouponValue, '0')) {
          entryCustomFees.REG_OPTION = entryCustomFees.REG_OPTION - teamDiscountCouponValue;
          newEntry.items.push({
            name: "{{ 'CART_TEAM_DISCOUNT_COUPON' | translate}} (" + couponPercentage + ")",
            translate: true,
            price: price,
            totalAfterTax: bcround(totalAfterTax, 2),
            taxed: bcround(taxed, 2),
            track: 'CART_TEAM_DISCOUNT_COUPON',
            hideVatData: false
          });

          if (eventInfo.wantsVat) {
            newEntry.items.push({
              name: description,
              translate: true,
              price: taxed,
              track: 'CART_TEAM_DISCOUNT_COUPON' + '_VAT_' + entry._id,
              hideInCart: true
            });
          }
        }
      }
      var lotteryEntry = null;
      if (newEntry.wantsLottery) {
        lotteryEntry      = newEntry;
        lotteryEntry = jQuery.extend(true, {}, newEntry);
        lotteryEntry.wantsLottery = false;
        var newEntryItems     = [];
        var lotteryEntryItems = [];
        angular.forEach(newEntry.items, function(a, b) {
          if (a.name == 'CART_DONATION') {
            newEntryItems.push(a);
          }
          else {
            lotteryEntryItems.push(a);
          }
        });
        newEntry.items = newEntryItems;
        lotteryEntry.items = lotteryEntryItems;
        
        var selectionDate     = lotteryData[entry.regOptionID].selection_date;
        newCart.selectionDate = selectionDate;
      }

      newEntry.total = calculateEntryTotal(newEntry);
      newEntry.customFees = newEntry.wantsLottery ? 0 : entryCustomFees;

      if (lotteryEntry) {
        var lotteryEntryTotal  = _.reduce(_.pluck(lotteryEntry.items, 'price'), bcadd5, '0');
        lotteryEntry.total      = lotteryEntryTotal < 0 ? 0 : lotteryEntryTotal;
        lotteryEntry.customFees = entryCustomFees;
      }
      newCart.entries.push(newEntry);
      if (lotteryEntry) {
        newCart.lotteryEntries.push(lotteryEntry);
      }
    });

    newCart.subtotal        = _.reduce(_.pluck(newCart.entries, 'total'), bcadd5, '0');
    newCart.subtotalLottery = _.reduce(_.pluck(newCart.lotteryEntries, 'total'), bcadd5, '0');
    
    calculateCustomFees(newCart, newCart.entries, false);
    calculateCustomFees(newCart, newCart.lotteryEntries, true);

    var dec = Number(decimal);
    // orgFeeAllocation != 1
    var entriesWithFeeAllocation = newCart.entries.filter(entry => entry.donationFeeAllocation === 'athlete')
    if(bccomp(orgFeeAllocation, "1.00000") !== 0) {
      newCart.fee        = bcround(computeFee(newCart.entries), dec);
      newCart.lotteryFee = bcround(computeFee(newCart.lotteryEntries), dec);
      newCart.showFee = cart.showFee || bccomp5(newCart.fee, '0') !== 0;
      newCart.showLotteryFee = cart.showLotteryFee || bccomp5(newCart.lotteryFee, '0') !== 0;
    } else if (entriesWithFeeAllocation.length) {
      newCart.fee = bcround(computeDonationFee(entriesWithFeeAllocation), dec);
      newCart.showFee = true;
    }
    newCart.totalFeeAfterTax        = newCart.fee;
    newCart.totalLotteryFeeAfterTax = newCart.lotteryFee;
    if (eventInfo.serviceFeeTax) {
      taxRate = eventInfo.serviceFeeTax;

      feeTaxed                 = bcdiv(bcmul(newCart.fee, taxRate, 4), 100, 3);

      totalFeeAfterTax = eventInfo.wantsVat ? bcadd(feeTaxed, newCart.fee, 3) : newCart.fee;

      newCart.feeTaxed         = feeTaxed;
      newCart.totalFeeAfterTax = totalFeeAfterTax;

      lotteryFeeTaxed                 = bcdiv(bcmul(newCart.lotteryFee, taxRate, 4), 100, 3);
      totalLotteryFeeAfterTax         = bcadd(lotteryFeeTaxed, newCart.lotteryFee, 3);
      newCart.lotteryFeeTaxed         = lotteryFeeTaxed;
      newCart.totalLotteryFeeAfterTax = totalLotteryFeeAfterTax;

      newCart.hideFeeVatData   = (feeTaxed == 0);
      newCart.hidelotteryFeeVatData   = (lotteryFeeTaxed == 0);
      if (eventInfo.wantsSales) {
        newCart.hideFeeVatData = true;
      }
    }

    newCart.totalSales = bcround(tax, dec);
    newCart.lotteryTotalSales = bcround(lotteryTax, dec);
    newCart.total = bcadd5(newCart.subtotal, newCart.totalFeeAfterTax);
    newCart.total = bcadd5(newCart.total, newCart.customFeesTotal);
    newCart.total = bcadd5(newCart.total, tax);
    // Add tax after fee is calculated
    if (!newCart.hasLottery) {
      newCart.total = bcadd5(newCart.total, newCart.shippingFee);
    }

    newCart.total = bcround(newCart.total,  dec);
    newCart.lotteryTotal = bcadd5(newCart.subtotalLottery, newCart.totalLotteryFeeAfterTax);
    newCart.lotteryTotal = bcadd5(newCart.lotteryTotal, newCart.lotteryCustomFeesTotal);
    newCart.lotteryTotal = bcadd5(newCart.lotteryTotal, newCart.shippingFee);
    newCart.lotteryTotal = bcadd5(newCart.lotteryTotal, lotteryTax);
    newCart.lotteryTotal = bcround(newCart.lotteryTotal, dec);

    if (parseFloat(newCart.total) === 0) {
      $rootScope.showRecaptcha        = true;
      $rootScope.wrongCaptchaResponse = true;
      $rootScope.$apply();
    } else {
      $rootScope.showRecaptcha        = false;
      $rootScope.wrongCaptchaResponse = false;
      $rootScope.$apply();  
    }

    newCart.tax = tax;

    $timeout(function() {
      angular.copy(newCart, cart);
    });

    if (e?.name === 'ctlive.reg.shipping-country-change' || e?.name === 'ctlive.reg.remove-store-product' ) {
      console.log('broadcast-form-submit')
      $rootScope.$broadcast("ctlive.reg.submit-form");
    }
    if (typeof e !== 'undefined' && e.name === 'ctlive.reg.shipping-country-change') {
      $rootScope.$broadcast("ctlive.reg.shipping_fee_changed");
    }
  }, 50);

  function calculateEntryTotal(entry) {
    var entryTotal      = _.reduce(_.pluck(entry.items, 'price'), bcadd5, '0');
    return bcround(entryTotal < 0 ? 0 : entryTotal, 2);
  }

  function setTax(taxValue) {
    tax = taxValue;
    _update();
  }

  function setLotteryTax(lotteryTaxValue) {
    lotteryTax = lotteryTaxValue;
    _update();
  }

  function getEntryName(entryId) {
    var entry = entries[entryId];
    return entry.regOptionID ? entry.firstName + ' ' + entry.lastName : '';
  }

  function inArray(needle, haystack) {
      var length = haystack.length;
      for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
      }
      return false;
  }
  
  /**
   * Formats price based on currency symbol.
   * 
   * @param {Number} price
   * @returns {String}
   */
  function formatPrice(price) {
    return $filter('currency')(price, currencySymbol, 2);
  }


  /**
   * Return currency symbol. 
   */
  function getCurrencySymbol() {
    return currencySymbol;
  }
  
  /**
   * Method will take all entries and for each of them will calculate custom 
   * fees if they are available. 
   * 
   * @returns void
   */
  function calculateCustomFees(cart, entriesToIterate, isLottery) {
    var customFeeTotal   = 0;
    var customFeesAmount = [];
    var flatApplied      = [];
    var isExclusion      = 0;
    if (isSeries) {
      cart.customFeesTotal = customFeeTotal;
      return;
    }
    angular.forEach(entriesToIterate, function(entry, key) {
      angular.forEach(customFees, function(customFee, feeKey) {
        angular.forEach(customFee, function(singleFee, key) {
          if(singleFee.is_exclusion == 1) isExclusion = 1;
        });
        if(entry.customFees[feeKey] > 0 || ((entry.customFees[feeKey] == '0' || isExclusion)  && feeKey === 'REG_OPTION')) {
          var roPrice = regOptions[entry.regOptionID].price;
          var typePrice = entry.customFees[feeKey];
          angular.forEach(customFee, function(singleFee, key) {
            var feeID = singleFee.id;
            var rate  = singleFee.rate;
            var flat  = singleFee.flat;
            
            if(!singleFee.rules && !singleFee.options) {
              return;
            }

            if (feeKey == 'REG_OPTION') {
              var ro = regOptions[entry.regOptionID];
              var regOptionIsCustomFeeEnabled = processCustomFeesOptions(customFeesOptions, 'REG_OPTION', ro, 'id', false, ro.price, singleFee.id);
              if (!regOptionIsCustomFeeEnabled) {
                return;
              }
            }
            if (feeKey == 'PRODUCT') {
              typePrice = entry.customFees.PRODUCTS[feeID];
              if (typeof typePrice == 'undefined') {
                return;
              }
            }
            exclusionFeesObj = {   
              entry_id      : entry._id, 
              single_fee_id : singleFee.id,
              is_exclusion  : singleFee.is_exclusion            
            };
            
            pushToexclusionFees = true;
            if(exclusionFeesArray.length === 0) {       
              exclusionFeesArray.push(exclusionFeesObj);
            } else {
               angular.forEach(exclusionFeesArray, function(exclusionFee, key) {
                 if(exclusionFee.entry_id  == entry._id ) {
                   pushToexclusionFees = false;
                 }  
               });
               
               if(pushToexclusionFees){
                 exclusionFeesArray.push(exclusionFeesObj);
               }
             }
            if(singleFee.is_exclusion === '1'){
              typePrice = roPrice;
            }
            var price = bcround(bcmul5(rate, typePrice), 5);
            exclusionsCount = 0;
            angular.forEach(exclusionFeesArray, function(exclusionFee, key) {
              if(exclusionFeesArray.single_fee_id  == feeID) {
                exclusionsCount++;
              }  
            });

            if(flatApplied.indexOf(feeID) === -1 || exclusionsCount > 1) {
              if(exclusionsCount > 1) {
                price = bcadd5((flat * exclusionsCount),price);
              } else {
                  if(singleFee.is_exclusion !=="1"){
                    flatApplied.push(feeID);
                  }
                  price = bcadd5(price, flat); 
                }
            }
            var description = singleFee.description;
            if(typeof customFeesAmount[feeID] === 'undefined') {
              customFeesAmount[feeID] = {
                'description': description,
                'price': price,
                '_id': feeID
              };
            }
            else {
              customFeesAmount[feeID].price = bcadd5(customFeesAmount[feeID].price, price);
            }

            if (singleFee.exclude_from_service_fee !== '1') {
              entry.customFeesTotal = bcadd5(entry.customFeesTotal, price);
            }
            customFeeTotal = bcadd5(customFeeTotal, price);
          });
        }
      });
    });
    angular.forEach(customFeesAmount, function(customFeeToAdd, feeID) {
      if (!isLottery) {
        cart.customFees.push(customFeeToAdd);
      } else {
        cart.lotteryCustomFees.push(customFeeToAdd);
      }
    });

    if (!isLottery) {
      cart.customFeesTotal = bcround(customFeeTotal, decimal);
    } else {
      cart.lotteryCustomFeesTotal = bcround(customFeeTotal, decimal);
    }
  }
  
   /**
   * Disable debounce, used for tests only
   */
  function disableDebounce() {
      _.debounce = function (func) { return function () { func.apply(this, arguments);}; };
  }

  // TODO: use this for coupon discounts
  function calcVatCouponDiscount(price, roPrice, newTaxMultiplier, totalAfterTax, taxed, type, discount, regOptionTax) {
    var coupon = {
      price: price,
      taxed: taxed,
      totalAfterTax: totalAfterTax
    };
    // VAT requires additional work for specific price and amount discounts
    if (type === "AMOUNT") {
      coupon.price                = bcround(bcdiv(price, newTaxMultiplier, 4), 3);
      coupon.taxed                = bcsub(totalAfterTax, price, 3);
    } else if (type === "SPECIFIC_PRICE") {
      coupon.totalAfterTax        = bcsub(discount, bcmul(roPrice, newTaxMultiplier, 4), 3);
      coupon.price                = bcdiv(totalAfterTax, newTaxMultiplier, 3);
      coupon.taxed                = bcsub(totalAfterTax, price, 3);
    } else {

      coupon.taxed         = bcdiv(bcmul(totalAfterTax, regOptionTax, 4), 100, 4);
      coupon.totalAfterTax = bcadd(taxed, price, 3);
      coupon.taxed         = bcround(taxed, 3);
    }
    return coupon;
  }

  function processCustomFeesOptions(customFeesOptions, productOrRegOption, element, field, entryCustomFees, customPrice, cfid) {
    var isCustomFeeEnabled = false;

    if (productOrRegOption == 'REG_OPTION' ) {
      if (customFeesOptions[cfid]) {
        if (typeof customFeesOptions[cfid].REG_OPTION != 'undefined') {
          angular.forEach(customFeesOptions[cfid].REG_OPTION, function(customFeeApply, key) {
              if (angular.isArray(customFeesOptions[cfid].REG_OPTION)) {
                  if (customFeeApply == element[field]) {
                      isCustomFeeEnabled = true;
                  }
              } else {
                if (customFeesOptions[cfid].REG_OPTION == 'all') {
                  isCustomFeeEnabled = true;
                }
              }
          });
        }
      }

    } else {
      angular.forEach(customFeesOptions, function(oneCustomFee, customFeeID) {
        isCustomFeeEnabled = false;
        if (typeof oneCustomFee[productOrRegOption] != 'undefined') {
          angular.forEach(oneCustomFee[productOrRegOption], function(customFeeApply, key) {
            if (angular.isArray(oneCustomFee[productOrRegOption])) {
              if (customFeeApply == element[field]) {
                isCustomFeeEnabled = true;
                enteredInIteration = true;
              }
            } else {
              if (oneCustomFee[productOrRegOption] == 'all') {
                isCustomFeeEnabled = true;
                enteredInIteration = true;
              }
            }
          });
        }
        if (isCustomFeeEnabled && productOrRegOption == 'PRODUCT') {
          var val = entryCustomFees.PRODUCTS[customFeeID] ? entryCustomFees.PRODUCTS[customFeeID] : 0;
          var priceToAdd = customPrice;
          if (priceToAdd > 0) {
            entryCustomFees.PRODUCTS[customFeeID] = bcadd5(val, parseFloat(priceToAdd));
          }
        }
      });
    }
    if (productOrRegOption == 'PRODUCT') {
      return entryCustomFees;
    }
    return isCustomFeeEnabled;
  }

  function getStateTaxRate(state, postalCode) {
    var deferred = $q.defer();
    if (!state) {
       return $q.when({valid: false});
    }
    var opts = {
      method: 'POST',
      url: '/reg/get-state-tax-rate/',
      data: $.param({state: state, postalCode: postalCode})
    };
    
    http = $http(opts)
      .then(function(response) {
          deferred.resolve({taxRate: response.data.taxRate});
      })
      .catch(function(reason) {
        deferred.resolve({valid: false});
      });
    return deferred.promise;
  }

  function wantsShippingAddress() {
    return cart.wantsShippingAddress
  }

  function getShippingFee() {
    return cart.shippingFee
  }

  function setStorefrontItemWithFee(id) {
    this.storefrontItemWithFee = id
  }

  function getStorefrontItemWithFee() {
    return this.storefrontItemWithFee
  }
  
  return {
    defaultCart: defaultCart,
    init: init,
    formatPrice: formatPrice,
    getCurrencySymbol: getCurrencySymbol,
    computeRegOptionPrice: computeRegOptionPrice,
    computeFee: computeFee,
    computeDonationFee: computeDonationFee,
    disableDebounce:disableDebounce,
    getStateTaxRate:getStateTaxRate,
    setTax: setTax,
    setLotteryTax: setLotteryTax,
    getEntryName: getEntryName,
    wantsShippingAddress: wantsShippingAddress,
    getShippingFee: getShippingFee,
    setStorefrontItemWithFee: setStorefrontItemWithFee,
    getStorefrontItemWithFee: getStorefrontItemWithFee,
  };
}]);
