angular.module('regFormApp').factory('WaiverService', [function() {
    
  /**
   * Object wiht scroll to agree waivers.
   * 
   * @type Object
   */
  var waivers = {};
  
  /**
   * Adds waiver. Only scroll to agree waivers are added
   * 
   * @param {Element} waiver
   * @returns {undefined}
   */
  function addWaiver(waiver) {
    waiver.isScrolledToBottom = 0;
    waivers[waiver.attr('id')] = waiver;
  }
  
  /**
   * Check if registrant should do some scrolling on scroll to agree waiver.
   * 
   * @param {String} waiverId
   * @returns {Boolean}
   */
  function shouldScrollToAgree(waiverId) {
    var waiver = waivers[waiverId] || false;
    //Only "scroll to agree" waivers are in waivers object
    if(!waiver || waiver.isScrolledToBottom === 1) {
      return false;
    }
    /**
     * Chrome remembers where scroll was after you refresh. 
     * Check here and pull it back up if scroll is not at the top
     */
    if(waiver && waiver.isScrolledToBottom === 0 && waiver[0].scrollTop > 0) {
      waiver[0].scrollTop = 0;
    }
    return waiver && waiver[0].scrollHeight > waiver.height();
  }
    
  return {
    shouldScrollToAgree: shouldScrollToAgree,
    addWaiver: addWaiver
  };
    
}]);   
    
angular.module('regFormApp').directive('ctScrollToAgree', ['WaiverService', function (WaiverService) {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      var last_scroll_top = 0;
      elem.isScrolledToBottom = 0;
      attrs.isAgreed = 0;
      WaiverService.addWaiver(elem);
      elem.bind("scroll", function () {
        var scroll_top = this.scrollTop,
                scroll_height = this.scrollHeight,
                height = this.offsetHeight,
                offset = 25;
        if (scroll_top > last_scroll_top) {
          if ((scroll_top + height + offset) >= scroll_height) {
            elem.parent().find('input[type!="button"]').prop('disabled', false);
            elem.isScrolledToBottom = 1;
          }
        }
        last_scroll_top = scroll_top;
      });
    }
  };
}]);

