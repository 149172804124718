angular.module('regFormApp').factory('RegionService', ['$q', '$http', function regionServicesFactory($q, $http) {

  var countriesUri = '/forms/app/data/countries.json';
  var regionsUri = '/forms/app/data/all-regions.json';
  var languagesUri = '/forms/app/data/languages.json';

  // @todo error handling for all the gets

  var countriesDeferred = $q.defer();
  $http.get(countriesUri).success(function getCountriesSuccess(data) {
    // map country.id => country
    countriesDeferred.resolve(_(data).reduce(function(o, x){o[x.id] = x; return o;}, {}));
  });
  var regionsDeferred = $q.defer();
  $http.get(regionsUri).success(function getRegionsSuccess(data) {
    regionsDeferred.resolve(data);
  });
  
  //@todo Why do we fetch languages here
  var languagesDeferred = $q.defer();
  $http.get(languagesUri).success(function getLanguagesSuccess(data) {
    languagesDeferred.resolve(data);
  });
  
  /**
   * Pulls only digits from a number
   * 
   * @param {Int|String} number
   * @returns {String} with only numbers
   */
  function _digitsOnly(number) {
    if (angular.isNumber(number)) {
      number = number.toString();
    }
    if (!angular.isString(number)) {
      return '';
    }
    return number.replace(/\D/g, '');
  }

  /**
   * Normalize phone number for database storage.
   *
   * @param {String} code Country code
   * @param {Int|String} number
   * @return {String}
   */
  function phoneNormalized(code, number) {
    if (typeof number === "undefined") {
      return undefined;
    }
    if (code !== "1") {
      // North American numbers save as digits only
      return _digitsOnly(number);
    }
    // everwhere else leave formatted as provided
    return phoneFormat(code, number);
  }
  
  /**
   * Formats phone number. Strips letter if phone is non-american
   * 
   * @param {String} code
   * @param {Int|String} number
   * @returns {String}
   */
  function phoneFormat(code, number) {
    if (typeof number === "undefined" || number === null) {
      return undefined;
    }
    if (code !== "1") {
      // for non-north american numbers just strip out invalid characters
      number = number.replace(/ +/g, ' ');
      return number.replace(/[^- .()0-9]/g, '');
    }

    var digits = _digitsOnly(number);
    if (digits.length !== 10) {
      // only format a full 10 digit north american number
      return number;
    }
    return _digitsOnly(number).replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3");
  }

  /**
   * Validate phone number. North american numbers must have 10 digits while 
   * all others must have between 7 and 14 digits.
   * 
   * @param {String} code
   * @param {Int|String} number
   * @returns {Boolean}
   */
  function phoneValidate(code, number) {
    if (typeof number === "undefined" || !number || number.length === 0) {
      return true;
    }
    var len = _digitsOnly(number).length;
    if (code === "1") {
      // north american numbers must be exactly 10 digits
      return len === 10;
    }
    // all other numbers between 7 and 14 digits
    return len >= 7 && len <= 14;
  }

  var ret = {
    countries: countriesDeferred.promise,
    regions: regionsDeferred.promise,
    languages: languagesDeferred.promise
  };
  var all = $q.all(ret);
  ret.all = all;
  ret.phoneFormat = phoneFormat;
  ret.phoneValidate = phoneValidate;
  ret.phoneNormalized = phoneNormalized;

  return ret;
}]);