angular.module('regFormApp').factory('TeamService', ['$q', '$http', '$rootScope', 
    function($q, $http, $rootScope) {
    
  /**
   * @type {Object}
   */
  var _defaultTeam = {
    id: null,
    name: '',
    reg_option_id: null,
    paymentType: null,
    // Needs to be empty string, not null, so default select option is correct in UI.
    team_bracket_id: "",
    max_team_members: null,
    entries: 0,
    teamBracketRestrictions: [],
    is_full: 0,
    owner_id: null
  };
  /**
   * Reference to the main entries array. Used for searching for existing teams.
   */
  var entries = {};

  function defaultTeam() {
    return angular.copy(_defaultTeam);
  }

  /**
   * Quote regular expresions
   * @param {string} str
   * @return string
   *
   * @see http://stackoverflow.com/questions/2593637/how-to-escape-regular-expression-in-javascript
   */
  function regExpQuote(str) {
      return (str+'').replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
  }

  /**
   * Search for teams. Search is done in database and in angular data scope.
   * 
   * @param {String} query Query to search.
   * @param {Object} entry The entry who is searching for the team. Needed so we can filter out
   * @param {Array} entries All the entries, so we find teams already added/joined.
   * @returns {Promise}
   */
  function searchTeams(query, currentEntry, entries) {

    function fillPaymentType(teams) {
      return _.map(teamsToReturn, function(team) {
        if (!team.paymentType) {
          var regOption = _getRegOption(team.reg_option_id);
          if(regOption.isMultiStructure) {
            team.paymentType = "PER_MEMBER";
          } else {
            team.paymentType = _getRegOption(team.reg_option_id).teamInfo.paymentStructure;
          }
        }
        return team;
      });
    }
    var uniqueSearch = (currentEntry.teamRegType === 'CREATE_TEAM' ||
                        currentEntry.teamRegType === 'CREATE_TEAM_AND_PREPAY' ? 1 : 0);
    query = query.trim();
    var deferred = $q.defer();
    if (query.length === 0) {
      deferred.resolve([]);
      return deferred.promise;
    }

    var pattern            = new RegExp(regExpQuote(query),"i");
    var ro                 = regFormApp.resources.regOptions[currentEntry.regOptionID];
    var share              = ro.team_share;
    var currentEntryRaceID = ro.race;
    var teamsToReturn = _.filter(entries, function(e) {
      var loopEntryRaceID = regFormApp.resources.regOptions[e.regOptionID].race;
      var entryNotSame      = e._id !== currentEntry._id;
      var roMatch           = e.regOptionID === currentEntry.regOptionID ;
      var raceMatch         = currentEntryRaceID === loopEntryRaceID;
      var teamRegTypeCreate = e.teamRegType === 'CREATE_TEAM' || e.teamRegType === 'CREATE_TEAM_AND_PREPAY';
      var patternTest       = e.team ? pattern.test(e.team.name): false;
      
      var condition = 
      // Don't match the current entry.
      entryNotSame &&
      //check only entries with team
      e.team &&
      // We only care about created teams, these won't show up in search because
      // they haven't hit the server.
      teamRegTypeCreate &&
      // And finally, the team name matches our search query.
      patternTest;

      return share == 'REG_OPTION' ? condition && roMatch : condition && raceMatch;
      
    });
    teamsToReturn = _.pluck(teamsToReturn, 'team');

    if (uniqueSearch && teamsToReturn.length > 0) {
      // If all we want is to find if this team is unique, and we already know it
      // isn't, let's not bother with the ajax request.
      deferred.resolve(fillPaymentType(teamsToReturn));
      return deferred.promise;
    }

    // query doesn't need escaped.
    var opts = {
      method: 'POST',
      url: '/reg/team-complete/',
      data: $.param({eventID: $rootScope.eventInfo.eventID, regOptionID: _getRegOption(currentEntry.regOptionID).id,
            q: query, limit: 9, exactMatch: uniqueSearch
      })
    };
    $http(opts).then(function(response) {
      angular.forEach(response.data, function(team, key){
        // entries count comes in as string.
        team.entries = parseInt(team.entries, 10);
        teamsToReturn.push(team);
      });
      deferred.resolve(fillPaymentType(teamsToReturn));
    }, function(error){
      deferred.resolve([]);
    });
    return deferred.promise;
  }

  /**
   * Validate team bracket
   * 
   * @param {Object} team
   * @param {Object} entry 
   * @param {Object} entries 
   * @param {Number} teamBracketID 
   * @returns {Promise}
   */
  function validateTeamBracket(team, entry, entries, teamBracketID) {
    var deferred = $q.defer();
    if(!team && isAutoJoinTeamBracket(entry)) {
      team = defaultTeam();
    }
    if(!team || !team.name) {
      deferred.reject('REQUIRED');
      return deferred.promise;
    }
    if (
      !teamBracketID &&
      isCreateTeam(entry) &&
      !isAutoJoinTeamBracket(entry) &&
      isTeamSelectionValid(entry)
    ) {
      deferred.resolve(true);
      return deferred.promise;
    }
    var prepaidEntries = null;
    if(team.prepaidEntriesNum && team.prepaidEntriesNum !== "0" && team.paymentType === "CAPTAIN_PRE_PAYS"){
      prepaidEntries = team.prepaidEntriesNum;
    } else if(team.prepaid_entries && team.prepaid_entries !== "0" && team.paymentType === "CAPTAIN_PRE_PAYS") {
      prepaidEntries = team.prepaid_entries;
    }
    if (team.is_full && team.paymentType === "CAPTAIN_PRE_PAYS" && (typeof entry.team === "undefined" || entry.team.name !== team.name)) {
      deferred.reject("tooMany");
      return deferred.promise;
    }
    
    try {
      var opts = {
        method: 'POST',
        url: '/reg/validate-team-bracket/',
        data: $.param({teamID: team.id, teamName: team.name, 
          age: entry.age, sex: entry.sex, regOptionID: entry.regOptionID, 
          teamBracket: (teamBracketID || team.team_bracket_id), teamEntries:team.entries, prepaidEntries: prepaidEntries})
      };
      http = $http(opts).then(function(response) {
        if(
          typeof response.status !== 'undefined' && 
          response.status === 200 && 
          response.data.status === 0
        ) {
          validateTeamBracketInCurrentReg(team, entry, entries, response.data.restrictions, deferred);
        }
        else {
          if(response.data.error === 'prepaidLargerThanMax' && typeof response.data.bracket_max_members !== 'undefined'){
            team.max_team_members = response.data.bracket_max_members;
          }
          deferred.reject(response.data.error);
        }
      });
    }
    catch(e) {
      deferred.reject(e);
    }
    return deferred.promise;
  }

  function validateTeamBracketInCurrentReg(team, currentEntry, entries, restrictions, deferred) {
    if(!restrictions || Object.keys(entries).length === 1 || isCreateTeam(currentEntry)) {
      deferred.resolve(true);
      return;
    }
    angular.forEach(restrictions, function(restriction, key) {
      angular.forEach(entries, function(entry) {
        if(currentEntry._id === entry._id ||
           currentEntry.regOptionID !== entry.regOptionID || 
           team.name !== entry.team.name) {
          return;
        }

        if(entry.age <= restriction.max_age && entry.age >= restriction.min_age) {
          var sex = entry.sex === 'M' ? 'MALE' : "FEMALE";
          if(sex === restriction.sex || restriction.sex === 'MIXED') {
            restrictions[key].added_entries++;
          }
        }
      });
    });

    var currentEntrySex = currentEntry.sex === 'M' ? 'MALE' : 'FEMALE'
    var hasAvailableSpot = false
    angular.forEach(restrictions, function(restriction) {
      if(
          (restriction.sex === currentEntrySex || restriction.sex === 'MIXED') &&
          currentEntry.age <= restriction.max_age &&
          currentEntry.age >= restriction.min_age &&
          parseInt(restriction.max_members) > restriction.added_entries
      ) {
        hasAvailableSpot = true
      }
    })

    if (hasAvailableSpot) {
      deferred.resolve(true)
    } else {
      deferred.reject('ERROR');
    }
  }
  
  
  /**
   * Check if selection of a team option is valid.
   * 
   * @param {Object} entry
   * @returns {Boolean}.
   */
  function isTeamSelectionValid(entry) {
    switch(entry.teamRegType) {
      case "REGISTER_AS_INDIVIDUAL":
        return !areTeamsRequired(entry);
      case "JOIN_TEAM":
        return typeof entry.team === 'object';
      case "CREATE_TEAM":
        return entry.isTeamNameValid;
      case "CREATE_TEAM_AND_PREPAY":
        return entry.isTeamNameValid;  
      default:
        if(canOnlyJoinTeams(entry) && typeof entry.team === 'object') {
          // @todo use proper angular methods to change the model value
          entry.teamRegType = "JOIN_TEAM";
          return true;
        }
        return false;
    }
  }

  function isCaptainChoosePaymentStructure(entry) {
    return _getRegOption(entry.regOptionID).teamInfo.paymentStructure === 'CAPTAIN_CHOOSE';
  }

  function isCaptainPaysAfterPaymentStructure(entry) {
    return _getRegOption(entry.regOptionID).teamInfo.paymentStructure === 'CAPTAIN_PAYS_AFTER';
  }

  function hasPaymentType(entry) {
    return entry.team && typeof entry.team.paymentType === 'string' &&
      entry.team.paymentType.length > 0;
  }

  /**
   * Check if registrant can choose payment structure
   * 
   * @param {Object} entry
   * @return {Boolean}.
   */
  function canChoosePaymentStructure(entry) {
    return !isRegisteringAsIndividual(entry) &&
      isCaptainChoosePaymentStructure(entry) &&
      (
        isCreateTeam(entry) || 
        (isJoinedToTeam(entry) && !hasPaymentType(entry))
      );
  }

  /**
   * Check if registrant is team captain and if payment structure info slide 
   * should be shown
   * 
   * @param {Object} entry
   * @returns {Boolean}
   */
  function showRegisterAsCaptain(entry) {
    if(!entry || !entry.team) {
      return false;
    }
    return !isRegisteringAsIndividual(entry) &&
      isCaptainPaysAfterPaymentStructure(entry) &&
      !isJoinedToTeam(entry);
  }


  /**
   * Team defaults for reg options. Fills in missing data.
   * @return {Object}
   */
  function regOptionDefaults() {
    return {
      // @todo change this to bool
      hasTeam: 0,
      // @todo change this to bool
      require_team: '0',
      teamInfo: {
        showTeamBracketSlide: false,
        hasAutoJoinTeamBracket: false,
        teamBracketRequired: false,
        // @todo change this to bool
        allow_team_creation_form: '0',
        paymentStructure: "FLAT_RATE"
      }
    };
  }

  /**
   * Return reg option. If no reg option is available, return defaults.
   * @param {string} regOptionID
   * @return {Object} 
   */
  function _getRegOption(regOptionID) {
    var ro;
    if (regOptionID) {
      ro = regFormApp.resources.regOptions[regOptionID];
    }
    return ro || regOptionDefaults();
  }

  /**
   * Check if registrant can choose team bracket
   * 
   * @param {Object} entry
   * @returns {Boolean}
   */
  function canChooseTeamBracket(entry) {
    if (!entry || !entry.team || !entry.team.name) {
      return false;
    }
    var ro = _getRegOption(entry.regOptionID);
    if (!ro.teamInfo.showTeamBracketSlide) {
      return false;
    }
    return entry.teamRegType === 'CREATE_TEAM' || entry.teamRegType === 'CREATE_TEAM_AND_PREPAY' ||
      ((entry.teamRegType === 'JOIN_TEAM' && entry.team.paymentType !== 'CAPTAIN_PRE_PAYS') &&
      !entry.team.captain_id && 
      !entry.team.team_bracket_id);
  }
  
  /**
   * Decide if team slide should be shown
   * 
   * @param {Object} entry
   * @returns {Boolean}
   */
  function showTeamSlide(entry) {
    return _getRegOption(entry.regOptionID).hasTeam === 1;
  }
  
  /**
   * Check if regoption has autojoin bracket option for teams
   * 
   * @returns {Boolean}
   */
  function isAutoJoinTeamBracket(entry) {
    return _getRegOption(entry.regOptionID).teamInfo.hasAutoJoinTeamBracket === true;
  }
  
  /**
   * Check if athlete is team owner
   * 
   * @param {Object} entry
   * @param {Object} team
   * @returns {Boolean}
   */
  function isEntryTeamOwner(entry, team) {
    return team.owner === entry._id;
  }
  
  function isRegisteringAsIndividual(entry) {
    return entry.teamRegType === "REGISTER_AS_INDIVIDUAL";
  }
  
  function isCreateTeam(entry) {
    return entry && entry.teamRegType === "CREATE_TEAM";
  }
  
  /**
   * Check if entry indeed joined to the team.
   * 
   * @param {Object} entry
   * @returns {Boolean}
   */
  function isJoinedToTeam(entry) {
    return entry.team && entry.teamRegType === 'JOIN_TEAM' && 
      !isEntryTeamOwner(entry, entry.team);
  }
  
  function areTeamsRequired(entry) {
    return _getRegOption(entry.regOptionID).require_team === '1';
  }
  
  function canCreateTeams(entry) {
    return _getRegOption(entry.regOptionID).teamInfo.allow_team_creation_form === "1";
  }
  
  function isMultiStructure(entry) {
    return _getRegOption(entry.regOptionID).isMultiStructure === "1";
  }
  
  function captainPrePays(entry) {
    return _getRegOption(entry.regOptionID).paymentStructure === "CAPTAIN_PRE_PAYS";
  }
  
  function canOnlyJoinTeams(entry) {
    return areTeamsRequired(entry) && !canCreateTeams(entry);
  }
  
  function resetTeamData(entry) {
    entry.team = defaultTeam();
    entry.teamRegType = areTeamsRequired(entry) ? null : "REGISTER_AS_INDIVIDUAL";
  }
  
  function bracketValidator($scope, ngModel, team, teamBracketID) {
    var d = $q.defer();
    var validationKeys = ['tooYoung', 'tooOld', 'wrongGender', 'tooMany', 'prepaidLargerThanMax', 'multipleRestrictionsError'];

    /**
     * Set the validitiy of the validators.
     * We use this to set all our validators when we start or complete validation.
     *
     * @param value Value to set key to. true, false, or undefined for pending.
     * @param errorKey (optional) A key to set as an error.
     */
    function setValidity(value, errorKey) {
      _.each(validationKeys, function(key) {
        ngModel.$setValidity(key, key===errorKey ? false : value);
      });
    }
    
    setValidity(undefined); // Setting undefined puts it into pending state.
    validateTeamBracket(
      team,
      $scope.entry,
      $scope.entries,
      teamBracketID
    ).then(
      function() {
        setValidity(true);
        d.resolve(true);
      },
      function(reason) {
        // set validatity of reason key to false
        setValidity(true, reason);
        d.reject(reason);
      }
    );
    return d.promise;
  }

  /**
   * Verify team password
   *
   * @param {Number} eventID
   * @param {Number} teamID
   * @param {String} password for team
   * @return {Object} Promise which resolves to {teamValid: ...}
   */
  function validatePassword(eventID, teamID, password) {
    var deferred = $q.defer();
    if (!password) {
       return $q.when({valid: false});
    }    
    var opts = {
      method: 'POST',
      url: '/reg/validate-team-password/',
      data: $.param({password: password, teamID: teamID, eventID: eventID})
    };
    http = $http(opts)
      .then(function(response) {
        var valid = response.data.teamValid;
        if (!valid) {
          return $q.reject();
        }
        deferred.resolve({valid: true});
      })
      .catch(function(reason) {
        deferred.resolve({valid: false});
      });
    return deferred.promise;
  }

  return {
    regOptionDefaults: regOptionDefaults,
    defaultTeam: defaultTeam,
    validateTeamBracket: validateTeamBracket,
    searchTeams: searchTeams,
    isTeamSelectionValid: isTeamSelectionValid,
    canChoosePaymentStructure: canChoosePaymentStructure,
    canChooseTeamBracket: canChooseTeamBracket,
    showRegisterAsCaptain: showRegisterAsCaptain,
    showTeamSlide: showTeamSlide,
    isAutoJoinTeamBracket: isAutoJoinTeamBracket,
    isEntryTeamOwner: isEntryTeamOwner,
    isJoinedToTeam: isJoinedToTeam,
    areTeamsRequired: areTeamsRequired,
    canCreateTeams: canCreateTeams,
    isMultiStructure: isMultiStructure,
    captainPrePays: captainPrePays,
    canOnlyJoinTeams: canOnlyJoinTeams,
    isCaptainChoosePaymentStructure: isCaptainChoosePaymentStructure,
    isCaptainPaysAfterPaymentStructure: isCaptainPaysAfterPaymentStructure,
    isRegisteringAsIndividual: isRegisteringAsIndividual,
    isCreateTeam: isCreateTeam,
    resetTeamData: resetTeamData,
    validateTeamBracketInCurrentReg: validateTeamBracketInCurrentReg,
    bracketValidator: bracketValidator,
    validatePassword: validatePassword
  };
}]);


angular.module('regFormApp').directive('ctTeamJoin', ['TeamService', function(TeamService) {

  /**
   * Sets the defaults for our controller variables.
   * 
   * @param {Object} obj
   */
  function resetControllerVars(obj) {
    obj.searchName = '';
    obj.matchingTeams = [];
    obj.pending = false;
    obj.noMatches = false;
  }

  return {
    require: ['ngModel', '^form'],
    controller: function() {
      resetControllerVars(this);
    },
    controllerAs: 'teamJoin',
    templateUrl: 'ctTeamJoin.html',
    link: function (scope, elem, attrs, controllers) {
      var ngModel = controllers[0];

      scope.$watch('entry.regOptionID', function regOptionIdChange() {
        resetControllerVars(scope.teamJoin);
        ngModel.$setPristine();
        ngModel.$setUntouched();
      });

      scope.isTeamSelected = function( team ) {
        if ( ! scope.entry.team ) {
          return false;
        }
        if ( team.owner_id ) {
          return team.name === scope.entry.team.name;
        }
        return scope.entry.team.id === team.id;
      };

      scope.teamJoin.searchChange = function searchChange() {
        if (scope.teamJoin.searchName.trim().length >= 1) {
          scope.teamJoin.search();
        }
      };

      ngModel.$isEmpty = function(value) {
        return !angular.isObject(value) || !value.id || !value.name;
      };

      scope.teamJoin.search = function search() {
        // @todo we should delay setting pending for a few ms, 
        // if the search returns fast don't show a spinner.
        scope.teamJoin.pending = true;
        TeamService.searchTeams(
          scope.teamJoin.searchName,
          scope.entry,
          scope.entries
        ).then(function(teams) {
          scope.teamJoin.noMatches = (teams.length === 0);
          if (scope.teamJoin.noMatches && !ngModel.$valid) {
            // If no matches and we don't already have a valid team selected, reset the team
            // so the errors resst. If we don't do this past validation errors displayed even though
            // they are no longer pertinent.
            ngModel.$setPristine();
            ngModel.$setUntouched();
          }
          angular.forEach(teams, function(team, key) {
            var entryCount = 0;
            angular.forEach(scope.entries, function(entry) {
              var regOption = scope.regOptions[scope.entry.regOptionID];
              if(scope.entry._id === entry._id) {
                return;
              }
              if(team.id && entry.team.id === team.id) {
                team.entries++;
              }
              else if (scope.entry.regOptionID === entry.regOptionID && team.name === entry.team.name) {
                team.entries = ++entryCount;    
              }
              var isMultiStructurePerMember = team.paymentType === "PER_MEMBER";
              if(regOption.paymentStructure === "CAPTAIN_PRE_PAYS" && typeof regOption.prepaidEntriesMAX !== 'undefined' && team.max_team_members === null && !isMultiStructurePerMember){
                team.max_team_members = regOption.prepaidEntriesMAX;
              }
              team.reg_prepaid_entries = entryCount;
              team.is_full = team.entries >= (team.max_team_members || Number.MAX_VALUE);
            });
          });
          scope.teamJoin.matchingTeams = teams;
        })
        .finally(function() {
          scope.teamJoin.pending = false;
        });
      };

      elem.find('.ct-team-join-list').on('click', '.ct-team-join-team', function (event) {
        var teamData = $(this).data('team');
        //unset team password when join team so it does not override initialy created password
        delete teamData.password;
        ngModel.$setViewValue(teamData);
        scope.$apply();
      });
    }
  };
}]);



angular.module('regFormApp').directive('ctTeamValidation', ['TeamService', function(TeamService) {
  return {
    restrict: 'A',
    require: 'ngModel',
    priority: 100,
    link: function ($scope, $element, attrs, ngModel) {
      $scope.$watchGroup(['entry.age', 'entry.sex', 'entry.regOptionID'], ngModel.$validate);
      ngModel.$asyncValidators.bracketError = function(team) {
        return TeamService.bracketValidator($scope, ngModel, team);
      };
    }
  };
}]);

angular.module('regFormApp').directive('ctTeamBracketId', ['TeamService', function(TeamService) {
  return {
    restrict: 'A',
    require: 'ngModel',
    priority: 100,
    link: function ($scope, $element, attrs, ngModel) {
      $scope.$watchGroup(['entry.age', 'entry.sex', 'entry.regOptionID', 'entry.team.prepaidEntriesNum'], ngModel.$validate);
      ngModel.$asyncValidators.bracketError = function(teamBracketID) {
        // @todo I think I can remove teamBracketID by setting it in entry.team and just passing team.
        return TeamService.bracketValidator($scope, ngModel, $scope.entry.team, teamBracketID);
      };
    }
  };
}]);


/**
 * Directive that converts team name to object with name attribute equals to 
 * team name
 */
angular.module('regFormApp').directive('ctCreateTeam', ['$q', 'TeamService', function($q, TeamService) {
  return {
    restrict: 'A',
    require: 'ngModel',
    controller: function() {
      this.searchName = '';
    },
    controllerAs: 'teamCreate',
    templateUrl: 'ctTeamCreate.html',
    priority: 200,
    link: function (scope, element, attrs, ngModel) {
      ngModel.$isEmpty = function(value) {
        return !angular.isObject(value) || !value.name;
      };

      scope.teamCreate.searchChange = function() {
        var team = TeamService.defaultTeam();
        var regOption = scope.regOptions[scope.entry.regOptionID];
        team.owner_id = scope.entry._id;
        team.name = scope.teamCreate.searchName.trim();
        team.paymentType = regOption.paymentStructure;
        var isMultiStructurePerMember = regOption.isMultiStructure === "1" && scope.entry.teamRegType === "CREATE_TEAM";
        if(isMultiStructurePerMember) {
          team.paymentType = "PER_MEMBER";
        } 
        team.reg_option_id = scope.entry.regOptionID;
        if(regOption.paymentStructure === "CAPTAIN_PRE_PAYS" && typeof regOption.prepaidEntriesMAX !== 'undefined' && !isMultiStructurePerMember){
          team.reg_prepaid_entries = "0";
          team.max_team_members    = regOption.prepaidEntriesMAX;
        }
        if(TeamService.isAutoJoinTeamBracket(scope.entry)) {
          var autojoinBracket = regOption.teamInfo.teamBrackets[0];
          team.entries = 1;
          team.team_bracket_id = autojoinBracket.id;
          team.teamBracketRestrictions = autojoinBracket.teamBracketRestrictions;
          team.max_team_members = _.reduce(team.teamBracketRestrictions, function(sum, restriction) {
            // If restriction.max_members is null, that means no limit. That evaluates this 
            // (and every sucessive reduce) to NaN, which will always result in is_full = false
            return sum + parseInt(restriction.max_members, 10);
          }, 0);
          team.is_full = team.entries >= team.max_team_members;
        }
        ngModel.$setViewValue(team);
      };

      ngModel.$validators.required = function createTeamNameRequired(modelValue, viewValue) {
        var team = modelValue || viewValue;
        return angular.isObject(team) && angular.isString(team.name) && team.name.trim().length > 0;
      };

      ngModel.$asyncValidators.unique = function(team) {
        var deferred = $q.defer();
        TeamService.searchTeams(
          scope.teamCreate.searchName,
          scope.entry,
          scope.entries
        ).then(function(teams) {
          if (teams.length === 0) {
            deferred.resolve(true);
          }
          else {
            deferred.reject('not unique');
          }
        })
        .catch(function(e) {
          deferred.reject('unknown error');
        });
        deferred.promise.then(
          function() { scope.teamCreateForm.$setValidity('unique', true); },
          function(reason) {
            var uniqueError = reason === 'not unique';
            scope.teamCreateForm.$setValidity('unique', !uniqueError);
            scope.teamCreateForm.$setValidity('unknown', uniqueError);
          }
        );
        return deferred.promise;
      };

      ngModel.$asyncValidators.bracketError = function bracketError(team) {
        return TeamService.bracketValidator(scope, ngModel, team);
      };

      scope.$watchGroup(['entry.age', 'entry.sex', 'entry.regOptionID'], ngModel.$validate);
    }
  };
}]);
