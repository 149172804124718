angular.module('regFormApp').factory('UsatService', ['$q', '$http','$rootScope', 'LoaderService', function($q, $http, $rootScope, LoaderService) {
    
 
  /**
   * Prepare params for call
   * @param String usatOption
   * @param Object entry
   * @returns Object
   */
  function prepareParams(entry, eventDate, usatEventID, eventFormat) {
    var params = {};
    var usatOption = entry.usatOption;

    switch(usatOption) {
    case $rootScope.EXISTING:
        params = {
          memberID: entry.licenceCode,
          lastName: entry.lastName,
          dob: entry.dob,
          eventDate: eventDate,
          eventFormat : eventFormat,
          regOptionID:entry.regOptionID
        };
        break;
    case $rootScope.UPGRADE:
        params = {
          license: entry.updateCode,
          email: entry.email,
          eventID: usatEventID
        };
        break;
    case $rootScope.RENEW:
    case $rootScope.RENEW_YOUTH:
        params = {
          username: entry.usatUsername,
          password: entry.usatPassword,
        };
        break;
    }
    return params;
  }
  
  /**
   * 
   * @param {type} usatOption
   * @param {type} sameCodeElements
   * @param {type} response
   * @returns {undefined}Setting validiti for ct revalidate flag
   */
  function setCtRevalidateyFlag(usatOption, sameCodeElements, response) {
       switch(usatOption) {
        case $rootScope.EXISTING:
            if(sameCodeElements[0]) {
              sameCodeElements[0].$setValidity('ctRevalidate', response);
            }
            break;
        case $rootScope.UPGRADE:
            if(sameCodeElements[1]) {
              sameCodeElements[1].$setValidity('ctRevalidate', response);
            }
            break;
        case $rootScope.RENEW:
        case $rootScope.RENEW_YOUTH:
            if(sameCodeElements[2]) {
              sameCodeElements[2].$setValidity('ctRevalidate', response);
            }
            break;
        }
  }
  
  /**
   * Get urls for usat Options
   */
  function getUrlForUsatOption(entry) {
    var url = '';
    var usatOption = entry.usatOption;
    switch(usatOption) {
    case $rootScope.EXISTING:
        url = '/reg/validate-usat-membership/';
        break;
    case $rootScope.UPGRADE: 
        url = '/reg/validate-usat-one-day-membership/';
        break;
    case $rootScope.RENEW:
    case $rootScope.RENEW_YOUTH:
        url =  '/reg/verify-usat-login';
        break;
    }
    return url;
  }
  
  /**
   * Verify membership coupon
   */
  function validate(entry, eventDate, usatEventID, eventFormat) {

    var deferred = $q.defer();

    var opts = {
      method: 'POST',
      url: getUrlForUsatOption(entry),
      data: $.param(prepareParams(entry, eventDate, usatEventID, eventFormat))
    };
    if(entry.usatOption === $rootScope.EXISTING && entry.licenceCode === '' || entry.usatOption === $rootScope.UPGRADE && entry.updateCode === '') {
      deferred.resolve({valid: false});
    } else {
        http = $http(opts)
        .then(function(response) {
          if (response.data.hasOwnProperty('success') && response.data.success === true) {
            deferred.resolve({valid: true});
          } else {
            deferred.resolve({valid: false});
          }
        })
        .catch(function(reason) {
          deferred.resolve({valid: false});
        });
    }
  
    return deferred.promise;
  } 

  function getUsatOptions(dob, regOptionId) {

    var d = $q.defer();
    params = {
      dob: dob,
      usatEventID:regFormApp.resources.eventInfo.usatEventID,
      dateFormat:regFormApp.resources.eventInfo.dateFormat,
      regOptionId: regOptionId
    };
    var opts = {
      method: 'POST',
      url: '/reg/get-usat-options',
      data: $.param(params)
    };
    
    $http(opts)
    .success(function postSuccess(result) {
      d.resolve(result);
    });

    return d.promise;
  }

  function getWaiverForMembership(membershipID) {
    var d = $q.defer();
    params = {
      membershipID: membershipID,
      usatEventID: regFormApp.resources.eventInfo.usatEventID
    };
    var opts = {
      method: 'POST',
      url: '/reg/get-usat-waiver-for-membership',
      data: $.param(params)
    };

    $http(opts)
    .success(function postSuccess(result) {
      d.resolve(result);
      LoaderService.hide();
    });
    
    return d.promise;
  }

  /**
   * Team defaults for reg options. Fills in missing data.
   * @return {Object}
   */
  function regOptionDefaults() {
    return {
      race_type: 'running'
    };
  }

   /**
   * Return reg option. If no reg option is available, return defaults.
   * @param {string} regOptionID
   * @return {Object} 
   */
  function _getRegOption(regOptionID) {
    var ro;
    if (regOptionID) {
      ro = regFormApp.resources.regOptions[regOptionID];
    }
    return ro || regOptionDefaults();
  }

  /**
   * Decide if USAT slide should be shown
   * 
   * @param {Object} entry
   * @returns {Boolean}
   */
  function showUsatSlide(entry) {
    console.log(_getRegOption(entry.regOptionID), 'ROPCIJA')
    return _getRegOption(entry.regOptionID).race_type === 'multisport'
  }
  
  return {
    validate: validate,
    setCtRevalidateyFlag: setCtRevalidateyFlag,
    getUsatOptions:getUsatOptions,
    getWaiverForMembership: getWaiverForMembership,
    showUsatSlide: showUsatSlide,
    regOptionDefaults: regOptionDefaults
  };
}]);
