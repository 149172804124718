// should be directives. with angular this stuff may not be loaded yet!
$(document).ready(function() {

  // @todo are these used? If so, document it this time so I stop asking.
  //img preview for Images on custom question
  var width  = $(window).width();
  var height = 300;
  if (width < 400) {
    height = 200;
  }
  $('[data-toggle="tooltip"]').tooltip({
    container: 'body'
  });
});

