angular.module('regFormApp').factory('CouponService', ['$q', '$http', function($q, $http) {
    
  /**
   * Map entry to coupon. Needed to validate max_uses.
   * 
   * @member {Object}
   */
  var couponUses = {};
  /**
   * Map entry to coupon. Needed to validate max_uses.
   * 
   * @member {Object}
   */
  var couponUsesSecMax = {};
  
  /**
   * Map entry to his membership coupon.
   * 
   * @type {Object}
   */
  var membershipCoupons = {};
  
  /**
   * Map entry to his team discount coupon.
   * 
   * @type {Object}
   */
  
  var teamDiscountCoupons = {};
  /**
   * @member {Object}
   */
  var _defaultCoupon = {
    discount: null,
    type: null,
    max_uses: Number.MAX_VALUE
  };
  
  /**
   * Default coupon. Making a copy prevents clients from overwritting the default.
   * 
   * @return {Object} of default coupon.
   */
  function defaultCoupon() {
    return angular.copy(_defaultCoupon);
  }

  /**
   * Delete a coupon usage when an entry is deleted or has a coupon error.
   *
   * @param {Int} entryID
   * @return {undefined}
   */
  function deleteUse(entryID) {
    delete couponUses[entryID];
  }
  /**
   * Check if coupon is used by entry
   * @param {int} entryID
   * @returns {Boolean}
   */
  function isUsed(entryID) {
    return !_.isEmpty(couponUses[entryID]);
  }

  /**
   * Check if coupon exceed max usage.
   * 
   * @param {Number} max_uses
   * @param {String} couponName
   * @returns {Boolean}
   */
  function maxUses(max_uses, couponName, isSecMax) {
    var usedCoupons = isSecMax ? couponUsesSecMax : couponUses;
    var uses = _.filter(usedCoupons, function(cn) {
      return cn === couponName;
    });
    uses = uses.length;
    return uses >= max_uses;
  }


  /**
   * Verify coupon usage
   *
   * @param {Number} eventID
   * @param {Number} regOptionID
   * @param {Object} entries
   * @param {Number} entryID Unique ID for the entry
   * @param {String} xnCoupon Coupon code
   * @param {boolean} isSecMax Is secondary max coupon type
   * @param {String} prepaidEntries
   * @return {Object} Promise which resolves to {valid: .., coupon: ...}
   */
  function validate(eventID, regOptionID, entries, entryID, xnCoupon, isSecMax, prepaidEntries) {

    var emptyCoupon = true;
    if (isSecMax) {
      emptyCoupon = false;
    }
    if(typeof prepaidEntries === "undefined"){
      prepaidEntries = '0';
    }
    if(!membershipCoupons[entryID]) {
      deleteUse(entryID);
    }
    if (!xnCoupon) {
      // Always validate when no coupon code presented, return the defaultCoupon.
      return $q.when({valid: emptyCoupon, coupon: defaultCoupon()});
    }
    var deferred = $q.defer();
    xnCoupon = xnCoupon.toLowerCase();
    var opts = {
      method: 'POST',
      url: '/reg/validate-coupon/',
      data: $.param({xnCoupon: xnCoupon, eventID: eventID, regOptionID: regOptionID,
        entries: entries, isSecMax: isSecMax, prepaidEntries: prepaidEntries})
    };
    http = $http(opts)
    .then(function(response) {
      var coupon = response.data.coupon;
      // check if we've exceed max coupon uses
      if (maxUses(coupon.max_uses, xnCoupon, isSecMax)) {
        return $q.reject();
      }
      if (!isSecMax) {
        couponUses[entryID] = xnCoupon;
      } else {
        couponUsesSecMax[entryID] = xnCoupon;
      }
      deferred.resolve({valid: true, coupon: coupon});
    })
    .catch(function(reason) {
      // error, don't count this coupon use
      deleteUse(entryID);
      deferred.resolve({valid: false, coupon: defaultCoupon()});
    });
    return deferred.promise;
  }
  
  function teamDiscountCoupon(eventID, regOptionID, prepaidEntries, entryID){
    var deferred = $q.defer();
    var opts = {
      method: 'GET',
      url: '/reg/validate-team-discount-coupon/xnCoupon/teamDiscount/eventID/' + eventID +
        '/regOptionID/' + regOptionID + '/prepaidEntries/' + prepaidEntries
    };
    http = $http(opts)
    .then(function(response) {
      var coupon = response.data.coupon;
      var code   = response.data.code;
      if (typeof coupon === 'undefined' || maxUses(coupon.max_uses, code, false)) {
        return $q.reject();
      } else{
          teamDiscountCoupons[entryID] = coupon;
          couponUses[entryID] = code;
          deferred.resolve({valid: true, coupon: coupon, code: code});
      }
    })
    .catch(function(reason) {
      // error, don't count this coupon use
      deleteUse(entryID);
      deferred.resolve({valid: false, coupon: defaultCoupon()});
    });
    return deferred.promise;
  }

  /**
   * Verify membership coupon usage
   *
   * @param {Number} accountID
   * @param {Number} eventID
   * @param {Number} regOptionID
   * @param {Number} entryID Unique ID for the entry
   * @return {Object} Promise which resolves to membership coupon code or null if no mebership coupon
   */
  function membershipCoupon(accountID, eventID, regOptionID, entryID) {
    if (!accountID || !is_numeric(accountID)) {
       return $q.when({couponName: null});
    }
    var deferred = $q.defer();
    var opts = {
      method: 'GET',
      url: '/reg/validate-membership-coupon/xnCoupon/membership/eventID/' + eventID +
        '/regOptionID/' + regOptionID + '/accountID/' + accountID
    };
    http = $http(opts)
    .then(function(response) {
      var coupon = response.data.coupon;
      var couponName = response.data.couponName;
      var renewMessage = response.data.renewMessage;
      if (typeof coupon === 'undefined' || maxUses(coupon.max_uses, couponName, false)) {
        deferred.resolve({couponName: null, renewMessage: renewMessage});
      }
      else {
        membershipCoupons[entryID] = coupon;
        couponUses[entryID] = couponName;
        deferred.resolve({couponName: couponName});
      }
    })
    .catch(function(reason) {
      deferred.resolve(null);
    });
    return deferred.promise;
  }
  /**
   * Returns membership coupons for entry
   * @param {Number} entryId
   * @returns {Object}
   */
  function getMembershipCoupon(entryId) {
    return membershipCoupons[entryId];
  }
  
  /**
   * Returns team discount coupons for entry
   * @param {Number} entryId
   * @returns {Object}
   */
  function getTeamDiscountCoupons(entryId) {
    return teamDiscountCoupons[entryId];
  }
  
  
  return {
    defaultCoupon: defaultCoupon,
    validate: validate,
    membershipCoupon: membershipCoupon,
    deleteUse: deleteUse,
    isUsed: isUsed,
    getMembershipCoupon: getMembershipCoupon,
    teamDiscountCoupon: teamDiscountCoupon,
    getTeamDiscountCoupons: getTeamDiscountCoupons
  };
}]);
