/* jshint -W069 */
/* Controllers */
var regFormAppControllers = angular.module('regFormApp', 
  ['ui.bootstrap','pascalprecht.translate', 'ui.utils', 'ngMessages', 'checklist-model']);

// Configuration Translate provider
regFormAppControllers.config(['$translateProvider', function ($translateProvider) {
  $translateProvider.useStaticFilesLoader({
    prefix: '/forms/app/data/locale-',
    suffix: '.json'
  });
  $translateProvider.determinePreferredLanguage();
}]);

// IE11 issue when placholder so element is dirty instead of pristine state 
// https://github.com/angular/angular.js/issues/2614
regFormAppControllers.config(['$provide', function($provide) {
  $provide.decorator('$sniffer', ['$delegate', function($sniffer) {
    var msie = parseInt((/msie (\d+)/.exec(angular.lowercase(navigator.userAgent)) || [])[1], 10);
    var _hasEvent = $sniffer.hasEvent;
    $sniffer.hasEvent = function(event) {
      if (event === 'input' && msie === 11) {
        return false;
      }
      _hasEvent.call(this, event);
    };
    return $sniffer;
  }]);
}]);

regFormAppControllers.config(['$locationProvider', '$windowProvider', function($locationProvider, $windowProvider) {
  //https://github.com/angular/angular.js/blob/master/src/ng/window.js#L9
  if(!$windowProvider.$get().isIE9) {
    $locationProvider.html5Mode(true);
  }
}]);

regFormAppControllers.config(['$httpProvider', function($httpProvider) {
  $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}]);

regFormAppControllers
  .config(['$sceDelegateProvider', function($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist(
      (localStorage.getItem('resourceUrlWhitelist') || 'self').split(';')
    )
  }])
  .directive('lightgallery', function () {
    return {
      restrict: 'A',
      link: function(scope, el) {
        if (scope.$last && lightGallery) {
          lightGallery(el.parent()[0]);
        }
      }
    }
  });
