angular.module('regFormApp').factory('ProductsService', ['$q', '$http', function($q, $http) {
  
  /**
   * @type Object
   */
  var inventory = {};
  
  /**
   * Initialize inventory
   * 
   * @param {Object} data
   * @returns {undefined}
   */
  function initInventory(data) {
    inventory = data;
  }

  function _isSoldOut(product) {
    if (!product) {
      return false;
    }
    return parseInt(product.sold) >= parseInt(product.total);
  }

  function getProduct(productTag) {
    return inventory[productTag];
  }
  
  /**
   * Check if product is sold out.
   * 
   * @param {String} productTag
   * @param {String} (optional) optionTag to check (Like T-Shirt S/M/L)
   * @returns {Boolean}
   */
  function isProductSoldOut(productTag, optionTag) {
    var product = getProduct(productTag);
    if(!product) {
      return false;
    }
    if (_isSoldOut(product)) {
      return true;
    }
    if (_allOptionsSoldOut(product)) {
      return true;
    }
    if (typeof optionTag === "undefined") {
      return _isSoldOut(product);
    }
    if (product && typeof product.options[optionTag] === "undefined") {
      return false;
    }
    return _isSoldOut(product.options[optionTag]);
  }
  
  /**
   * Check if all options are sold out
   * @param {type} product
   * @returns {Boolean}
   */
  function _allOptionsSoldOut(product) {
    var allOptionsSold = true;
    for(var key in product.options) {
      if(key === "") continue;
      var option = product.options[key];
      if(parseInt(option.sold) < parseInt(option.total)) {
        allOptionsSold = false;
        break;
      }
    }
    return allOptionsSold;
  }
  
  function getItemsLeftPerOption(productTag, optionTag, onlyTotal) {
    var product = getProduct(productTag);
    if (!product || _isSoldOut(product) || typeof optionTag === "undefined" || 
        typeof product.options[optionTag] === "undefined") 
    {
      return 0;
    } 
    if (typeof onlyTotal === "undefined") {
      return parseInt(product.options[optionTag].total) - parseInt(product.options[optionTag].sold);
    }
    else {
      return parseInt(product.total) - parseInt(product.sold);
    }
  }
  
  function showItemsLeftLabel(itemsLeft, limit) {
    limit = typeof limit !== 'undefined' ? limit : 26;
    if (!itemsLeft || typeof itemsLeft === "undefined") {
      return false;
    }
    return 0 < parseInt(itemsLeft) && parseInt(itemsLeft) < limit;
  }
  
  return {
    initInventory: initInventory,
    getProduct: getProduct,
    isProductSoldOut: isProductSoldOut,
    showItemsLeftLabel: showItemsLeftLabel,
    getItemsLeftPerOption: getItemsLeftPerOption
  };
  
}]);

/**
 * Enables/disables products as they are sold out.
 *
 * ct-product=element_name  Name of the product inventory.
 * ct-product=multioption  (optional) Name of the multioption (e.g. t-shirt size) to check for.
 */
angular.module('regFormApp').directive("ctProduct", ['ProductsService', '$translate', function(ProductsService, $translate) {
  return {
    restrict: "A",
    link: function(scope, elem, attrs) {
      var isSoldOut = ProductsService.isProductSoldOut(attrs.ctProduct, attrs.ctProductOption);
      elem.toggleClass('sold-out', isSoldOut);
      // Don't run the input disable on the top level ct-product. That is because we'd have a race condition.
      // We'd call disable both for the ct-product and the ct-product-option. We only want it called once.
      if (attrs.ctProductOption) {
        var onlyTotal = attrs.ctOnlyTotal;
        var itemsLeft = ProductsService.getItemsLeftPerOption(attrs.ctProduct, attrs.ctProductOption, onlyTotal);
        var showItemsLeft = ProductsService.showItemsLeftLabel(itemsLeft, attrs.ctTagLimit);
        if (showItemsLeft) {
          if (elem.is("option")) {
            elem.html(elem.html() + " ("+ $translate.instant('ITEMS-LEFT', { items: itemsLeft } ) + ")");
          }
          else {
            elem.parents('.question_content').addClass('items-left-holder');
            setTimeout(function() {
              elem.parent().find('.tags-holder').prepend("<span class='items-left pull-right'> "+ 
                $translate.instant('ITEMS-LEFT', { items: itemsLeft } ) + "</span>");
            }, 1000);
          }
        }  
        elem.prop('disabled', isSoldOut);
        elem.find('input').prop('disabled', isSoldOut);
        if(isSoldOut) {
          elem.find('.sold-out-text').show();
        }
      }
      elem.find('option.sold-out').each(function(){
        var txt = $(this).html();
        $(this).html(txt + ' ('+ $translate.instant('SOLD-OUT') +')');
      });
      var isProductSoldOut = ProductsService.isProductSoldOut(attrs.ctProduct);
      if (isProductSoldOut) {
        var element = elem.find('.label-' + attrs.ctProduct);
        element.removeClass('lbl-required');
        element.append('<span class="sold-out-tag"> ' + $translate.instant('SOLD-OUT') + ' </span>');
      }
    }
  };
}]);
    
