/**
 * Service for javascript logging using Rollbar
 */
angular.module('regFormApp').factory('JsLogService', ['$window', function($window) {

  // When we call .configure() we need to pass the full config or we lose important settings.
  $window._rollbarConfig = $window._rollbarConfig || {};
  $window._rollbarConfig.payload = $window._rollbarConfig.payload || {};
  _.extend($window._rollbarConfig.payload, {actions: []});

  var Rollbar;

  if ($window.Rollbar) {
    Rollbar = $window.Rollbar;
  }
  else {
    Rollbar = {
      critical: function() {},
      error: function() {},
      warning: function() {},
      info: function() {},
      debug: function() {},
      configure: function() {}
    };
  }
  // Merge payload updates into the existing payload.
  Rollbar.updatePayload = function updatePayload(payloadUpdate) {
    _.extend($window._rollbarConfig.payload, payloadUpdate);
    Rollbar.configure($window._rollbarConfig);
  };

  Rollbar.logAction = function logAction(action, payload) {
    var item = {
      timestamp: (new Date()).getTime(),
      action: action
    };
    if (typeof payload !== "undefined") {
      // It will be sent in JSON format to Rollbar if there are any 
      // problems, so we convert it back to an object so we don't have
      // json encoded json.  We do lose type information such as dates. 
      // We were going to lose that anyway.
      item.payload = angular.fromJson(angular.toJson(payload)); // This basically makes a deep clone of the object. 
    }
    $window._rollbarConfig.payload.actions.push(item);
    Rollbar.updatePayload({actions: $window._rollbarConfig.payload.actions});
  };

  return Rollbar;
}]);


angular.module('regFormApp').config(['$provide', function($provide) {
  $provide.decorator('$exceptionHandler', ['$delegate', '$window', function($delegate, $window) {
    return function (exception, cause) {
      if ($window.Rollbar) {
        $window.Rollbar.error(exception, {cause: cause});
      }
      $delegate(exception, cause);
    };
  }]);


  // Log all events
  $provide.decorator('$rootScope', ['$delegate', 'JsLogService', function ($delegate, JsLogService) {
    var _emit = $delegate.$emit;
    var _broadcast = $delegate.$broadcast;

    $delegate.$emit = function () {
      JsLogService.logAction("emit", arguments);
      return _emit.apply(this, arguments);
    };

    $delegate.$broadcast = function () {
      JsLogService.logAction("broadcast", arguments);
      return _broadcast.apply(this, arguments);
    };

    return $delegate;
  }]);
}]);

