var module = angular.module('regFormApp');

module.directive('ctMobileConnections', ['$modal', 'RegionService',
  function($modal, RegionService) {

  return {
    restrict: 'C',
    scope: {
      entry: '='
    },
    template:
      '<div class="mobileUpdate pull-left cb" ng-click="open()" ' +
      '    ng-disabled="disabled()">' +
      '  <i class="glyphicon glyphicon-earphone"></i> SMS' +
      '</div>' +
      '<div class="mobileNumbersPrint pull-left">' +
      '  <ul>' +
      '    <li class="numberPills" ng-repeat="mobile in entry.mobileNumbers">' +
      '      <p>' +
      '        {{mobile.phone | phoneWithCode}}' +
      '        <button type="button" class="close remove-ph"' +
      '          ng-click="deletePhone(mobile)">&times;</button>' +
      '      </p>' +
      '    </li>' +
      '  </ul>' +
      '</div>',
    link: function(scope, elem, attr) {
      var defaultPhoneCode;
      var defaultCountryID;
      scope.$watch('entry.phone.code', function(value) {
        defaultPhoneCode = value;
      });
      scope.$watch('entry.phone.countryID', function(value) {
        defaultCountryID = value;
      });
      RegionService.languages.then(function(languages) {
        scope.languages = languages;
      });

      scope.disabled = function disabled() {
        return _.size(scope.entry.mobileNumbers) >= 3;
      };
      scope.open = function open() {
        if (scope.disabled()) {
          return;
        }
        var dialog = $modal.open({
          templateUrl: 'mobileModal.html',
          controller: 'mobileUpdateController',
          scope: scope,
          resolve: {
            mobileLanguage: function() { return scope.entry.athleteUpdate.updatesLanguage; },
            defaultPhoneCode: function() { return defaultPhoneCode; },
            defaultCountryID: function() { return defaultCountryID; }
          }
        });
        dialog.result.then(function(data) {
          // change the default phone code to whatever they last picked
          defaultPhoneCode = data.phone.code;
          defaultCountryID = data.phone.countryID;
          scope.entry.mobileNumbers.push(data);
        });
      };
      scope.deletePhone = function deletePhone(mobile) {
        // remove the phone that matches
        scope.entry.mobileNumbers = _.without(scope.entry.mobileNumbers, mobile);
      };
    }
  };
}]);
