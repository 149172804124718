var trackerController = angular.module('regFormApp');

trackerController.controller('tealiumTagManager-controller',["$rootScope", "$http","$q", function($rootScope, $http, $q) {


  function tagTrack(event, params) {
    var data        = {};
    var eventParams = {};

    data.event         = event.name;  
    data.eventID       = $rootScope.eventInfo.eventID;
    data.orgID         = $rootScope.eventInfo.orgID;
    data.eventCategory = 'Registration form';
    data.eventParams   = params;
    
    // Add registration variable to data Layer for adyen
    if(event.name === 'ctlive.reg.registered.adyen') {
      var transID = params.registration.transaction.trans_id;
      getGMTData(transID).then(function(resolveData){
        data.registration = resolveData;
        if (typeof utag !== 'undefined') {
          utag.link(data);
        }
      });
    }
    else {
      if (typeof utag !== 'undefined') {
        utag.link(data);
      }
    }
  }
  
  function getGMTData(transID) {
    var d = $q.defer();
    var registrationData = '';
    $http.post('/reg/get-gmt-data', $.param({
      transID     : transID
    })).then(function (response) {
      registrationData = response.data;
      d.resolve(registrationData);
    });
    return d.promise;
  }
  
  $rootScope.$on("ctlive.reg.step.next", tagTrack);
  $rootScope.$on("ctlive.reg.step.prev", tagTrack);
  $rootScope.$on("ctlive.reg.step.goto", tagTrack);
  $rootScope.$on("ctlive.reg.step.leave", tagTrack);
  $rootScope.$on("ctlive.reg.registered", tagTrack);
  $rootScope.$on("ctlive.reg.registered.adyen", tagTrack);
  $rootScope.$on("ctlive.reg.registered.guest", tagTrack);
  $rootScope.$on("ctlive.reg.registered.individual", tagTrack);
  $rootScope.$on("ctlive.reg.registered.group", tagTrack);
  $rootScope.$on("ctlive.reg.start-reg", tagTrack);
  $rootScope.$on("ctlive.reg.login", tagTrack);
  $rootScope.$on("ctlive.reg.guest", tagTrack);
  $rootScope.$on("ctlive.reg.social-login", tagTrack);

  $rootScope.$on("ctlive.reg.entries-change", function gtmEntriesChange(event, entries) {
    entriesObj = {'entries': entries};
    if (typeof utag !== 'undefined') {
      utag.link(entriesObj);
    }
  });

  return {tagTrack: tagTrack};
}]);
