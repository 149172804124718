// @todo stepsController is used outside this file?! If I change this variable things start failing.
var stepsController = angular.module('regFormApp');
var gt = gt || {gettext: function(string) {return string;}};

stepsController.factory('StepService', ['$rootScope', '$timeout', function($rootScope, $timeout) {
    
  /**
   * @type {Object}
   */
  var current = {
    entryID: null,
    step: null
  };

  /**
   * Map custom elements to step name.
   * @type {Object}
   */
  var customElementMap = {};

  /**
   * Default step structure. 
   * <b>steps</b> is "Doubly Linked List" of steps {prev, next, scope, elem, attr}
   * @type {Object}
   */
  var _defaultStepStruct = {
    // "Doubly Linked List" of steps {prev, next, scope, elem, attr}
    steps: {},
    firstStep: null,
    lastStep: null,
    // Sorted array of steps 
    stepsArray: [],
    invalid: false,
    completed: true
  };
  
  /**
   * @type {Object}
   */
  var _steps = {};

  /**
   * Returns array of entry steps
   * 
   * @param {Int} entryID
   * @returns {Array}
   */
  function steps(entryID) {
    if (!_steps[entryID]) {
      _steps[entryID] = angular.copy(_defaultStepStruct);
    }
    return _steps[entryID];
  }

  /**
   * Check if entry has errors in any step.
   * 
   * @param {Int} entryID
   * @returns {Boolean}
   */
  function isEntryInvalid(entryID) {
    return steps(entryID).invalid;
  }
  
  function areStepsCompleted(entryID) {
    return steps(entryID).completed;
  }

  /**
   * Apply changes.
   *
   * Because we use the steps in the view, any changes need to be applied.
   * We pre-build a sorted array of steps because this gets called so many times.
   *
   * @param {Int} entryID
   * @return {Array}
   */
  function apply(entryID) {
    /**
     * We have a problem where sometimes we update a model then immediately want to step,
     * but the current digest hasn't finished so the data here isn't up to date.
     * So we defer application until the next digest by using $timeout.
     */
    $timeout(function() {
      var s = steps(entryID);
      if (s.firstStep === null) {
        return [];
      }
      s.stepsArray = [];
      s.invalid = false;
      s.completed = true;
      var index = s.firstStep;
      do {
        var step = s.steps[index];
        if (!step.hide) {
          var invalid = false;
          // only check step validity if we have a form and this step is complete
          if (step.complete && step.form) {
            invalid = step.form.$invalid;
          }
          s.invalid = s.invalid || invalid;
          s.completed = s.completed && step.complete;
          s.stepsArray.push({
            id: step.id,
            active: step.active,
            complete: step.complete,
            invalid: invalid,
            description: step.description
          });
          if(step.elem && step.active) {
              step.elem.show();
          }
        }
        index = step.next;
      } while(index !== null);
    }, 0);
  }

  /**
   * When changing pages we need to scroll to the top of the page.
   */
  function scrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 0);
  }

  /**
   * Adds steps
   * 
   * @param {Int} entryID
   * @param {String} step
   * @param {Object} elem 
   * @param {Object} form
   * @param {String} description
   * @param {Array} Array of strings - custom elements in this step
   * @returns {undefined}
   */
  function add(entryID, step, elem, form, description, elements) {
    // Map all the elements in a step to the step for fast lookup later.
    _.extend(customElementMap, _.object(_.map(elements, function(x) { return [x, step]; })));

    var s = steps(entryID);
    if (s.steps[step]) {
      // already exists, just repopulate elem and form
      var theStep = s.steps[step];
      theStep.elem = elem;
      theStep.form = form;
      if (theStep.active) {
        // the step should be displayed
        theStep.elem.show();
      }
      return;
    }
    if (s.firstStep === null) {
      s.firstStep = step;
    }
    if (s.lastStep !== null) {
      s.steps[s.lastStep].next = step;
    }
    s.steps[step] = {
      id: step,
      description: description,
      elem: elem,
      form: form,
      prev: s.lastStep,
      next: null,
      hide: false,
      complete: false,
      active : false
    };
    s.lastStep = step;
    /** 
     * We don't call apply() here. This only gets invoked when the page
     * is first being compiled and something else is sure to trigger a digest.
     * Otherwise we're triggering an extra apply for every step.
     */
  }

  /**
   * Removes the elem from a step. Keeps the step for record keeping.
   *
   * @param {Int} entryID
   * @param {String} step
   * @returns {undefined}
   */
  function removeStep(entryID, step) {
    var entrySteps = steps(entryID).steps[step];
    if(typeof entrySteps !== 'undefined') {
      entrySteps.elem = null;
    }
  }

  /**
   * Removes steps for entry.
   * 
   * @param {Int} entryID
   * @throws {Exception} if we try to remove current entry
   * @returns {undefined}
   */
  function removeEntry(entryID) {
    if (current.entryID === entryID) {
      throw "StepService.removeEntry: Can't remove current entry.";
    }
    delete _steps[entryID];
  }

  /**
   * Go to first step
   * 
   * @param {Int} entryID
   * @returns {undefined}
   */
  function goToFirst(entryID) {
    goTo(entryID,false, steps(entryID).firstStep);
  }

  /**
   * Go to last step
   * 
   * @param {Int} entryID
   * @returns {undefined}
   */ 
  function goToLast(entryID) {
    var s = steps(entryID);
    var step = s.lastStep;
    if (isHidden(s.steps[step])) {
      step = _traverse(entryID, s.lastStep, s.firstStep, "prev");
    }
    goTo(entryID, false, step);
  }

  /**
   * Toggle display of a step.
   *
   * @param {Object} step Step structure we are toggling.
   * @param {Boolean} active Whether to activate or not.
   * @returns {undefined}
   */
  function toggleStep(step, active) {
    if(typeof step === 'undefined') {
      return;
    }
    if(step.elem && !active) {
      step.elem.hide();
    }
    step.active = active;
  }

  /**
   * Go to selected step
   * 
   * @param {Int} entryID
   * @param {object} entries
   * @param {String} step
   * @param {Boolean} updateHistory
   * @param {string} direction: If this is a "next" or "prev" step.
   * @returns {undefined}
   */
  function goTo(entryID, entries, step, updateHistory, direction) {
    var s = steps(entryID);
    if (current.step) {
      toggleStep(steps(current.entryID).steps[current.step], false);
      if (current.entryID !== entryID) {
        apply(current.entryID);
      }
    }

    var cqValues = Object.values(customElementMap);
    // params for messages
    var params = {
      old_entry_id: current.entryID,
      old: current.step,
      current_entry_id: entryID,
      current: step,
      updateHistory: updateHistory || typeof updateHistory === 'undefined',
      isProductsSlide: cqValues && cqValues.length > 0 && cqValues.indexOf(current.step) >= 0
    };
    current = {
      entryID: entryID,
      step: step
    };

    if (params.old) {
      $rootScope.$broadcast('ctlive.reg.step.leave', params);
      $rootScope.$broadcast('ctlive.reg.step.leave.' + params.old, params);
    }

    toggleStep(s.steps[current.step], true);
    apply(entryID);
    scrollToTop();
    $rootScope.$broadcast('ctlive.reg.step.goto', params);
    $rootScope.$broadcast('ctlive.reg.step.goto.' + params.current, params);
    if (direction) {
      $rootScope.$broadcast('ctlive.reg.step.' + direction, params);
    }

    if (step == 'groupJoinOrCreateTeam') {
      $timeout(function() {
        $("#groupJoinOrCreateTeam :radio, #groupJoinOrCreateTeam :text").attr("disabled", false);
        return false;
      }, 100);
      if (entries && _.size(entries) > 1) {
        $.each(entries, function (i, entry) {
          if (entryID == entry.team.owner_id && entry.team.prepaidEntriesNum > 1 && entry.team.entries > 0 && entry.team.paymentType == "CAPTAIN_PRE_PAYS" && entry.teamRegType == "CREATE_TEAM_AND_PREPAY") {
            $timeout(function () {
              $("#groupJoinOrCreateTeam :radio, #groupJoinOrCreateTeam :text").attr("disabled", true);
              return false;
            }, 100);
          }
        });
      }
    }

    if (current.step == 'ident') {
      $rootScope.$broadcast('validateAge');
    }
  }

  /**
   * Check if step is hidden.
   * 
   * @param {Object} step
   * @returns {Boolean}
   */
  function isHidden(step) {
    return !step || step.hide;
  }

  /**
   * Traverse steps in prev/next direction until one is found that is not hidden.
   * Stops at the sentinel (lastStap or firstStep.
   *
   * @param {Int|String} entryID
   * @param {String} step Current step
   * @param {String} sentinel Step to stop out
   * @param {String} direction Direction to travel, either prev or next.
   * @return {String} step First non hidden step found in direction, or false if there are no more.
   */
  function _traverse(entryID, step, sentinel, direction) {
    var s = steps(entryID);
    if (step === sentinel) {
      return false;
    }
    step = s.steps[step][direction];
    // skip elements that have been hidden or removed
    if (isHidden(s.steps[step])) {
      step = _traverse(entryID, step, sentinel, direction);
    }
    return step;
  }

  /**
   * Proceed to next slide, skipping hidden slides.
   *
   * @param stepID Step we are stepping from. Prevents triggering click in a step that is not active.
   * @returns {undefined}
   */
  function next(stepID) {
    if (current.step != stepID) {
      return;
    }
    var entryID = current.entryID;
    var s = steps(entryID);
    s.steps[current.step].complete = true;
    var n = _traverse(entryID, current.step, s.lastStep, "next");
    if (n === false) {
      goTo("checkout", false, "registration-review", undefined, "next");
      return;
    }
    goTo(entryID, false, n, undefined, "next");
  }

  /**
   * Return to previous slide, skipping hidden slides.
   * 
   * @param stepID Step we are stepping from. Prevents triggering click in a step that is not active.
   * @returns {undefined}
   */
  function prev(stepID) {
    if (current.step != stepID) {
      return;
    }
    var entryID = current.entryID;
    var s = steps(entryID);
    var p = _traverse(entryID, current.step, s.firstStep, "prev");
    if (p !== false) {
      goTo(entryID, false, p, undefined, "prev");
    }
  }

  // Small delay so we don't flood ourself with events.
  var _emitHideChange = _.debounce(function() {
    $rootScope.$broadcast('ctlive.reg.step.hide-change');
  }, 10);

  /**
   * Set step's hide flag
   *
   * @param {Int|String} entryID
   * @param {Object} step
   * @param {Boolean} hide
   * 
   * @returns {undefined}
   */
  function setHide(entryID, step, hide) {
    var s = steps(entryID);
    if (!s.steps[step] || s.steps[step].hide === hide) {
      return;
    }
    s.steps[step].hide = hide;
    apply(entryID);
    _emitHideChange();
  }

  /**
   * Return steps in sorted array.
   * 
   * @param {Int|String} entryID
   * 
   * @returns {undefined}
   */
  function getSteps(entryID) {
    if (entryID === null || typeof entryID === "undefined") {
      return [];
    }
    return steps(entryID).stepsArray;
  }

  function getCurrent() {
    return current;
  }

  function getEditSteps(entryID) {
    var entrySteps = getSteps(entryID);
    enhanceFirstParticipantStep(entrySteps);
    var checkoutSteps = steps('checkout').steps;
    return entrySteps.concat([checkoutSteps['registration-review'], checkoutSteps.payment]);
  }
  
  /**
   * Corrects step description for the first participant
   * 
   * @param {Array} entrySteps
   * @returns {Array}
   * 
   */
  function enhanceFirstParticipantStep(entrySteps) {
    angular.forEach(entrySteps, function(value, key) {
      if(value.description === "PARTICIPANT 1"){
        entrySteps[key].description = "Participant 1";
      }
     });
     return entrySteps;
  }
  
  /**
   * Check if custom element is hidden
   * 
   * @param {Number} entryID
   * @param {String} element
   * @returns {Boolean}
   * 
   */
  function isCustomElementHidden(entryID, element) {
    var stepName = customElementMap[element];
    if (!stepName) { 
      return /^element\d+$/.test(element);
    }
    return isHidden(steps(entryID).steps[stepName]);
  }
  
  /**
   * Check if step indicator should be enabled.
   * 
   * @param {Object} step
   * @param {Number} entryID
   * @returns {Boolean}
   * 
   */
  function isStepIndicatorEnabled(step, entryID) {
    if(step.id === 'registration-review' || step.id === 'payment') {
      return step.complete && areStepsCompleted(entryID);
    }
    return step.complete;
  }

  return {
    add: add,
    removeStep: removeStep,
    removeEntry: removeEntry,
    goTo: goTo,
    goToFirst: goToFirst,
    goToLast: goToLast,
    next: next,
    prev: prev,
    getSteps: getSteps,
    isEntryInvalid: isEntryInvalid,
    setHide: setHide,
    getCurrent: getCurrent,
    getEditSteps: getEditSteps,
    areStepsCompleted: areStepsCompleted,
    isCustomElementHidden: isCustomElementHidden,
    isStepIndicatorEnabled: isStepIndicatorEnabled
  };
}]);

stepsController.directive('ctStep', ['StepService', function(StepService) {
  return {
    restrict: 'A',
    scope: {
      id: '@',
      entryId: '@',
      description: '@',
      customDescription: '@',
      customElements: '@'
    },
    require: '?form',
    // ng-if priority 800 and terminal -- we want to run this first.
    priority: 800,
    transclude: true,
    template: function(elem, attrib) {
      
      var stringToPrint = '';
      if (typeof attrib.customDescription != 'undefined') {
        stringToPrint = attrib.customDescription;
      }

      var toReturn = '<div class="slide-description">';

      description          = attrib.description;
      var hasCustomRegForm = regFormApp.resources.eventInfo.hasCustomRegForm;
      var printed          = false;
      if (hasCustomRegForm) {
        isClosed = regFormApp.resources.eventInfo.isCustomRegFormClosed;
        if (isClosed) {
          description = regFormApp.resources.eventInfo.customRegFormOpens;
          printed     = true;
          toReturn += '<h4 class="section {{id}}">' + description + '</h4>';
        }
      }
      if (!printed) {
        toReturn += '<h4 class="section {{id}}">{{description}}</h4>';
      }

      toReturn += '<div class="custom_description">' + decodeURIComponent(stringToPrint) + '</div>' +
                    '<div class="separator"></div>' +
                  '</div>' +
                  '<div ng-transclude></div>';

      return toReturn;
    },
    compile: function(tElem, tAttr) {
      tElem.addClass('ct-step col-xs-12');
        if(tAttr.id == "done") {
            tElem.addClass('col-md-12');
        }
        else {
            tElem.addClass('col-md-8');
        }
      return function link(scope, elem, attr, form) {
        if (attr.id === "") {
          throw "id required";
        }
        if (typeof attr.entryId === "undefined") {
          throw "ct-step entry-id required";
        }
        StepService.add(attr.entryId, attr.id, elem, form,
            attr.description, scope.$eval(scope.customElements));
        elem.on('$destroy', function() {
          StepService.removeStep(attr.entryId, attr.id);
        });

      };
    }
  };
}]);

/**
 * Used like ng-if to hide a step based on certain conditions.
 */
stepsController.directive('ctStepIf', ['StepService', function(StepService) {
  return {
    restrict: 'A',
    // We want this priority 500 so that it is after the ctStep directive.
    priority: 500,
    compile: function(tElem, tAttr) {
      return function link(scope, elem, attr) {
        scope.$watch(attr.ctStepIf, function ctStepIfWatchAction(value) {
          StepService.setHide(attr.entryId, attr.id, !value);
        });
      };
    }
  };
}]);

stepsController.directive('ctStepButton', ['$parse', 'StepService', '$translate', function($parse, StepService, $translate) {
  var directionMap = {
    prev: {
      cls: 'prevButton',
      click: StepService.prev
    },
    next: {
      cls: 'nextButton pull-right',
      click: StepService.next
    } 
  };
  return {
    restrict: 'A',
    scope: {
      // Can override default behavior with ngClick
      ngClick: '&'
    },
    compile: function(tElem, tAttr) {
      var d = directionMap[tAttr.ctStepButton];
      tElem.addClass(d.cls);
      if (!tAttr.value) {
        tElem.val(d.value);
      }
      return function link(scope, elem, attr) {
        var click = d.click;
        if (attr.ngClick) {
          click = $parse(attr.ngClick);
        }
        elem.on('click', function(event) {
          scope.$apply(function() {
            var step = $(event.target).closest('.ct-step');
            if (!step) {
              // Not in a step, do nothing.
              return;
            }
            click(step[0].id);
          });
        });
      };
    }
  };
}]);
