/**
 * Main Controller
 * Handles initialization
 */
angular.module('regFormApp').controller('mainController',
  ["$scope","$translate","$rootScope", "RegionService", "LoginService", "CartService",
  "ProductsService", "WaiverService", "$location", "CrowdriseService", "TeamService", "AthlinksService",
  "JsLogService",
  function($scope,$translate,$rootScope, RegionService, LoginService, CartService,
    ProductsService, WaiverService, $location, CrowdriseService, TeamService, AthlinksService,
    JsLogService) {

  JsLogService.updatePayload({context: '/r/' + regFormApp.resources.eventInfo.eventID});

  // Acceptable use of rootScope
  $rootScope.eventInfo = regFormApp.resources.eventInfo;
  $rootScope.currency=regFormApp.resources.currency;
  $rootScope.crowdrise = regFormApp.resources.crowdrise;
  $rootScope.groupConnections = regFormApp.resources.groupConnections;
  $rootScope.ajaxSent = false;

  var avaliableLanguages = regFormApp.resources.eventInfo.availableLanguages;
  var COfAvaliableLanguages = avaliableLanguages.slice(0);
  for(var i = 0; i < COfAvaliableLanguages.length; i++){
      COfAvaliableLanguages[i] = COfAvaliableLanguages[i].split("_")[0];
  }
  var currentLang = $translate.proposedLanguage() || $translate.use();
  var splittedCurrentLang = currentLang.split("_");
  if(!inArray(currentLang, avaliableLanguages)) {
      if(inArray(splittedCurrentLang[0],avaliableLanguages)){
          currentLang = splittedCurrentLang[0];
      }
      else if (inArray(splittedCurrentLang[0],COfAvaliableLanguages)) {
          var indexForLang = COfAvaliableLanguages.indexOf(splittedCurrentLang[0]);
          currentLang = avaliableLanguages[indexForLang];
      }
      else{
          currentLang = 'en';
      }
  }
  $translate.use(currentLang);
  $rootScope.currentLanguage = currentLang;
  // Remap regOptions to something that allows quick lookup.
  var regOptions = {};
  var regOptionDefaults = TeamService.regOptionDefaults();
  angular.forEach(regFormApp.resources.regOptions, function(ro, index) {
    regOptions[ro.id] = _.defaults(ro, regOptionDefaults, {
        minAge: 0,
        maxAge: 120,
        display_order: index,
    });
  });
  regFormApp.resources.regOptions = regOptions;
  CrowdriseService.init($rootScope.eventInfo.eventID,$rootScope.crowdrise).then(function(response) {
    _.extend($rootScope.crowdrise, response);
  });

  ProductsService.initInventory(regFormApp.resources.displayGroupLimits);

  /**
   * Method that handles hanging language.
   *
   * @param {String} langKey
   * @returns {Object}
   */
  $scope.changeLanguage = function (langKey) {
    $rootScope.currentLanguage = langKey;
    $('body').attr('class', 'ng-scope');
    $('body').addClass(langKey);
    return $translate.use(langKey);
  };

  // Set languages
  RegionService.languages.then(function(languages) {
    $scope.languages = languages;
  });

  LoginService.init();
  AthlinksService.init(
    $rootScope.eventInfo.eventID,
    $rootScope.eventInfo.canSyncToAthlinks
  );

  $rootScope.account = {
    UID: '',
    isLoggedIn: false,
    isGuest: false
  };

  /**
   * Asks to confirm logut and proceed if confirmed.
   *
   * @param {Object} event
   * @returns {undefined}
   */
  $scope.logout = function(event) {
    event.preventDefault();
    $translate("LOG-OUT-?").then(
      function(text) {
        var conf = confirm(text);
        if (!conf) {
          return;
        }
        LoginService.logout();
      }
    );
  };

  $rootScope.$on('gigya.event.logout',
    /**
     * Unsets $rootScope.account data.
     *
     * @param {Object} event
     * @param {Object} data
     * @returns {undefined}
     */
    function onLogout(event, data) {
      $rootScope.account = data.user;
    }
  );

  $scope.isProductSoldOut = ProductsService.isProductSoldOut;
  $scope.shouldScrollToAgree = WaiverService.shouldScrollToAgree;
  $scope.gigyaLoaded = LoginService.gigyaLoaded;

  function inArray(needle, haystack) {
      var length = haystack.length;
      for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
      }
      return false;
  }

}]);

// Disables angular hijacking the hash for navigation so we can use it for form auto-fill
angular.module('regFormApp').config(['$locationProvider', function($locationProvider) {
    $locationProvider.html5Mode(true).hashPrefix('!');
}]);
