var trackerController = angular.module('regFormApp');

trackerController.controller('googleTagManager-controller',["$rootScope", "$http","$q", function($rootScope, $http, $q) {

  function tagTrack(event, params) {
    var data        = {};
    var eventParams = {};

    data.event         = event.name;
    data.eventID       = $rootScope.eventInfo.eventID;
    data.orgID         = $rootScope.eventInfo.orgID;
    data.eventCategory = 'Registration form';
    data.eventParams   = params;
    data.taxRate      = $rootScope.eventInfo.regOptionTax;
    data.taxType      = $rootScope.eventInfo.taxType;
    
    // Add registration variable to data Layer for adyen
    if(event.name === 'ctlive.reg.registered.adyen' || event.name === 'ctlive.reg.registered') {
      var transID = params.registration.transaction.trans_id;
      getGMTData(transID).then(function(resolveData){
        data.registration = resolveData;
        // Copy eCommerce fields to outer object
        if (resolveData.transactionTotal && resolveData.transactionId && resolveData.transactionProducts) {
          data.transactionTotal = resolveData.transactionTotal;
          data.transactionId = resolveData.transactionId;
          data.transactionProducts = resolveData.transactionProducts;
        }
        dataLayer.push(data);
      });
    }
    else {
      dataLayer.push(data);
    }
  }
  
  function getGMTData(transID) {
    var d = $q.defer();
    var registrationData = '';
    $http.post('/reg/get-gmt-data')
    .then(function (response) {
      registrationData = response.data;
      d.resolve(registrationData);
    });
    return d.promise;
  }
  
  $rootScope.$on("ctlive.reg.step.next", tagTrack);
  $rootScope.$on("ctlive.reg.step.prev", tagTrack);
  $rootScope.$on("ctlive.reg.step.goto", tagTrack);
  $rootScope.$on("ctlive.reg.step.leave", tagTrack);
  $rootScope.$on("ctlive.reg.registered", tagTrack);
  $rootScope.$on("ctlive.reg.registered.adyen", tagTrack);
  $rootScope.$on("ctlive.reg.registered.guest", tagTrack);
  $rootScope.$on("ctlive.reg.registered.individual", tagTrack);
  $rootScope.$on("ctlive.reg.registered.group", tagTrack);
  $rootScope.$on("ctlive.reg.start-reg", tagTrack);
  $rootScope.$on("ctlive.reg.login", tagTrack);
  $rootScope.$on("ctlive.reg.guest", tagTrack);
  $rootScope.$on("ctlive.reg.social-login", tagTrack);

  $rootScope.$on("ctlive.reg.entries-change", function gtmEntriesChange(event, entries) {
    dataLayer.push({'entries': entries});
  });

  return {tagTrack: tagTrack};
}]);
