/**
 * Mobile Update controller
 */
angular.module('regFormApp').controller('mergeAccountsController', [
    '$scope', '$modalInstance', 'LoginService', 'mergeAccountList', 'provider',
    function ($scope, $modalInstance, LoginService, mergeAccountList, provider)
{
  $scope.mergeAccountList = mergeAccountList;
  $scope.provider = provider;
  $scope.selectedAccounts = [];

  /**
   * Close modal window.
   * 
   * @returns {undefined}
   */
  $scope.cancel = function cancel() {
    $modalInstance.dismiss();
  };

  /**
   * Save changes and close.
   * 
   * @returns {undefined}
   */
  $scope.ok = function ok() {
    $modalInstance.close($scope.selectedAccounts);
  };
}]);
