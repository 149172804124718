angular.module('regFormApp').factory('CrowdriseService', ['$q', '$http', function($q, $http) {
  
  function init(eventId, settings) {
    var deferred = $q.defer();
    if(typeof settings === 'undefined' || !settings.enabled) {
      deferred.resolve({
        enabled: false,
        teams: [],
        hasTeams: false
      });
      return deferred.promise;
    }
    else {
      return getCrowdriseTeams(eventId);
    }
  }
  /**
   * Get crowdrise teams.
   * 
   * @param {Number} eventId
   * @returns {Object} promise
   */
  function getCrowdriseTeams(eventId) {
    var deferred = $q.defer();
    var opts = {
      method: 'GET',
      url: '/reg/get-crowdrise-teams/eventID/' + eventId
    };
    $http(opts).then(function(response) {
      deferred.resolve({hasTeams: Object.keys(response.data).length > 0, teams: response.data, enabled: true});
    }, function(error){
      deferred.resolve({});
    });
    return deferred.promise;
  }

  function getLiveCrowdriseTeams(eventId) {
    var deferred = $q.defer();
    var opts = {
      method: 'GET',
      url: '/reg/get-live-crowdrise-teams/eventID/' + eventId
    };
    $http(opts).then(function(response) {
      deferred.resolve({hasTeams: Object.keys(response.data).length > 0, teams: response.data, enabled: true});
    }, function(error){
      deferred.resolve({});
    });
    return deferred.promise;
  }

  return {
    getCrowdriseTeams: getCrowdriseTeams,
    getLiveCrowdriseTeams: getLiveCrowdriseTeams,
    init: init
  };
}]);
