/**
 * Entry Controller
 */
angular.module('regFormApp').controller('entryController', ["$scope", "$rootScope", "$http",
    "$translate", "$compile", "$filter", "$q", "$timeout",
    "DateService", "StepService", "TeamService", "GroupService", "CouponService", "AthlinksService", "ProductsService",
    "CartService", "LoaderService", "RegionService", "LoginService", "$window", "$location", "JsLogService", "ModalService","UsatService",
    "CrowdriseService",
    "StorefrontService",
    function($scope, $rootScope, $http,
      $translate, $compile, $filter, $q, $timeout,
      DateService, StepService, TeamService, GroupService, CouponService, AthlinksService, ProductsService,
      CartService, LoaderService, RegionService, LoginService, $window, $location, JsLogService, ModalService, UsatService,
      CrowdriseService,
      StorefrontService
    ) {

  $scope.isMobile = !!window.WebViewBridge;
  $scope.mobileFinish = function() {
    if ($scope.isMobile) {
      window.WebViewBridge.send(JSON.stringify({
        scene: 'Profile',
        resetTo: true
      }));
    }
  };
  /**
   * Default card data
   */
  $scope.cardData = {
    billingName: null,
    cardNumber: null,
    cardExpires: null,
    cardCode: null,
    billingZip: null,
    paymentToken: null,
    processorConfig: null,
    isHpp: regFormApp.resources.paymentProcessor.isHpp,
    generationTime: regFormApp.resources.paymentProcessor.generationTime
  };

  /**
   * Default avalara object
   * @type {{customerCode: null, transactionCode: null}}
   */
  $scope.avalara = {
    customerCode: null,
    transactionCode: null
  };
  
  $scope.eventStringBase = 'ctlive.reg.registered';
  $scope.eventString = regFormApp.resources.paymentProcessor.isHpp ? $scope.eventStringBase + '.adyen' : $scope.eventStringBase;
  
  $scope.entries = {};
  $scope.entryReferralData = {
    link: null,
    goal: null,
    award: null
  };
  $scope.entryCount = 0;
  $scope.omitTeamReset = false;
  $scope.currentEntry = null;
  $scope.mainEntry = null;
  $scope.translateData = {};
  $scope.regRefundPrice = 0;
  $scope.athlinksPartialEntryId = '';
  $scope.hpp = '';
  $rootScope.refreshPaymentMethods = true;
  $scope.paymentMethods = null;
  $scope.ccardOnly = false;
  $scope.issuers = [];
  $scope.selectedIssuer = [];
  $rootScope.showRecaptcha        = false;
  $rootScope.wrongCaptchaResponse = false;
  $scope.is_usat_membership   = regFormApp.resources.is_usat_membership;

  /**
   * Transaction
   */
  $scope.transaction = {};

  /**
   * Set reg options
   */
  $scope.regOptions        = regFormApp.resources.regOptions;
  $scope.seriesEvents      = regFormApp.resources.series_events;
  $scope.series            = regFormApp.resources.series;
  $scope.enableMasterpass  = regFormApp.resources.eventInfo.enableMasterpass;

  $scope.regOptions   = regFormApp.resources.regOptions;
  $scope.seriesEvents = regFormApp.resources.series_events;
  $scope.series       = regFormApp.resources.series;
  $rootScope.usatPrices = regFormApp.resources.eventInfo.usatPrices;
   
  $rootScope.EXISTING              = 'EXISTING';
  $rootScope.ONE_DAY               = 'ONE_DAY';
  $rootScope.ANNUAL                = 'ANNUAL';
  $rootScope.ANNUAL_YOUTH          = 'ANNUAL_YOUTH';
  $rootScope.RENEW                 = 'RENEW';
  $rootScope.RENEW_YOUTH           = 'RENEW_YOUTH';
  $rootScope.UPGRADE               = 'UPGRADE';
 
  $scope.isSeries = function() {
    return !_.isEmpty($scope.series);
  };

  $scope.isUsatMembership = function() {
    return regFormApp.resources.is_usat_membership;
  };

  $scope.isLottery = function() {
    return regFormApp.resources.wantsLottery;
  };
  $scope.regInProgress = $scope.isSeries();

  // tracks which entry "Edit" menus are currently open
  $scope.editUserShow = {};
  /**
   * Get Payment methods based on country that main user has selected
   *
   * @param {Object} event
   * @param {Object} data
   * @returns {undefined}
   */
  
  $scope.getCountry = function() {
    var country = null;
    if(typeof $scope.entries[$scope.mainEntry] !== 'undefined') {
      country = $scope.entries[$scope.mainEntry].country;
    } else {
      if(typeof $scope.entries[$scope.currentEntry] !== 'undefined') {
        country = $scope.entries[$scope.currentEntry].country;
      }
    }
    return country;
  };
  
  /**
   * Hiding payment slide when we are doing directory lookup call so we evade show/hide/show process
   * @returns Bool
   */
  $scope.hideForDirectory = function() {
    return $scope.isAdyen() &&
           $rootScope.refreshPaymentMethods &&
           parseFloat($scope.cart.total) !== 0 &&
           !regFormApp.resources.forceCC && 
           $scope.getCountry() !== 'US';
  };
  
  $scope.$on('ctlive.reg.step.leave.registration-review',function(event, data) {
    var paymentCondition = data.old === 'registration-review' &&
        data.current === 'payment' && $rootScope.refreshPaymentMethods
    if (paymentCondition && parseFloat($scope.cart.total) !== 0 || ($scope.isLottery() && paymentCondition)) {
      
      LoaderService.show();
      var country = $scope.getCountry();
      // We will create transaction here so we can get price after tax from avalara
      $scope.submitForm(true);

      if(!regFormApp.resources.forceCC && country && country !== 'US') {
          $rootScope.refreshPaymentMethods = true;
          
          var paymentData = {};
          paymentData.eventID = regFormApp.resources.eventInfo.eventID;
          paymentData.country = country;
          paymentData.paymentAmount = $scope.cart.total;
          
          $http.post('/reg/get-payment-methods', $.param(paymentData))
          .success(function postSuccess(result) {
            $timeout(function() {
              $scope.allMethods = result;
              $scope.paymentMethods = $scope.filterPaymentResults(result);
            }, 0);
            
            // If there are no payment methods fallback to cc
            if(result.length === 0) {
              $scope.fallBackToCC();
            } else {
              $scope.setIssuersFromPaymentMethods();
            }
            
            // Set refreshPaymentMethods to false because we don't want to spam our api without nessesity
            $rootScope.refreshPaymentMethods = false;
            LoaderService.hide();
            }).error(function postError(errorData) {
              LoaderService.hide();
              $timeout(function() {
                $scope.fallBackToCC();
              }, 0);
            });
      } else {
        // If country is not set up for any reason, fall back to Credit card
        LoaderService.hide();
        $scope.fallBackToCC();
      }
   
    }
  });
  /**
   * This method will remove credit cards since we have them with easy encryption
   * @param Array results
   * @returns {undefined}
   */
  $scope.filterPaymentResults = function(results) {
    var forRemoval = ['amex', 'mc', 'visa', 'discover'];
    var methodsForReturn = [];
    angular.forEach(results, function(value, key) { 
      if(forRemoval.indexOf(value.brandCode) < 0) {
        methodsForReturn.push(value);
      }
    });
    return methodsForReturn;
  };
  /**
   * This method set payment methods array to null and sets ccardOnly flag to true
   * if something goes wrong with directory lookup we want to be able to pay with CC
   */
  $scope.fallBackToCC = function() {
    $scope.paymentMethods = null;
    $scope.ccardOnly = true;
    $scope.cart.paymentMethod = 'cc';
  };
  
  /**
   * @returns {undefined} * If payment method is banking we will have issuers that we have to show
   */
  $scope.setIssuersFromPaymentMethods = function() {
    angular.forEach($scope.paymentMethods, function(value, key) {
        if(value.issuers && value.issuers.length) {
          $scope.issuers[value.brandCode] = value.issuers;

          // Set the default value of issuer for specific brand
          $scope.selectedIssuer[value.brandCode] = value.issuers[0].issuerId;
        }
    });
  };
  
  /**
   * Returns true if passed method has issuers
   * @returns {Boolean}
   */
  $scope.hasIssuers = function(method) {
    return $scope.issuers[method] && $scope.cart.paymentMethod === method;
  };
  
  /**
   * Showing payment elements for adyen based on directory lookup fetch
   * @returns {Boolean}
   */
  $scope.showPaymentElements = function() {
      return ($scope.isAdyen() && !$rootScope.refreshPaymentMethods) || $scope.ccardOnly || parseFloat($scope.cart.total) === 0;
  };
  
  /**
   * Check cardType accroding to value
   * @returns {String}
   */
  $scope.checkCardTypeToValue = function(cardType,value){
      if(cardType === value || cardType === ''){
          return "";
      }
      else{
          return "opacity_card";
      }
  };
  
  /**
   * Hiding payment method icons based on directory lookup
   * @param {type} card
   * @returns {String}
   */
  $scope.checkPaymentMethod = function(card) {
    var country = $scope.getCountry();
      
    if(!regFormApp.resources.forceCC && country && country !== 'US') {
      var cssClass = "hidden";
      angular.forEach($scope.allMethods, function(value, key) {
        if(value.brandCode == card) {
          cssClass = "";
          return cssClass;
        }
      });
      return cssClass;
   }
  };
  
  /**
   * Is this event using adyen as payment processor
   * @returns {Boolean}
   */
  $scope.isAdyen = function() {
      return regFormApp.resources.paymentProcessor.isHpp;
  };
  /**
   * Check if its adyen and if user has selected credit card as payment method;
   * @returns {Boolean}
   */
  
  $scope.isAdyenCC = function() {
      return $scope.isAdyen() && $scope.cart.paymentMethod === 'cc';
  };
  /**
   * Checks if payment method is avaliable from directory lookup
   * we are using this to show/hide payment methods on payment slide
   * @returns {undefined}
   */
  $scope.isAvailableMethod = function(method) {
    var showMethod = false;
    angular.forEach($scope.paymentMethods, function(value, key) {
        if(method === value.brandCode && !showMethod) {
          showMethod = true;
        }
    });
    return showMethod;
  };
  
  /**
   * Allows to go back to user's step.
   *
   * @param {Number} position
   * @param {Number} entryID
   * @returns {undefined}
   */
  $scope.editUserClick = function(position, entryID) {
    if (typeof $scope.editUserShow[position] === "undefined") {
      $scope.editUserShow[position] = {};
    }
    $scope.editUserShow[position][entryID] = !$scope.editUserShow[position][entryID];
  };

  /**
   * Check if events series is valid
   * @returns {Boolean}
   */
  $scope.isRegSeriesValid = function isRegSeriesValid() {
    var min = $scope.series.min_events;
    var max = $scope.series.max_events;
    var ok = false;
    var currentEntry = $scope.entries[$scope.currentEntry];
    if (currentEntry.checkedSeriesEvents &&
      min <= currentEntry.checkedSeriesEvents.length &&
      currentEntry.checkedSeriesEvents.length <= max) {
      ok = true;
    }
    return ok;
  };

  /**
   * Used to remove key from object
   * @example
   *  ng-change : checkbox is unchecked and we want remove key from another object
   *
   * @param {string} key
   * @param {object} object
   * @param {boolean} checked
   * @returns {undefined}
   */
  $scope.unsetFromObject = function(key, object, checked) {
    if (!checked) {
      delete object[key];
    }
  };
  
  $scope.setUsatPrice = function(price, membershipID, membershipName) {
    if(!$scope.entries[$scope.currentEntry]) {
     return;
    }
    var currentEntry = $scope.entries[$scope.currentEntry];
    currentEntry.usatMembership = price;
    currentEntry.usatOption     = membershipID;
    currentEntry.usatOptionName = membershipName;
  };
  
  /**
   * Check all events if series type is package (min_events == max_events)
   * @returns {undefined}
   */
  $scope.checkAllEvents = function() {
    if ($scope.isSeries() && $scope.series.select_all) {
      var currentEntry = $scope.entries[$scope.currentEntry];
      currentEntry.checkedSeriesEvents = Object.keys($scope.seriesEvents);
    }
  };

  /**
   * Check if event is selected by current user
   * @param {type} eventID
   * @returns {Boolean}
   */
  $scope.isEventSelected = function isEventSelected(eventID) {
    return $scope.entries[$scope.currentEntry].checkedSeriesEvents.indexOf(eventID) != -1;
  };

  $scope.getSteps = function getSteps(entryID) {
    return StepService.getSteps(entryID);
  };
  $scope.getEditSteps = function getEditSteps(entryID) {
    return StepService.getEditSteps(entryID);
  };
  $scope.goToStep = function goToStep(entryID, step, updateHistory) {
    /**
     * If current entry has errors we need to prevent user to navigate to other entry.
     * We need to go to same step first to update form error (or call StepService.apply ?).
     * We need timeout to avoid two $digest cycles.
     */
    if($scope.currentEntry !== entryID) {
      $scope.omitTeamReset = true;
      var current = StepService.getCurrent();
      StepService.goTo(current.entryID, $scope.entries, current.step, false);
      $timeout(function(){
        if(StepService.isEntryInvalid($scope.currentEntry)) {
          alert("There are errors for the current registrant. You must fix them before proceeding.");
          return;
        }
        else {
          $scope.setCurrentEntry(entryID);
          StepService.goTo(entryID, $scope.entries, step, updateHistory);
        }
      }, 0);
    }
    else {
      $scope.setCurrentEntry(entryID);
      StepService.goTo(entryID, $scope.entries, step, updateHistory);
    }
  };
  $scope.setCurrentEntry = function(currentEntry) {
    $scope.currentEntry = currentEntry !== 'checkout' ?
      currentEntry : $scope.currentEntry;
  };
  $scope.goToLastStep = function goToLastStep(entryID) {
    $scope.setCurrentEntry(entryID);
    StepService.goToLast(entryID);
  };
  $scope.isEntryInvalid = function isEntryInvalid(entryID) {
    return StepService.isEntryInvalid(entryID);
  };
  $scope.hasInvalidEntries = function() {
    var invalid = false;
    angular.forEach($scope.entries, function(entry) {
      invalid = invalid || $scope.isEntryInvalid(entry._id);
    });
    return invalid;
  };
  $scope.isArr = function(arr){
   return Array.isArray(arr);
  };
  $scope.objectLength = function(obj){
   return Object.keys(obj).length;
  };
  $scope.hasIncompleteRegistration = function() {
      var uncompleted = false;
      angular.forEach($scope.entries, function(entry) {
        uncompleted = uncompleted || !StepService.areStepsCompleted(entry._id);
      });
      return uncompleted;
  };
  $scope.isStepIndicatorEnabled = StepService.isStepIndicatorEnabled;
  
  $scope.$on('ctlive.reg.step.leave', function(param, steps) {
    if(!steps.updateHistory) {
      return;
    }
    //Do not update browser history if current and old step are same
    if(steps.old_entry_id === steps.current_entry_id &&
       steps.old === steps.current) {
      return;
    }
  });

  window.addEventListener("popstate", function(e) {
    // If registration is completed we need to prevent user from going back
    if(!$scope.isRegInProgress()) {
      return false;
    }
    //no state === no history to go to
    if(!e || !e.state) {
      return false;
    }
    //if it is real entry and if it is not in scope entries then do not go to that step in history
    if(e.state.entryID !== 'checkout' && !$scope.entries[e.state.entryID]) {
      return false;
    }
    //if you are navigating on entry without reg option then delete it.
    //Cannot tell if it is back or forward.
    //Either way entry does not have reg option only on first slide
    if($scope.currentEntry !== 'checkout' &&
       !$scope.entries[$scope.currentEntry].regOptionID) {
      $scope.removeEntry($scope.currentEntry, false);
    }
    $scope.goToStep(e.state.entryID, e.state.step, false);
  });

  $scope.$watch("entries[currentEntry].regOptionID",
    function(regOptionID){
      var regOption = regOptionID ? $scope.regOptions[$scope.entries[$scope.currentEntry].regOptionID] : false;
      if (regOption) {
        //fixing problem when removing 2nd entry, so that the first one does not
        //end up without team.
        if (!$scope.omitTeamReset) {
          TeamService.resetTeamData($scope.entries[$scope.currentEntry]);
          GroupService.resetGroupData($scope.entries[$scope.currentEntry]);
        }
        $scope.omitTeamReset = false;
        $scope.regOptionAgeTranslate = { min: regOption.minAge, max: regOption.maxAge };
        $scope.requiredDateFormatTranslate = { format: $scope.dateFormat };
        if(regOption.regRefundEnabled) {
          $scope.regRefundPrice = regOption.regRefundPrice;
          $scope.translateData.regRefundCurrentPrice = CartService.formatPrice(
            regOption.regRefundPrice, regOption.currency
          );
        }
      }
    }
  );

  $scope.$watch("entries[currentEntry].team.team_bracket_id",
    function(teamBracketID){
      if (teamBracketID) {
        var regOption = $scope.regOptions[$scope.entries[$scope.currentEntry].regOptionID];
        var bracket = _.findWhere(regOption.teamInfo.teamBrackets, {id: teamBracketID});
        if (bracket.enable_total_max_cap > 0) {
          $scope.entries[$scope.currentEntry].team.max_team_members = bracket.total_max_members;
        }
        else {
          $scope.entries[$scope.currentEntry].team.max_team_members = _.reduce(bracket.teamBracketRestrictions, function(sum, restriction) {
            return sum + parseInt(restriction.max_members, 10);
          }, 0);
        }
      }
    }
  );

  /**
   * Called when registration is finalized.
   * Remap entries, formats phone and manage team selection.
   * On success registration is completed. Errors are displayed if it is not
   * successful.
   *
   * @returns {undefined}
   */
  $scope.submitForm = function submitForm(estimatePrice) {
    // Do not disable payment form if we just want to estimate taxes with avalara
    $scope.disablePaymentButton = !estimatePrice;
    if (!estimatePrice) {
      // We won't wait for avalara. We will just update cart once response comes
      LoaderService.show(0);
    }
    $scope.billingDataChange(); 

    /**
     * Phone should be digits only
     *
     * @param {Number} phone
     * @returns {String}
     */
    function formatPhone(phone) {
      if (!phone.number) {
        return null;
      }
      return phone.code + ' ' + phone.number;
    }

    var entries = _.map($scope.entries, function mapEntries(e) {
      var entry = _.pick(e, function entryPick(value, key) {
        var requiredKeys = ['regOptionID', 'firstName', 'lastName', 'dob', 'sex', 'country',
        'address', 'address2', 'postalCode', 'city', 'region', 'email', 'emailConfirm',
        'partialEntryID', 'shirt', 'xnCoupon', 'athleteUpdate'];
        if (_.indexOf(requiredKeys, key) !== -1) {
          return true;
        }
        var excludedKeys = ['team'];
        if (_.indexOf(excludedKeys, key) !== -1) {
          return false;
        }
        // Only include these if they are not undefined.
        var optionalKeys = ['donation', 'usatMembership', 'donationMessage', 'donationName', 'donationType'];
        if (_.indexOf(optionalKeys, key) !== -1) {
          if (['donationMessage', 'donationName', 'donationType'].includes(key)) {
            return value && typeof value === 'string' && value.length >= 0;
          }
          return typeof value !== "undefined" && bccomp5(value, '0') > 0;
        }
        if (/^waiver/.test(key)) {
          return true;
        }
        // Only thing left that we might consider keeping are custom question elements.
        return !StepService.isCustomElementHidden(e._id, key);
      });
      entry.phone = formatPhone(e.phone);
      if (e.emergPhone.number) {
        entry.emergNam = e.emergContact.name;
        entry.emergRelationship = e.emergContact.relationship;
        entry.emergPhone = formatPhone(e.emergPhone);
      }
      else {
        delete entry.emergPhone;
      }
      // needs to be object of sms_number_1, sms_number_2, etc..
      entry.mobileNumbers = {};
      _.each(e.mobileNumbers, function(mobile, i) {
        // make index 1 based
        i++;
        entry.mobileNumbers['sms_number_'+i] = mobile.phone.number;
        entry.mobileNumbers['sms_cc_'+i]     = mobile.phone.countryID.toLowerCase();
        entry.mobileNumbers['sms_lang_'+i]   = mobile.language;
      });

      if (TeamService.areTeamsRequired(e) && TeamService.canOnlyJoinTeams(e)) {
        e.teamRegType = 'JOIN_TEAM';
      }

      if (typeof e.teamRegType !== 'undefined' && e.team && e.team.name) {
        switch(e.teamRegType) {
          case 'CREATE_TEAM':
          case 'CREATE_TEAM_AND_PREPAY':
          case 'JOIN_TEAM':
            entry.joinOrCreateTeam = e.team;
            break;
          default:
            entry.joinOrCreateTeam = [];
            break;
        }
      }

      if (typeof e.groupRegType !== 'undefined' && e.group && e.group.name) {
        switch(e.groupRegType) {
          case 'CREATE_GROUP':
          case 'JOIN_GROUP':
            entry.joinOrCreateGroup = e.group;
            break;
          default:
            entry.joinOrCreateGroup = [];
            break;
        }
      }

      entry.crowdrise = e.crowdrise;
      entry.source_type = $scope.isSeries() ? "SERIES" : "ON-LINE";
      return entry;
    });

    function createData(token, config) {
      return {
        entries: entries,
        notifications: LoginService.getNotifications(),
        cartTotal: $scope.cart.total,
        priceConfirmed: false,
        paymentToken: token,
        transactionToken: regFormApp.resources.transactionToken,
        processorConfig: config,
        la: $location.search().la,
        preview: $location.search().preview,
        version: $location.search().version,
        tag: $location.search().tag,
        lc: 'es'
      };
    }

    function getToken() {
      // If we don't need to get a token, just return automatically.
      if (estimatePrice || $scope.cardData.paymentToken !== null || !$scope.cart.showPaymentForm() || $scope.handleMasterpassEvent) {
        var retData = createData($scope.cardData.paymentToken, $scope.cardData.processorConfig);
        return $q.when(retData);
      }
      
      JsLogService.logAction("paymentProcessor.createToken.start");
      var card, objectToUse;
      var isTestMode = $scope.cart.isTestFormCheck();
      if ($scope.isAdyen()) {
        card        = $scope.cardData;
        objectToUse = $.paymentProcessor;
      } else {
        card            = isTestMode ? $scope.cart.getStripeElementTest() : $scope.cart.getStripeElement();
        objectToUse = isTestMode ? $.paymentProcessorTest : $.paymentProcessor;
      }
      
      card.isAdyenCC = $scope.isAdyenCC();

      return objectToUse.createToken(card, $scope.cardData.billingName)
      .then(function(dataReturn) {
        var data = {};
        if (dataReturn !== null) {
          JsLogService.logAction("paymentProcessor.createToken.succes", dataReturn);
          var token = dataReturn.token;
          var config = dataReturn.config;

          $scope.cardData.processorConfig = config;
          $scope.cardData.paymentToken = token;
          data = createData(token, config);
        }
        else {
          data = createData(null, null);
        }
        return data;
      }, function(error){
        $rootScope.ajaxSent = false;
        // Cast payment processor errors into format expected below
        JsLogService.info("RR: Error from payment processor", error);
        return $q.reject({error: error.message});
      });
    }

    function goToCheckoutDone() {
      $(window).unbind('beforeunload');
      StepService.goTo("checkout", false, "done", false);
      if (!regFormApp.resources.paymentProcessor.isHpp || (regFormApp.resources.paymentProcessor.isHpp && parseFloat($scope.cart.total) === 0)) {
        LoaderService.hide();
      }
    }

    /**
     * Hpp Form generation
     * @param {type} fields
     * @returns {String}
     */
    function generateHppForm(data) {
      var url = regFormApp.resources.paymentProcessor.skipUrl;
      var name = regFormApp.resources.paymentProcessor.name;
      var form = "<form name=\"" + name + "Form\" action=\"" + url + "\" method=\"post\">";
      angular.forEach(data, function(value, key) {
        form += '<input type="hidden" name="' + key + '" value="' + value + '" />';
      });
      form += '<input type="submit" value="submit" />';
      form += "</form>";
      return form;
    }
    
    function register(regData) {
      var d = $q.defer();
      JsLogService.logAction("register.start", regData);
      regData.reg_language = $rootScope.currentLanguage;
      regData.lc = $rootScope.currentLanguage;
      regData.old_entry_id = $location.search().eid;
      regData.paymentMethod = $scope.isAdyen() ? $scope.cart.paymentMethod : null;
      regData.isMasterpass = $scope.handleMasterpassEvent;
      regData.issuerId = $scope.selectedIssuer[$scope.cart.paymentMethod] ? $scope.selectedIssuer[$scope.cart.paymentMethod] : null;
      
      regData.isAdyenCC = $scope.isAdyenCC();
      regData.estimatePrice = estimatePrice;
      regData.transaction = $scope.transaction;
      regData.wantsShippingAddress = CartService.wantsShippingAddress()
      regData.shippingFee = CartService.getShippingFee()
      regData.shippingAddress = $scope.shippingAddress;
      $http.post('/reg/register/eventID/' + $rootScope.eventInfo.eventID, $.param(regData)).
        success(function postSuccess(data) {
          $scope.entryReferralData = data.entryReferralData;
          if (estimatePrice) {
            $scope.avalara.transactionCode = data.salesTaxInfo.masterTransaction.transactionCode;
            CartService.setTax(data.salesTaxInfo.masterTransaction.prices.taxesTotal || 0);
            $scope.transaction = data.result.transaction;

            if (data.salesTaxInfo.lotteryTransaction) {
              CartService.setLotteryTax(data.salesTaxInfo.lotteryTransaction.prices.taxesTotal || 0);
            }
            return;
          }
          $scope.cart.paymentMethod = regData.paymentMethod;
          $rootScope.ajaxSent = false;
          JsLogService.logAction("register.return", data);
          $scope.paymentErrors = false;
          // If there are errors, return to pay and print them
          var PRICING_TIER_CHANGE_STATUS = 2;
          var EVENT_FULL_STATUS          = 3;
          var TRANSACTION_TOKEN_RESUSE   = 4;
          if (data.status === PRICING_TIER_CHANGE_STATUS && !regData.priceConfirmed) {
            // We need to find a proper way to test this
            if ($window.confirm(data.error)) {
              regData.priceConfirmed = true;
              d.resolve(register(regData));
              return;
            }
          }
          if (data.status === EVENT_FULL_STATUS) {
            if ($window.confirm(data.error)) {
              $scope.regInProgress = false;
              $(window).unbind('beforeunload');
              window.location.href = data.url;
              return;
            }
          }
          if (data.status === TRANSACTION_TOKEN_RESUSE) {
            JsLogService.info('RR: Transaction Token Reuse Error', {error: data, regData: regData});
            $window.alert(data.error);
            $scope.regInProgress = false;
            goToCheckoutDone();
            return;
          }
          
          if (data && data.result && data.result.customHtml) {
            $('#customHtml').html(data.result.customHtml);
          }
          
          if (!data || data.status > 0) {
            d.reject(data);
          }
          else {
            // We need to redirect to masterpass if we succesfuly created pending transaction
            if($scope.handleMasterpassEvent) {
              if(!$scope.masterpassCheckout(data.result)) {
                JsLogService.info('RR: Transaction Token Reuse Error', {error: data, regData: regData});
                d.reject(data);
              }
            }
            
            if (regFormApp.resources.paymentProcessor.isHpp && parseFloat($scope.cart.total) > 0 && !$scope.isAdyenCC()) {
              //need to load form (and submit it)
              //it is done by directive cthpp (has a $watch on $scope.hpp)
              $scope.hpp = generateHppForm(data);
            } else {
              $scope.regInProgress = false;
              $window.onbeforeunload = null;
              if (data.result && data.result.athlinks) {
                AthlinksService.setPartialEntries(data.result.athlinks);
                AthlinksService.openAthleteSyncModal($scope);
              }
            }
            d.resolve(data);
          }
        }).
        error(function postError(data, status, headers, config, statusText) {
          if (!angular.isObject(data)) {
            data = {};
          }
          if (typeof data.error === "undefined") {
            data.error = statusText;
            data.serverError = status;
          }
          JsLogService.info('RR: Error registering user', {error: data, regData: regData});
          d.resolve(data);
        });
      return d.promise;
    }
    
    getToken()
      .then(register)
      .then(function regSuccess(data) {
        if(typeof data.serverError != 'undefined') {
          JsLogService.info('RR: Error from server in regSuccess', {data: data});
          data = { error : $translate.instant('SUBMIT-ERROR') };
          // Error messages can be a single string, or an array of entries that have errors.
          // Coerce this into a single format.
          $scope.paymentErrors = {
            message: typeof data.error === "string" ? data.error : null,
            entry_errors : typeof data.error === "object" ? data.error : []
          };
          $rootScope.$broadcast('ctlive.reg.error');
        } else {
          goToCheckoutDone();
          $rootScope.$broadcast($scope.eventString, {
            step: 'Registered',
            entries: $scope.entries,
            registration: data.result,
          });
          if ($rootScope.account.isGuest) {
            $rootScope.$broadcast('ctlive.reg.registered.guest', {'step':'Registered as guest'});
          }
          if (_.size(entries) === 1) {
            $rootScope.$broadcast(
              'ctlive.reg.registered.individual',
              {'step':'Registered as individual'}
            );
          }
          else {
            $rootScope.$broadcast('ctlive.reg.registered.group', {'step':'Registered as group'});
          }
          if((typeof data.result !== 'undefined') || (typeof data.result === 'undefined' && data.result !== 'undefined')) {
            provider_info = (typeof data.result === 'undefined' && data.result !== 'undefined') ? data.provider_info : data.result.provider_info;
            LoginService.setupShare(provider_info);
          }
        }

        // Generate the SSO Join link (note: if the user is currently logged
        // into SSO, then this will get returned as an empty string, and we
        // will suppress the "Join Athlinks" button, which would be redundant.
        var joinAthlinksUrl = data.result && data.result.joinAthlinksUrl || '';
        if (joinAthlinksUrl.length > 0) {
          $('#join-athlinks-btn').attr('onclick', 'window.location.replace("' + joinAthlinksUrl + '")');
          $('div#upsell').show();
        }

        return data;
      })
      .catch(function regFail(data) {
        if (!data) {
          data = { error : $translate.instant('SUBMIT-ERROR') };
        }
        // Error messages can be a single string, or an array of entries that have errors.
        // Coerce this into a single format.
        JsLogService.debug("RR: Payment error", data);
        $scope.paymentErrors = {
          message: typeof data.error === "string" ? data.error : null,
          entry_errors : typeof data.error === "object" ? data.error : []
        };
      })
      .finally(function regFinally() {
        $scope.disablePaymentButton = false;
        if (
          ($scope.isAdyen() && parseFloat($scope.cart.total) === 0) || !$scope.isAdyen() || ($scope.paymentErrors && $scope.paymentErrors.entry_errors.length > 0 && $scope.isAdyen()) || $scope.isAdyenCC() || $scope.cart.paymentMethod === 'maestro'
        ) {
          LoaderService.hide();
        }
      });
  };

  /**
   * Check if registration is in process.
   *
   * @returns {Boolean} true if entry count is greater than 0.
   */
  $scope.isRegInProgress = function() {
    return $scope.regInProgress > 0;
  };
  /**
   * This method is used for mocking location search
   */
  $scope.getLocationSearch = function() {
    return location.search;
  };
  /**
   * Adds another athlete. Also called on registration initialization.
   *
   * @returns {undefined}
   */

  $scope.addAnother = function addAnother() {

    angular.forEach($scope.regOptions, function(regOpt){
      if (DateService.age(regOpt.openeningDate, new Date()) < 0 ) {
        regOpt.registrationClosed = 1;
      }
    })

    var phoneObject = {
      countryID: $rootScope.eventInfo.locationCountry,
      code: '1',
      number: ''
    };

    var emergPhoneObject = {
      countryID: $rootScope.eventInfo.locationCountry,
      code: '1',
      number: ''
    };

    if(typeof $scope.entries[$scope.mainEntry] !== 'undefined') {
      var mainEntryData           = $scope.entries[$scope.mainEntry];
      phoneObject.countryID       = mainEntryData.phone.countryID;
      phoneObject.code            = mainEntryData.phone.code;
      emergPhoneObject.countryID  = mainEntryData.emergPhone.countryID;
      emergPhoneObject.code       = mainEntryData.emergPhone.code;
    }

    var entry = {
      // this is a temporary id just used in javascript
      _id: _.now(),
      partialEntryID: null,
      regOptionID: null,
      seriesEvents: {},
      firstName: null,
      lastName: null,
      dob: null,
      sex: null,
      email: null,
      emailConfirm: null,
      address: null,
      address2: null,
      group: {},
      groupRegType: 'REGISTER_AS_INDIVIDUAL',
      postalCode: null,
      region: null,
      city: null,
      athlinks: AthlinksService.defaultData(),
      country: $rootScope.eventInfo.locationCountry,
      shirt: null,
      marketing_emails: null,
      third_party_emails: null,
      xnCoupon: '',
      coupon: CouponService.defaultCoupon(),
      membershipCoupon: null,
      teamDiscountCoupon: null,
      phone: phoneObject,
      emergPhone: emergPhoneObject,
      emergContact: {
        name: null,
        relationship: null,
      },
      mobileNumbers: [],
      athleteUpdate: {
        updatesLanguage: 'en',
        twitter: 0,
        facebook: 0
      },
      crowdrise: {
        wantsCrowdrise: null,
        fundraisingOption: null,
        selectedTeam: null,
        newCrowdriseTeam: null,
      },
      donationDefaultApplied: false,
      donation: 0,
      storefront: {
        products: []
      },
      earlyRegUserInput: null,
      shippingAddress: {},
      isUsatMembershipCodeValid: false,
      currentValidUsatMembershipCode: ''
    };

    $scope.shippingAddress = {
      firstName: '',
      lastName: '',
      country: '',
      street: '',
      city: '',
      email: '',
      postalCode: '',
      region: ''
    };

    
    var currentEntry = $scope.entries[$scope.currentEntry];
    TeamService.resetTeamData(entry);
    GroupService.resetGroupData(entry);
    if (_.size($scope.entries) > 0) {
      var firstEntry = $scope.entries[$scope.mainEntry];
      _.extend(entry, _.pick(firstEntry, 'lastName', 'address', 'address1', 'postalCode',
          'city', 'region', 'country', 'phone.countryID', 'phone.code'));
    }

    $scope.entries[entry._id] = entry;
    $scope.setCurrentEntry(entry._id);
    $scope.mainEntry = $scope.mainEntry || $scope.currentEntry;
    $scope.entryCount = _.size($scope.entries);

    //If regOptionID is in url params and if that reg option exists then we will
    //automatically set it as first entry's reg option.
    if($scope.entryCount === 1) {
      // We cant use $locaiton or it messes up the url fragments.
      // From http://stackoverflow.com/a/901144/895588
      var regex = new RegExp("[\\?&]" + "regOptionID" + "=([^&#]*)"),
          results = regex.exec($scope.getLocationSearch());
      var newRegOptionID = results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
      if (newRegOptionID && $scope.regOptions[newRegOptionID] &&
          !$scope.regOptions[newRegOptionID].registrationClosed &&
          !$scope.regOptions[newRegOptionID].isFull) {
        entry.regOptionID = newRegOptionID;
      }
    }

    $rootScope.$broadcast('newEntryAdded');
    $rootScope.refreshPaymentMethods = true

    var shirtProduct = ProductsService.getProduct('shirt');
    if ($scope.entryCount > 1 && currentEntry && currentEntry.shirt && typeof shirtProduct !== 'undefined') {
      shirtProduct.options[currentEntry.shirt]['sold'] = parseInt(shirtProduct.options[currentEntry.shirt]['sold']) + 1;
    }

    // Want to make sure $digest has had time to create the new elements
    // before we jump to it.
    $timeout(function() {
      StepService.goToFirst($scope.currentEntry);
      if(entry.regOptionID) {
        $scope.regOptionClick($scope.regOptions[entry.regOptionID]);
      }
    });
    return entry;
  };
  // setup the initial entry
  var initialEntry = $scope.addAnother();
  /*
    This function will parse the url hash as a query string,
    then compose it into a deep object, and merge it with the entry.

    Example:
    importFromUrlHash(entry, "#firstName=Alan&lastName=Col%F3n&phone.code=1&phone.countryID=US&phone.number=%28909%29%20282-0720")
    will result in
    {
      ...entry
      firstName: "Alan",
      lastName: "Colón",
      phone: {
        code: "1",
        countryID: "US",
        number: "(909) 282-0720"
      }
    }
  */
  function importFromUrlHash(target, hash) {
    function envObj(env, obj, delim) { // From https://github.com/alancnet/env-obj/blob/master/index.js
      if (!env) env = process.env;
      if (!obj) obj = {};
      if (!delim) delim = '.';
      function put(r, k, v) {
        var w = k.split(delim);
        var a = w[0];
        var e = r.hasOwnProperty(a);
        if (w.length == 1) {
          r[a] = v;
        } else if (!e) {
          r[a] = {};
        }
        if (w.length > 1) {
          put(r[a], w.slice(1).join(delim), v);
        }

      }
      for (var key in env) {
        var val = env[key];
        put(obj, key, val);
      }
      return obj;
    }
    if (hash) {
      var qs = (hash[0] == '#') ? hash.substr(1) : hash;
      var query = {};
      qs.split('&')
        .map(function(x) {
          var i = x.indexOf('=');
          return i == -1 ? [unescape(x), null] :
          [unescape(x.substr(0, i)), unescape(x.substr(i+1))];
        })
        .forEach(function(pair) {
          query[pair[0]] = pair[1];
        });

      return envObj(query, target, '.');
    }
  }

  RegionService.countries.then(function(countries) {
    $scope.countries = countries;
    importFromUrlHash(initialEntry, $window.location.hash);
    // We need to set phone code if country is other then US
    if(initialEntry.phone.country !== 'US') {
      initialEntry.phone.code = $scope.countries[initialEntry.phone.countryID].itu_code;
    }
    if(initialEntry.phone && initialEntry.phone.countryID) {
     removeCountryCode(initialEntry);
    }
  });
  
  if (initialEntry.isGuest) {
    $rootScope.account.isGuest = true;
    var acct = $rootScope.account;
    delete initialEntry.isGuest;
  }
  /**
   * Initialize cart service with products, fees and reg options
   */
  $scope.cart = CartService.init(
     $scope.regOptions,
    $scope.entries,
    { 'theCurrency' : regFormApp.resources.currency,
      'theProducts' : regFormApp.resources.products,
      'theFees' : regFormApp.resources.fees,
      'theEventInfo' : regFormApp.resources.eventInfo,
      'thePaymentProcessor' : regFormApp.resources.paymentProcessor,
      'theCustomFees' : regFormApp.resources.customFees,
      'theCustomFeesOptions' : regFormApp.resources.customFeesOptions,
      'theSeriesEvents' : $scope.seriesEvents,
      'theIsSeries' : $scope.isSeries(),
      'theSeries' : $scope.series,
      'theSalesFees': regFormApp.resources.salesFees
    }
  );


  $scope.getIsStripeElementsValid = function () { return $scope.cart.getIsStripeElementsValid(); }

  $scope.addStorefrontProductToCart = function(entry, product) {
    var optionId = entry.storefront.products[product.id].option.id;
    var quantity = entry.storefront.products[product.id].option.selectedQuantity;
    var price = entry.storefront.products[product.id].price;
    var deliveryType = product.deliveryType;
    if(deliveryType === 'USER_DECIDES') {
      deliveryType = entry.storefront.products[product.id].deliveryType;
    }
    var sum = 0
    if (typeof entry.storefront.products[product.id].optionsInCart !== 'undefined') {
      // if we want to update quantity, return 0
      // since we do not want to include it in total sum because it is going to be replaced,
      // else return quantity of option in cart
      var quantities = entry.storefront.products[product.id].optionsInCart.map(
        optionInCart => optionInCart.id === parseInt(optionId) ? 0 : optionInCart.quantity
        )
      sum = quantities.reduce((partialSum, a) => partialSum + a, 0);
    }
    if (StorefrontService.checkIfMaxItemsPerPersonIsExceeded(product.id, entry.regOptionID, (sum + quantity))) {
      generateErrorToast(
        $translate.instant(
          'STOREFRONT-QUANTITY-ERROR',
          {
            maxItemsPerPerson: StorefrontService.getMaxItemsPerPersonForProduct(product.id, entry.regOptionID)
          }
        )
      );
      return
    }
    if (optionId && quantity && deliveryType) {
      if (typeof entry.storefront.products[product.id].optionsInCart === 'undefined') {
        entry.storefront.products[product.id].optionsInCart = []
      }
      var existingOptionInCart = entry.storefront.products[product.id].optionsInCart.find(
        optionInCart => optionInCart.id == optionId
        )
      if (existingOptionInCart) {
        existingOptionInCart.quantity = quantity
      } else {
        entry.storefront.products[product.id].optionsInCart.push({id: optionId, quantity, deliveryType, price: product.options?.find(x => x.id == optionId)?.price})
      }
      
      generateSuccessToast($translate.instant('STOREFRONT-ADDED-TO-CART'));
    } else {
      generateErrorToast($translate.instant('STOREFRONT-ADD-TO-CART-ERROR'));
    }
  }

  $scope.$on('ctlive.reg.step.leave',
    /**
     * We same partial entry after registrant passes contact slide.
     *
     * @param {Object} event
     * @param {Object} data
     * @returns {undefined}
     */
    function(event, data) {
      if (data.isProductsSlide) {
        // $scope.submitForm(true); // We should try to estimate taxes if old step is product slide
      }
      if(!$scope.entries[data.current_entry_id]) {
        return;
      }
      //function that is used to send data and create a partial entry
      //(when reg is started but not finshed)
      var entry = $scope.entries[data.current_entry_id];
      if(typeof $scope.entries[$scope.mainEntry] !== 'undefined') {
        var mainEntryData = $scope.entries[$scope.mainEntry];
        JsLogService.updatePayload({person: {
          id: mainEntryData.email,
          email: mainEntryData.email,
          username: mainEntryData.firstName + ' ' + mainEntryData.lastName
        }});
      }
      if (Object.values)
      if (entry.firstName !== '' && entry.firstName !== null &&
          entry.email !== '' && entry.email !== null) {
        $http.post('/reg/create-partial-entry', $.param({
          firstname     : entry.firstName,
          lastname      : entry.lastName,
          email         : entry.email,
          dob           : entry.dob,
          sex           : entry.sex,
          reg_option_id : $scope.isSeries() ? $scope.series.regOptionID : entry.regOptionID,
          partial_entry_id : entry.partialEntryID,
          eventID       : regFormApp.resources.eventInfo.eventID,
          slide         : data.current,
          region        : entry.region,
          postal_code   : entry.postalCode
        })).then(function (response) {
          entry.partialEntryID = response.data.partial_entry_id;
          if (entry.regOptionID) {
            // $scope.submitForm(true);
          }
        });
      }
    }
  );

  $rootScope.$on('ctlive.reg.step.goto.donations',
    function(event, data) {
      var entry = $scope.entries[data.current_entry_id];
      if (entry.donation === 0 && !entry.donationDefaultApplied) {
        entry.donation = $rootScope.eventInfo.donationDefault;
        entry.donationDefaultApplied = true;
      }
    }
  );

  $rootScope.$on('ctlive.reg.submit-form', function(event, data) {
    console.log('submitting-form')
    $scope.submitForm(true);
  });
  $rootScope.$on('ctlive.reg.shipping_fee_changed', function(event, data) {
    var current = StepService.getCurrent()
    if (current.step === 'payment') {
      $scope.submitForm(true);
    }
  });
  
  $rootScope.$on('ctlive.reg.step.goto.groupUSAT',
    function(event, data) {
      var entry = $scope.entries[data.current_entry_id];
      var ageref = $scope.regOptions[entry.regOptionID].ageRefTimes[0];
      var dob = DateService.returnDateInFormat(entry.dob, $scope.dateFormat);
      entry.usatRaceAge = DateService.age(dob, new Date(ageref));  
    }
  );
  $scope.$on('ctlive.reg.step.leave.ident',
    function(event, data) {
      var entry = $scope.entries[$scope.currentEntry];
      if(!entry || !$scope.regOptions[entry.regOptionID] || !$scope.regOptions[entry.regOptionID].race) {
        return;
      }
      AthlinksService.matchEntry(entry, $scope.regOptions[entry.regOptionID].race).then(function(data){
        entry.athlinks = data;
      });
    }
  );

  // Generate event if entries updates, but only every 2/10ths of a second.
  // Why 2/10ths? 1 second seemed to long, anything else feels to short. Gut feeling.
  $scope.$watch(
    "entries",
    _.debounce(function entriesChange(entries) {
      $rootScope.$broadcast("ctlive.reg.entries-change", entries);
    }, 200),
    true
  );
  
  /**
   * We do not want to hit api many times when changing email, execute call after certain time
   */
  $scope.revalidateTrigger = _.debounce(function() {
    if($scope.is_usat_membership) {
      var entry = $scope.entries[$scope.currentEntry];
        if(entry.updateCode || entry.licenceCode || $scope.isYouthUsat(entry)) {
           $scope.revalidateMembership();
        }
    }
  }, 3000);
  /**
   * Opens modal that displays certain warnings
   */
  $rootScope.show = function() {
    var flatRateCaptain = $scope.checkTeamCaptain();
    var doubledEntries = $scope.checkDoubleEntriesReg();
    ModalService.showModal({
      templateUrl: 'regModal',
      controller: 'ModalController'
    }).then(function(modal) {
        var step = $scope.getActiveStep();
        if(step === "groupJoinOrCreateTeam" || (step === "registration-review" && flatRateCaptain === false) || (step === "payment" && flatRateCaptain === false) || (step === "" && $scope.cart.total === '0.00' && flatRateCaptain === false)){
          modal.element.find('#modalMessageNoCaptain').show();
        } else{
            if(doubledEntries === true){
              modal.element.find('#modalMessageDoubles').show();
            } else{
                if(step === "ident"){
                  modal.element.find('#modalMessagePersonalInfo').show();
                } else if(step === "payment" || step === 'registration-review' || (step === "" && $scope.cart.total === '0.00')){
                    modal.element.find('#modalMessagePayment').show();
                  }
              }
        }
        modal.element.modal();
        modal.close.then(function(result) {
          $scope.message = "Result: " + result;
         });
    });
  };
  
  /**
   * Check if entries added with "Add new entry" option are doubled
   * 
   */
  $scope.checkDoubleEntriesReg = function(){
    if(!$scope.entries[$scope.currentEntry]) {
     return;
    }
    var currentEntry = $scope.entries[$scope.currentEntry];
    var doubleEntry = false; 
    angular.forEach($scope.entries, function(entry) {
     if($scope.entries[$scope.currentEntry]._id !== entry._id){
       if(currentEntry.firstName === entry.firstName &&
         currentEntry.lastName === entry.lastName &&  
         currentEntry.dob === entry.dob &&
         currentEntry.sex === entry.sex &&
         currentEntry.email === entry.email){
           doubleEntry = true;
       }
     }
    });
    return doubleEntry;
  };
  
  /**
   * Check if entry with given params has already registered for reg option
   * 
   */
  $scope.isEntryRegistered = function(){
    if(!$scope.entries[$scope.currentEntry]) {
      return;
    }
    var currentEntry = $scope.entries[$scope.currentEntry];
    if (currentEntry.firstName !== '' && currentEntry.firstName !== null &&
        currentEntry.lastName !== '' && currentEntry.lastName !== null &&
        currentEntry.dob !== '' && currentEntry.dob !== null &&
        currentEntry.email !== '' && currentEntry.email !== null) {
      return $http.post('/reg/is-entry-already-registered', $.param({
        reg_option_id : $scope.isSeries() ? $scope.series.regOptionID : currentEntry.regOptionID,
        firstname     : currentEntry.firstName,
        lastname      : currentEntry.lastName,
        dob           : currentEntry.dob,
        date_format   : $scope.dateFormat,
        sex           : currentEntry.sex,
        email         : currentEntry.email
      }));
    }
  };

  /**
   * This is where we create or update avalara transactions
   * We will always send customer code or transaction code if we have it
   * And we will always overwrite avalara object with what we get from server
   * @returns {*}
   */
  $scope.createOrUpdateAvalaraTransaction = function() {
    if(!$scope.entries[$scope.currentEntry]) {
      return;
    }
    var currentEntry = $scope.entries[$scope.currentEntry];
    return $http.post('/reg/handle-avalara-transaction', $.param({
      avalara: $scope.avalara,
      entry: currentEntry,
      cart: $scope.cart,
      eventId: $rootScope.eventInfo.eventID
    })).then(function(response){
      $scope.avalara = response.data;
    });
  };
  
  /**
  * Checking entry reg data: if entry is already registered,
  * if flatrate team has no captain and if there are duplicate entries allowed
  * Also validating coupons for team discounts if captain pre-pays for entries
  */
  $scope.checkEntryRegData =  function(options) {
    $scope.handleMasterpassEvent = options && options.handleMasterpassEvent ? options.handleMasterpassEvent : false;
    
    var step = $scope.getActiveStep();
    var doubledEntries = $scope.checkDoubleEntriesReg();
    var flatRateCaptain = $scope.checkTeamCaptain();
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    var currentRegOption = entry.regOptionID;
    var skipModal = false;
    $scope.checkForTeamDiscount(entry, currentRegOption);

    if(flatRateCaptain === false){
      $rootScope.show();
      return;
    } else{
        if(step === 'groupJoinOrCreateTeam'){
          $scope.checkTeamPassword();
          return;
        }
    }
    angular.forEach($scope.regOptions, function(regOpt){
      if(regOpt.value === currentRegOption){
        if(typeof regOpt.reg_class_id !== "undefined"){
          if(regOpt.reg_class_allow_duplicate_entries === '1'){ 
            skipModal = true; 
          }
        }
        if(regOpt.allow_duplicate_entries === '1'){
            skipModal = true; 
        }
      }
    });

    if(doubledEntries === true && skipModal === false){
      $rootScope.show();
      return;
    }
    var paymentMethod = $scope.cart.paymentMethod;
    // $scope.createOrUpdateAvalaraTransaction().then(function(response) {
    //   console.log(response, 'response');
    //   // @todo update cart here
    // });
    $scope.isEntryRegistered().then(function(response) {
      if(response.data.registered === true && skipModal === false) {
        $rootScope.show();
      } else {
          if(step === 'ident'){
            StepService.next('ident');
          } else if(step === 'payment' || step === 'registration-review' || (step === "" && parseInt($scope.cart.total) === 0)) {
              if($rootScope.ajaxSent === false){
                $rootScope.ajaxSent = true;
                $scope.cart.paymentMethod = paymentMethod;
                $scope.submitForm();
              }
          }
      }
   });
  };
  
  /**
   * Returns active step in reg form
   * 
   */
  $scope.getActiveStep =  function() { 
    var step = '';
    for(var i=0;i<$scope.steps.length;i++){
      if($scope.steps[i].active===true){
        step = $scope.steps[i].id;
       }
    }
    return step;
  };

  /**
   * Check if we need parent's initials.
   *
   * @param (int) entryAge
   * @return bool
   */
  var parentInitialsAge = 17; // The age needed for parent's signature
  $scope.needParentInitials = function (entryAge) {
    return entryAge <= parentInitialsAge;
  };

  $rootScope.notifications = [];
  $scope.dateFormat = regFormApp.resources.eventInfo.dateFormat || DateService.defaultDateFormat;

  /**
   * Only one athlete can get facebook and twitter athlete updates.
   * This checks for and enforces that.
   *
   * @returns {Boolean}
   */
  $scope.showAthleteUpdates = function () {
    // we only show if no one else has twitter or facebook updates
    return ! _.find($scope.entries, function(entry) {
      // Don't count the current entry
      if (entry._id === $scope.currentEntry) {
        return false;
      }
      return entry.athleteUpdate.facebook || entry.athleteUpdate.twitter;
    });
  };
  
  /**
   * Try to match country number in phone number and remove it
   * @param entry Object
   * @returns int
   */
  function removeCountryCode(entry) {
    if($scope.countries[entry.phone.countryID]) {
      var numberForMatching = '+' + $scope.countries[entry.phone.countryID].itu_code;
      var phoneNumber = entry.phone.number;
      if (phoneNumber) {
        if(phoneNumber.indexOf(numberForMatching) !== -1) {
          var newPhoneNumber = phoneNumber.replace(numberForMatching, '');
          $timeout(function() {
            entry.phone.number = newPhoneNumber;
          }, 0); 
        }
      }
    }
  }
  
  /**
   * Handles clicking back from the reg slide
   *
   * @returns {undefined}
   */
  $scope.regSlideBack = function regSlideBack() {
    if ($scope.entryCount === 1) {
      $rootScope.account.isGuest = false;
    }
  };

  $scope.broadcastAgeValidation = function () {
    _delayAgeValidation();
  };

  var _delayAgeValidation = _.debounce(function() {
    $rootScope.$broadcast('validateAge');
  }, 300);

  /**
   * Check if we should display secondary max coupon slide after reg option slide.
   * @returns bool
   */
  $scope.needsSecMax = function needsSecMax() {
    var regOptionId = $scope.entries[$scope.currentEntry].regOptionID;
    if (typeof regOptionId != 'undefined' && regOptionId !== null) {
      var regOption = regFormApp.resources.regOptions[regOptionId];
      return regOption['secondaryPlaces'] > 0;
    }
    else {
      return false;
    }
  };

  /**
   * 
   * @param {str} userInput
   * @param {int} regOptionId
   */
  $scope.validateEarlyReg = function (userInput, regOptionId) {

    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];

    if (memberIdUsed()) {
      generateToast({ message: "This Member ID has already been used. Please use a different ID.", background: "#FFB5B5", color: "#BA5454", length: "5000ms" });
      return
    };

    var payload = {
      regOptionId,
      userInput,
    };
    return $http.post('/reg/validate-early-reg', $.param(payload))
    .then(function(response){
      if (response.data.valid){
        angular.forEach($scope.regOptions, function(regOpt){
          if (regOpt.id == regOptionId) {
            regOpt.registrationClosed = 0
          }
        })
        generateToast({message:"Welcome Life Time Member. Select your registration below.", background: "#A8FAD1", color:"#69CE9C", length:"5000ms"});
        entry.earlyRegUserInput = userInput
      } else {
        generateToast({message:response.data.reason, background: "#FFB5B5", color: "#BA5454",length:"5000ms"});
      }
    });

    function memberIdUsed() {
      var memberIdUsed = false;

      angular.forEach($scope.entries, function (entry) {
        if ((entry.earlyRegUserInput == userInput) && (entry.regOptionId == regOptionId)) {
          memberIdUsed = true;
        }
      });
      return memberIdUsed;
    }
  };

  initToast = function(){
    document.querySelector('.main-container').insertAdjacentHTML('afterbegin', `<div class="toast-container"></div>`);
    toastContainer = document.querySelector('.toast-container');
  }();

  generateErrorToast = function(message) {
    generateToast({
      message,
      background: "#FFB5B5",
      color: "#BA5454",
      length: "5000ms"
    })
  }

  generateSuccessToast = function(message) {
    generateToast({
      message,
      background: "#E8FFF8",
      color: "#5FC2A2",
      length: "5000ms"
    })
  }

  generateToast = function({
    message,
    background = '#00214d',
    color = '#fffffe',
    length = '3000ms',
  }){
    toastContainer.insertAdjacentHTML('beforeend', 
    `<p class = "toast" 
    style="background-color: ${background};
    border: 2px solid ${color};
    color: ${color};
    animation-duration: ${length}">
    ${message}
    </p>`)
    const toast = toastContainer.lastElementChild;
    toast.addEventListener('animationend', ()=>toast.remove());
  }

  /**
   * When click on reg option add beforeunload handler and step to next slide.
   *
   * @param regOption
   */
  $scope.regOptionClick = function (regOption) {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    entry.regOptionID = regOption.id;
    delete entry.xnCoupon;
    var couponCode = {};
    if ($location.search().govx) {
      couponCode = $location.search().govx;
      CouponService.validate(
        $rootScope.eventInfo.eventID,
        entry.regOptionID,
        $scope.entries,
        entryID,
        couponCode,
        false
      )
      .then(function(reason) {
        if (reason.valid) {
          entry.xnCoupon = couponCode;
        }
        _.extend(entry.coupon, reason.coupon);
      });
      $location.search('govx', null);
    }
    else if ($location.search().couponCodeID) {
      couponCode = $location.search().couponCodeID;

      CouponService.validate(
        $rootScope.eventInfo.eventID,
        entry.regOptionID,
        $scope.entries,
        entryID,
        couponCode,
        false
      )
      .then(function(reason) {
        if (reason.valid) {
          entry.xnCoupon = couponCode;
        }
        _.extend(entry.coupon, reason.coupon);
      });
    }
    else if ($location.search().memReg) {
       entry.memReg = 1;
    }
    bazusportsUID = $rootScope.account.identities ? $rootScope.account.identities.bazusports.UID : null;
    CouponService.deleteUse(entry._id);
    CouponService.membershipCoupon(
        $rootScope.account.isGuest ? null : ($rootScope.account.UID ? $rootScope.account.UID : bazusportsUID),
      regFormApp.resources.eventInfo.eventID,
      entry.regOptionID,
      entryID
    )
    .then(function(reason) {
      if(reason.couponName) {
        entry.membershipCouponValue = reason.couponName;
        entry.membershipCoupon = CouponService.getMembershipCoupon(entry._id);
      }
      else {
        entry.membershipCouponValue = '';
        entry.membershipCoupon = null;
        if (reason.renewMessage) {
          alert(reason.renewMessage);
        }
      }
    });

    $rootScope.$broadcast(
      'ctlive.reg.start-reg',
      {"step":"Started registration", "currentRegOption":regOption}
    );

    if(!$scope.isRegInProgress()) {
      //initialize first step of history
      $window.onbeforeunload = function() {
        return gt.gettext($translate.instant("LEAVE-?"));
      };
    }
    $scope.regInProgress = true;
    if(!$scope.isSeries()) {
      var regOptionSex = $scope.regOptions[regOption.id].genders;
      var sex = $scope.regForm.entryForm.identForm.sex.$viewValue;
      $scope.genderClick(sex, regOptionSex);
    }
    StepService.next("regOptions");
  };

  $scope.checkName = function() {
    var entry = $scope.entries[$scope.currentEntry];
    if (entry.firstName && (entry.firstName).length > 1) {
      var firstNameValidity = entry.firstName.match(/[@\.\/]/g);
      $scope.regForm.entryForm.identForm.firstName.$invalid = firstNameValidity;
      $scope.regForm.entryForm.identForm.firstName.$setValidity('invalid', !firstNameValidity);
    }

    if (entry.lastName && (entry.lastName).length > 1) {
      var lastNameValidity = entry.lastName.match(/[@\.\/]/g);
      $scope.regForm.entryForm.identForm.lastName.$invalid = lastNameValidity;
      $scope.regForm.entryForm.identForm.lastName.$setValidity('invalid', !lastNameValidity);
    }
  };

  $scope.hasSelectedGender = function(entrySex, regOptionGenders) {
    return regOptionGenders.some(gender => gender.constant == entrySex);
  }

  $scope.genderClick = function(sex, regOptionGenders) {
    if(!$scope.isSeries()) {
      const hasSelectedGender = $scope.hasSelectedGender(sex, regOptionGenders);
      if(regOptionGenders !== null && !hasSelectedGender) {
        $scope.regForm.entryForm.identForm.sex.$invalid = true;
        $scope.regForm.entryForm.identForm.sex.$pristine = false;
        $scope.regForm.entryForm.identForm.sex.$setValidity('required', false);
      }
      else {
        $scope.regForm.entryForm.identForm.sex.$invalid = false;
        $scope.regForm.entryForm.identForm.sex.$pristine = true;
        $scope.regForm.entryForm.identForm.sex.$setValidity('required', true);
      }
    }
  };

  /**
   * Check if entry used coupon code
   * @param {int} entryID
   * @returns {boolean}
   */
  $scope.isUsedCoupon = function isUsedCoupon(entryID) {
    return CouponService.isUsed(entryID);
  };
  
  /**
   * Check if entry has a team discount for prepaid entries
   * @param {object} entry
   * @param {int} currentRegOption
   * @returns (object} entry
   */
  $scope.checkForTeamDiscount = function checkForTeamDiscount(entry, currentRegOption) {
    TeamService.isTeamSelectionValid(entry);
    if(entry.team &&
      entry.team.prepaidEntriesNum && 
      entry.team.prepaidEntriesNum !== "0" &&
      entry.team.paymentType === "CAPTAIN_PRE_PAYS" && 
      entry.teamRegType === "CREATE_TEAM_AND_PREPAY"){
        CouponService.teamDiscountCoupon(
          $rootScope.eventInfo.eventID,
          currentRegOption,
          entry.team.prepaidEntriesNum,
          $scope.currentEntry
        ).then(function(reason) {
           if (reason.valid) {
             entry.teamDiscountCouponValue = reason.code;
             entry.teamDiscountCoupon = CouponService.getTeamDiscountCoupons(entry._id);
           } else {
              entry.teamDiscountCouponValue = '';
              entry.teamDiscountCoupon = null;
            }
         });
         //unestting xnCoupon if applied before team discount coupon
         if(typeof entry.coupon !== 'undefined' && entry.coupon.discount !== null){
           entry.coupon.discount = null;
           entry.coupon.type = null;
           entry.coupon.max_uses = 1.7976931348623157e+308;
           delete entry.xnCoupon;
         }
         //unestting membership coupon if applied before team discount coupon
         if(entry.membershipCouponValue){
           entry.membershipCouponValue = '';
           entry.membershipCoupon = null;
         }
     } else{
         entry.teamDiscountCouponValue = '';
         entry.teamDiscountCoupon = null;
     }
     return entry;
  };

  $scope.checkCrowdriseTeamExist = function checkCrowdriseTeamExist(crowdriseTeam) {
    function filterTeam(crowdriseTeam) {
      var foundTeam = !_.some($rootScope.crowdrise.teams, function(obj) {
        return obj.toLowerCase() === crowdriseTeam;
      });
      return foundTeam;
    }
    $scope.regForm.entryForm.crowdriseForm[$rootScope.crowdrise.elementNames.newCrowdriseTeam].$setValidity('teamExists', filterTeam(angular.lowercase(crowdriseTeam)));
  };

  /**
   * If join for crowdrise is selected then we will have to pull
   * available teams from crowdrise.
   * We will pull only once per reg run
   * @param entry
   */
  $scope.resetCrowdriseForm = function resetCrowdriseForm(entry) {
    entry.crowdrise.newCrowdriseTeam = null;
    $scope.regForm.entryForm.crowdriseForm[$rootScope.crowdrise.elementNames.newCrowdriseTeam].$setValidity('teamExists', true);
    if(entry.crowdrise.fundraisingOption === 'JOIN' && !!$rootScope.crowdrise.allTeams && !$scope.pulledCrowdriseTeams) {
     CrowdriseService.getLiveCrowdriseTeams($rootScope.eventInfo.eventID).then(function (data) {
       _.extend($rootScope.crowdrise, data);
       $scope.pulledCrowdriseTeams = true;
     });
    }
  };

  $scope.isTeamSelectionValid = TeamService.isTeamSelectionValid;
  $scope.canChoosePaymentStructure = TeamService.canChoosePaymentStructure;
  $scope.canChooseTeamBracket = TeamService.canChooseTeamBracket;
  $scope.showTeamSlide = TeamService.showTeamSlide;
  $scope.showUsatSlide = UsatService.showUsatSlide;
  $scope.showRegisterAsCaptain = TeamService.showRegisterAsCaptain;
  $scope.isJoinedToTeam = TeamService.isJoinedToTeam;
  $scope.areTeamsRequired = TeamService.areTeamsRequired;
  $scope.canCreateTeams = TeamService.canCreateTeams;
  $scope.isMultiStructure = TeamService.isMultiStructure;
  $scope.captainPrePays = TeamService.captainPrePays;
  $scope.canOnlyJoinTeams = TeamService.canOnlyJoinTeams;
  $scope.isProductSoldOut = ProductsService.isProductSoldOut;
  $scope.getAthlinksEntries = AthlinksService.getAthlinksEntries;
  $scope.getAthlinksEntryCount = AthlinksService.getAthlinksEntryCount;
  $scope.setAthlinksEntry = AthlinksService.setEntry;
  $scope.isAthlinksEntrySelected = AthlinksService.isEntrySelected;
  $scope.hasAthlinksEntries = AthlinksService.hasAthlinksEntries;
  $scope.athlinksRunner = AthlinksService.athlete;
  $scope.pulledCrowdriseTeams = false;
  $scope.showStorefrontSlide = StorefrontService.showStorefrontSlide;
  $scope.shouldShowProduct = StorefrontService.shouldShowProduct;
  $scope.getProductOptionRemainingQty = StorefrontService.getProductOptionRemainingQty;
  $scope.setEntryProductOptionQuantity = StorefrontService.setEntryProductOptionQuantity;
  $scope.addStoreProductToCart = StorefrontService.addStoreProductToCart;
  $scope.setShippingCountry = StorefrontService.setShippingCountry
  $scope.wantsShippingAddress = CartService.wantsShippingAddress
  $scope.getShippingFee = CartService.getShippingFee
  $scope.filterProductsByPricingType = StorefrontService.filterProductsByPricingType
  $scope.getFeaturedImage = StorefrontService.getFeaturedImage

  /**
   * Update the region and city when changing the postal code.
   *
   * @return {undefined}
   */
  $scope.postalCodeChange = function postalCodeChange() {
    if (!$scope.regForm.entryForm.identForm.postalCode.$valid) {
      // don't do anything until validity switches to true
      return;
    }
    var entry = $scope.entries[$scope.currentEntry];
    if (entry.country !== "US" || entry.postalCode.length !== 5) {
      // we only look up US codes with length == 5
      return;
    }
    $http({
      method: 'GET',
      url: '/reg/postal-code-change/postalCode/'+ entry.postalCode +'/country/'+ entry.country}).
      success(function(data) {
        // check again in case data was updated before ajax call returned
        if (data.code === 0 && !entry.city) {
          entry.city    = data.city;
          entry.country = data.country;
          entry.region  = data.regionID;
        }
        // If data.code !== 0 means error, don't change anything.
      });
  };

  $scope.calculateShippingCost = function calculateShippingCost() {
    StorefrontService.setShippingCountry($scope.shippingAddress.country)
    $rootScope.$broadcast('ctlive.reg.shipping-country-change');
  };

  /**
   * Update the region and city when changing the postal code on payment form.
   *
   * @return {undefined}
   */
  $scope.billingShippingPostalCodeChange = function billingShippingPostalCodeChange(shippingOrBilling) {
    if (!$scope.paymentForm[shippingOrBilling + 'PostalCode'].$valid) {
      // don't do anything until validity switches to true
      return;
    }

    // var address = $scope[shippingOrBilling + 'Address']
    var postalCode = $scope.paymentForm[shippingOrBilling + 'PostalCode'].$modelValue
    var country = $scope.paymentForm[shippingOrBilling + 'Country'].$modelValue
    var city = $scope.paymentForm[shippingOrBilling + 'City'].$modelValue
    if (country !== "US" || postalCode.length !== 5) {
      // we only look up US codes with length == 5
      return;
    }
    $http({
      method: 'GET',
      url: '/reg/postal-code-change/postalCode/'+ postalCode +'/country/'+ country}).
      success(function(data) {
        // check again in case data was updated before ajax call returned
        if (data.code === 0 && !city) {
          $scope.entries[$scope.currentEntry][shippingOrBilling + 'Address'].city = data.city;
          $scope.entries[$scope.currentEntry][shippingOrBilling + 'Address'].region  = data.regionID;
          $scope.entries[$scope.currentEntry][shippingOrBilling + 'Address'].country = country;
          $scope.entries[$scope.currentEntry][shippingOrBilling + 'Address'].postalCode = postalCode;
          
          $scope['shippingAddress'].city = data.city
          $scope['shippingAddress'].region = data.regionID
          $scope['shippingAddress'].postalCode = postalCode
        }
        // If data.code !== 0 means error, don't change anything.
      });
  };

  $scope.updateShippingAddress = function updateShippingAddress() {
    $scope['shippingAddress'].firstName = $scope.paymentForm.shippingFirstName.$modelValue
    $scope['shippingAddress'].lastName = $scope.paymentForm.shippingLastName.$modelValue
    $scope['shippingAddress'].street = $scope.paymentForm.shippingStreet.$modelValue
    $scope['shippingAddress'].city = $scope.paymentForm.shippingCity.$modelValue
    $scope['shippingAddress'].email = $scope.paymentForm.shippingEmail.$modelValue
  };

  /**
   *
   * @returns {undefined}Update flag that billing data were changed so we can re-generate token
   */
  $scope.billingDataChange = function billingDataChange() {
    $scope.cardData.paymentToken = null;
    $scope.cardData.processorConfig = null;
  };

  /**
   * Applies coupon, both regular and membership.
   *
   * @returns {undefined}
   */
  $scope.applyCoupon = function(required) {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    var couponElement = $scope.regForm.entryForm.couponForm.xnCoupon;
    // $viewValue b/c if we form was previuosly invalid the model value is undefined.
    var couponName = couponElement.$viewValue;

    LoaderService.show();
    CouponService.validate(
      $rootScope.eventInfo.eventID,
      entry.regOptionID,
      $scope.entries,
      entryID,
      couponName,
      false,
      entry.team.prepaidEntriesNum
    )
    .then(function(reason) {
      couponElement.$setValidity('ctRevalidate', reason.valid);
      _.extend(entry.coupon, reason.coupon);
      if (reason.valid) {
        StepService.next("coupon");
      }
      return reason;
    })
    .finally(function() {
      LoaderService.hide();
    });
  };
  
  /**
   * 
   * @param {type} entry
   * @returns {Boolean}Check if user has selected youth option
   */
  $scope.isYouthUsat = function(entry) {
    return entry.usatOption === $rootScope.ANNUAL_YOUTH || entry.usatOption === $rootScope.RENEW_YOUTH;
  };
  
  /**
   * We need to reset revalidation of usat password and username if values have been changed
   */
  $scope.resetRevalidate = function() {
    var usatForm = $scope.regForm.entryForm.regUSATform;
    var usernameElement = usatForm.usatUsername;
    var passwordElement = usatForm.usatPassword;
    
    usernameElement.$setValidity('ctRevalidate', true);
    passwordElement.$setValidity('ctRevalidate', true);
  };
  
  /**
   * 
   * Funciton that will trigger revalidation after certain fields has been changed
   */
  $scope.revalidateMembership = function() {
    if($scope.is_usat_membership) {
      var usatForm = $scope.regForm.entryForm.regUSATform;
      
      var entryID = $scope.currentEntry;
      var entry = $scope.entries[entryID];
      entry.licenceCode = entry.updateCode = entry.usatUsername = '';
      if(entry.usatRaceAge > 17 && $scope.isYouthUsat(entry)) {
        var option = entry.usatOption.split("_YOUTH");
        entry.usatOption = option[0];
        $scope.setUsatPrice();
        $rootScope.$apply();
      }
      var usatOption = entry.usatOption;
      var licenceCodeElement = usatForm.licenceCode;
      var updateCodeElement  = usatForm.updateCode;
      var usernameElement    = usatForm.usatUsername;
    
      var sameCodeElements = [licenceCodeElement, updateCodeElement, usernameElement];
      if($scope.isInfoNeeded(usatOption)) {
        UsatService.setCtRevalidateyFlag(usatOption, sameCodeElements, false);
      }
      $scope.applyUSATmembership();
    }
  };
  
  
  /**
   * 
   * We need to check if there are entries with same codes
   */
  $scope.checkExistingUsatEntries = function(entryForChecking, sameCodeElements) {
    var usatOption = entryForChecking.usatOption;
    var hasSameCode = false;
    if($scope.isInfoNeeded(usatOption)) {
      angular.forEach($scope.entries, function(entry) {
        if(entry._id !== entryForChecking._id) {
           switch(usatOption) {
            case "EXISTING":
                if(entryForChecking.licenceCode == entry.licenceCode && entryForChecking.lastName == entry.lastName) {
                  hasSameCode = true;
                  sameCodeElements[0].$setValidity('alreadyUsed', false);
                }
                break;
            case "UPGRADE":
               if(entryForChecking.updateCode == entry.updateCode && entryForChecking.email == entry.email) { 
                  hasSameCode = true;
                  sameCodeElements[1].$setValidity('alreadyUsed', false);
                }
                break;
            case "RENEW":
                if(entryForChecking.usatUsername == entry.usatUsername) { 
                  hasSameCode = true;
                  sameCodeElements[2].$setValidity('alreadyUsed', false);
                 }
                break;
            }
        }
      });
    }
    return hasSameCode;
  };
  
  $scope.isInfoNeeded = function(usatOption) {
    return usatOption === $rootScope.EXISTING || usatOption === $rootScope.UPGRADE || usatOption === $rootScope.RENEW || usatOption === $rootScope.RENEW_YOUTH;
  };
  
  $scope.checkUsatSignature = function(waiverName, usatSignatureName, guardianUsatSignature) {
    var entryID   = $scope.currentEntry;
    var entry     = $scope.entries[entryID];
    if($scope.is_usat_membership && typeof entry[usatSignatureName] !== 'undefined' && entry[usatSignatureName] !== "") {
      var usatForm  = $scope.regForm.entryForm.regUSATform;
      if(!$scope.compareUsatSignature(entry, usatSignatureName) && (entry[waiverName] || typeof entry[waiverName] === 'undefined')) {
        usatForm[waiverName].$setValidity(usatSignatureName, false);
      } else {
        var sigValid = true;
        if(entry.usatRaceAge < 18) {
          if(!entry[guardianUsatSignature] || entry[guardianUsatSignature].length < 1) {
            sigValid = false;
          }
        }
        usatForm[waiverName].$setValidity(usatSignatureName, sigValid);
      }
      $scope.checkIfAllWaiversAreValid();
    }
  };
  
   $scope.compareUsatSignature = function(entry, usatSignatureName) {
     if(typeof entry[usatSignatureName] === 'undefined') {
        return false;
     } else {
        return entry.firstName.replace(/\s/g, '').toLowerCase() + entry.lastName.replace(/\s/g, '').toLowerCase() === entry[usatSignatureName].replace(/\s/g, '').toLowerCase();
     }
   };
   
  $scope.checkUsatWaiver= function(waiverName, usatSignatureName, waiverVersionID, guardianUsatSignature) {  
    usatForm = $scope.regForm.entryForm.regUSATform;
    entryID  = $scope.currentEntry;
    entry    = $scope.entries[entryID];

    usatForm.$setDirty(true)

    if($scope.compareUsatSignature(entry, usatSignatureName)) {
      var waiverValid = true;
      if(entry.usatRaceAge < 18) {
        if(!entry[guardianUsatSignature] || entry[guardianUsatSignature].length < 1) {
          waiverValid = false;
        }
      }
      usatForm[waiverName].$setValidity(usatSignatureName, waiverValid);
      var versionPropertyName    = waiverName + 'Version';
        entry[versionPropertyName] = waiverVersionID;
      } else {
        usatForm[waiverName].$setValidity(usatSignatureName, false);
      }
      $scope.checkIfAllWaiversAreValid();
   };

  $scope.checkIfAllWaiversAreValid = function() {
    var allValid = true;
    usatForm     = $scope.regForm.entryForm.regUSATform;
    Array.from(document.getElementsByClassName("usat-waiver-checkbox")).forEach(
      function(element, index, array) {
        var waiverName = element.name;
        if (!usatForm[waiverName].$valid) {
          allValid = false;
        }
      }
    );
    
    Array.from(document.getElementsByClassName("guardian-usat-dob")).forEach(
      function(element, index, array) {
        var guardianUsatDobName = element.name;
        if (!usatForm[guardianUsatDobName].$valid || !usatForm[guardianUsatDobName]) {
          allValid = false;
        }
      }
    );
    usatForm.$invalid = !allValid;
  };

  $scope.resetUSATLicenceCodeSubmittedState = function() {
    $scope.isUSATLicenceCodeSubmitted = false;
  };

  $scope.shouldUsatNextButtonBeDisabled = function(regUSATform, entry) {
    if (regUSATform.$invalid) {
      return true
    }

    if (entry.usatOption === 'EXISTING' && !entry.isUsatMembershipCodeValid) {
     return true 
    }

    if (entry.usatOption === 'EXISTING' && entry.isUsatMembershipCodeValid
      && (entry.licenceCode != entry.currentValidUsatMembershipCode)) {
      return true  
    }

    return false
  };

  $scope.applyUSATmembership = function(required) {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    entry.errorFromUsatApi = false;
    $scope.isUSATLicenceCodeSubmitted = true;
    var usatOption = entry.usatOption;

    var usatForm = $scope.regForm.entryForm.regUSATform;
    var licenceCodeElement = usatForm.licenceCode;
    var updateCodeElement  = usatForm.updateCode;
    var usernameElement    = usatForm.usatUsername;
    
    var sameCodeElements = [licenceCodeElement, updateCodeElement, usernameElement];
    var alreadyUsed = $scope.checkExistingUsatEntries(entry, sameCodeElements);
    
    if(alreadyUsed) {
      return true;
    } else {
      angular.forEach(sameCodeElements, function(element) {
          if(element) {
            element.$setValidity('alreadyUsed', true);
          }
      });
    }

    if(!entry.licenceCode) {
      return;
    }
    
    if($scope.isInfoNeeded(usatOption)) {
      LoaderService.show();

      var usatEventID = $rootScope.eventInfo.usatEventID;
      var usatEventStartDate = $rootScope.eventInfo.usatStartDate;
      var dateFormat = eventInfo.dateFormat;

      UsatService.validate(
        entry,
        usatEventStartDate,
        usatEventID,
        dateFormat
      )
      .then(function(response) {
        UsatService.setCtRevalidateyFlag(usatOption, sameCodeElements, response.valid);
        if (response.valid) {
          StepService.next("groupUSAT");
          entry.isUsatMembershipCodeValid = true
          entry.currentValidUsatMembershipCode = entry.licenceCode
        } else {
          entry.errorFromUsatApi = response.errorMsg;
        }
        return response;
      })
      .finally(function() {
        LoaderService.hide();
      });
    } else {
      StepService.next("groupUSAT");
    }
  };

  $scope.secondaryMax = function() {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    var couponElement = $scope.regForm.entryForm.couponSecForm.xnCouponSecMax;
    // $viewValue b/c if we form was previuosly invalid the model value is undefined.
    var couponName = couponElement.$viewValue;
    LoaderService.show();
    CouponService.validate(
      $rootScope.eventInfo.eventID,
      entry.regOptionID,
      $scope.entries,
      entryID,
      couponName,
      true
    )
    .then(function(reason) {
      couponElement.$setValidity('ctRevalidate', reason.valid);
      _.extend(entry.coupon, reason.coupon);
      if (reason.valid) {
        StepService.next("couponSecMax");
      }
      return reason;
    })
    .finally(function() {
      LoaderService.hide();
    });
  };

  /**
   * Check if captain for flat rate payment structure is set
   * @returns bool
   */
  $scope.checkTeamCaptain = function() {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    var captain = true;
    if(entry.team && !entry.team.captain_id && entry.team.paymentType === "FLAT_RATE" && entry.teamRegType === 'JOIN_TEAM' && !entry.team.owner_id){
      captain = false;
    }
    return captain;
  };
  
  /**
   * Check if password is valid for team. 
   * @returns {undefined}
   */
  $scope.checkTeamPassword = function() {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    if(!entry.team.hasPassword || entry.teamRegType !== 'JOIN_TEAM') {
      return StepService.next("groupJoinOrCreateTeam");
    }
    var passwordElement = $scope.regForm.entryForm.teamForm.teamJoin_password;
    // $viewValue b/c if we form was previuosly invalid the model value is undefined.
    var password = passwordElement.$viewValue;

    LoaderService.show();
    TeamService.validatePassword(
      $rootScope.eventInfo.eventID,
      entry.team.id,
      password
    )
    .then(function(reason) {
      passwordElement.$setValidity('ctRevalidate', reason.valid);
      if (reason.valid) {
        StepService.next("groupJoinOrCreateTeam");
      }
      return reason;
    })
    .finally(function() {
      LoaderService.hide();
    });
  };

  /**
   * Method will reset team password fields - so we dont save password if captain
   * decide not to set password for team
   * @returns {undefined}
   */
  $scope.resetTeamPassword = function() {
    var entryID = $scope.currentEntry;
    var entry   = $scope.entries[entryID];
    entry.team.password         = "";
    entry.team.password_confirm = "";
  };

  /**
   * Method will check if team password option is enabled
   * @returns {Boolean}
   */
  $scope.isTeamPasswordEnabled = function() {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    return $scope.regOptions[entry.regOptionID].teamInfo.show_team_password === '1';
  };
  
  /**
   * Method will check if pre pay for team members radio button is checked
   * @returns {Boolean}
   */
  $scope.isPrePay = function() {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    return entry.teamRegType === 'CREATE_TEAM_AND_PREPAY';
  };
  
  /**
   * Method will check whether to show or not max prepaid entries
   * number on team selectio slide
   * @returns {Bollean}
   */
  $scope.showPrepaidMax = function(){
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    if(typeof entry.team !== 'undefined' && entry.team.max_team_members === '99'){
      return false;
    } else{
      return true;
    }
  };
  
  /**
   * 
   * This function will handle master pay flow
   */
  $scope.handleMasterpass = function() {
    var options = { handleMasterpassEvent : true };
    $scope.checkEntryRegData(options);
  };
  
  /**
   * Handle masterpass redirect
   */
  $scope.masterpassCheckout = function(result) {
    var transaction = result.transaction;
    var transID = transaction.trans_id;
    var amount = transaction.local_amount;
    var currency = transaction.local_currency;
    var masterpassData = {
        "checkoutId": regFormApp.resources.paymentProcessor.masterpassKey,         
        "allowedCardTypes": ["master,amex,diners,discover,jcb,maestro,visa"],             
        "amount": amount, 
        "currency": currency,   
        "suppress3Ds": true,         
        "suppressShippingAddress": true, 
        "cartId": transID,
        "callbackUrl": window.location.origin + "/" + regFormApp.resources.paymentProcessor.masterpassCallback               
    };
    try {
      masterpass.checkout(masterpassData);
    }
    catch(error) {
      return false;
    }
  };
  
  
  /**
   * Removes entry from registration process.
   *
   * @param {Number} entryID
   * @param {Bollean} check
   * @returns {undefined}
   */
  $scope.removeEntry = function removeEntry(entryID, check) {
    if(check && !$window.confirm($translate.instant('REMOVE-ENTRY-?'))) {
      return;
    }
    delete $scope.entries[entryID];
    $scope.entryCount = _.size($scope.entries);
    CouponService.deleteUse(entryID);
    StepService.goTo("checkout", false, "registration-review", false);
    // Remove entry after goTo. If we are on that entry's steps and try to delete
    // it we'll get errors when we navigate away.
    StepService.removeEntry(entryID);
    $scope.currentEntry   = $scope.mainEntry;
    $scope.omitTeamReset  = true;
    $scope.paymentErrors  = false;
  };


  $rootScope.isRegistered = false;
  var unsubscribeGigyaLogin = $rootScope.$on('gigya.event.login', function onLogin(event, response) {
    // On successful login sets account data
    response.user.isGuest = $rootScope.account.isGuest;
    $rootScope.account = response.user;
    if (!response.user.isLoggedIn) {
      return;
    }
    $rootScope.$on($scope.eventString, function onRegistered() {
      $rootScope.isRegistered = true;
      unsubscribeGigyaLogin();
    });

    var entry = $scope.entries[$scope.currentEntry];
    var identForm = $scope.entryForm.identForm;
    var locationForm = $scope.entryForm.locationForm;

    // Use the user from our code, "bazusports", not "site" passed from gigya.
    var loginProvider = response.user.loginProvider;

    var provider =
      (loginProvider === "site" || loginProvider === "chronotrack.com") ?
      "bazusports" : response.user.loginProvider;
    var user = response.user.identities[provider];
    
    // Gigya provides different fields than what we need. Pick
    var fieldMap = {
      // from: to
      firstName: {form: 'identForm', field: 'firstName'},
      lastName: {form: 'identForm', field: 'lastName'},
      email: {form: 'identForm', field: 'email'},
      gender: {form: 'identForm', field: 'sex'},
      street: {form: 'identForm', field: 'address'},
      street2: {form: 'identForm', field: 'address2'},
      city: {form: 'identForm', field: 'city'},
      state: {form: 'identForm', field: 'region'},
      zip: {form: 'identForm', field: 'postalCode'}
    };
    // Allow country to be overwritten. Otherwise we can't set a default country
    // in addAnother().
    if (user.country && (entry.country !== user.country)) {
      entry.country = user.country;
      identForm.country.$setDirty();
    }


    _.each(fieldMap, function fieldMap(to, from) {
      // don't overwrite anything already entered by user
      var overwriteRegion = to.field === 'region';
      if (overwriteRegion && user.state && user.state.length !== 5){
        user.state = user.country + '-' + user.state;
        user.region = user.state;
      }
      if (!entry[to.field] && user[from] || overwriteRegion) {
        entry[to.field] = user[from];
        $scope.entryForm[to.form][to.field].$setDirty();
      }
    });
    if (angular.isString(entry.sex)) {
      entry.sex = entry.sex.toUpperCase();
      //can return U (undefined) from admin, and it is not supported.
      if (entry.sex !== "M" && entry.sex !== "F") {
        entry.sex = null;
      }
    }
    if (entry.emailConfirm !== entry.email) {
      entry.emailConfirm = entry.email;
      identForm.emailConfirm.$setDirty();
    }

    if(typeof identForm.$error !== "undefined" && !_.isEmpty(identForm.$error) && identForm.$error.postalCode && identForm.$error.postalCode[0].$invalid) {
      identForm.postalCode.$setTouched();  
    }
    if (user.birthMonth && user.birthDay && user.birthYear) {

      var newDob = DateService.returnDateStringInFormat(
        user.birthMonth, user.birthDay, user.birthYear, eventInfo.dateFormat
      );
      if (entry.dob !== newDob) {
        entry.dob = newDob;
        identForm.dob.$setDirty();
        identForm.dob.$setTouched();
      }
    }
    
    if (!entry.phone.number && entry.phone.number !== user.phone) {
      entry.phone.number = user.phone;
      if(entry.country) {
        entry.phone.countryID = entry.country;
        RegionService.countries.then(function(countries) {
          $scope.countries = countries;
          entry.phone.code = $scope.countries[entry.phone.countryID].itu_code;
          if(entry.phone && entry.phone.countryID) {
           removeCountryCode(entry);
          }
          identForm.primaryPhone.$setDirty();
        });
      }
    }
    

    // Strip leading 1 from US phone numbers.
    // @someday should we be striping the itu code from all phone numbers?
    if (
      entry.phone.number &&
      entry.phone.number.substring(0,1) === "1" &&
      entry.country === "US"
    ) {
      entry.phone.countryID = "US";
      entry.phone.code = "1";
      entry.phone.number = entry.phone.number.substring(1);
      identForm.primaryPhone.$setDirty();
    }
    
    StepService.goToFirst($scope.currentEntry);
    if(entry.regOptionID) {
      $scope.regOptionClick($scope.regOptions[entry.regOptionID]);
    }
  });




  $scope.$watch("entries[currentEntry].dob",
    /**
     * Computes age for an entry and save data to entry.age
     *
     * @param {Object|String} newDob
     * @returns {undefined}
     */
    function(newDob) {
      if (typeof newDob === "undefined") {
        return;
      }
      var entry = $scope.entries[$scope.currentEntry];
      if (!DateService.validate(newDob, $scope.dateFormat)) {
        return;
      }
      var dob = DateService.returnDateInFormat(newDob, $scope.dateFormat);
      // only calculate age if we have a valid birthdate
      var eventTime  = new Date($rootScope.eventInfo.universalDate);
      entry.age = DateService.age(dob, eventTime);
    }
  );


  /**
   * After countries are loaded make sure the country viewValue is updated
   */
  RegionService.countries.then(function countriesLoaded(countries) {
    $scope.countries = countries;
  });

  /**
   * After regions are loaded make sure the region viewValue is updated
   */
  RegionService.regions.then(function regionsLoaded(regions) {
    $scope.regions = regions;
    var entry = $scope.entries[$scope.currentEntry];
    
    //Setting default region
    if(entry && entry.region === null && $scope.regions[entry.country]) {
        entry.region = Object.keys($scope.regions[entry.country])[0];
    }
  });

  $scope.$watch("entries[currentEntry].country",
    /**
     * Update itu_code for phone number on country change.
     * if no phone number or code has been set yet.
     *
     * @param {String} newCountry
     * @returns {undefined}
     */
    function countryChange(newCountry) {
      if (typeof newCountry === "undefined" || !newCountry) {
        return;
      }
      RegionService.countries.then(function countriesLoaded(countries) {
        var entry = $scope.entries[$scope.currentEntry];
        if (!countries[newCountry]) {
          return;
        }
        angular.forEach(['phone', 'emergPhone'], function(key) {
          if (entry[key] && (!entry[key].code || !entry[key].number)) {
            entry[key].countryID = newCountry;
            entry[key].code = countries[newCountry].itu_code;
          }
        });
        if($scope.regions && $scope.regions[newCountry] ) {
          if(!$scope.regions[newCountry][entry.region]) {
             entry.region = Object.keys($scope.regions[entry.country])[0];
          }
        }

        $rootScope.refreshPaymentMethods = true;
      });
    }
  );

  $scope.$watch("entries[currentEntry].region",

    function stateChange(newState) {
      firstKey = Object.keys($scope.entries)[0];
      if (firstKey == $scope.currentEntry) {
        postalCode = $scope.entries[$scope.currentEntry].postalCode;
        if (newState && postalCode) {
          CartService.getStateTaxRate(newState, postalCode)
              .then(function (response) {
                $rootScope.stateTaxRate = response.taxRate;
                $rootScope.$broadcast('ctlive.reg.state-change');
              });
        }
      }
    }
  );

  $scope.$watch("entries[currentEntry].postalCode",

    function postalChange(postalCode) {
      firstKey = Object.keys($scope.entries)[0];
      if (firstKey == $scope.currentEntry) {
        region = $scope.entries[$scope.currentEntry].region;
        if (region && postalCode) {
          CartService.getStateTaxRate(region, postalCode)
          .then(function(response) {
            $rootScope.stateTaxRate = response.taxRate;
            $rootScope.$broadcast('ctlive.reg.state-change');
          });
        }
      }
    }
  );

  $scope.initChecklist = function(entry, key) {
    if(typeof entry[key] === 'undefined') {
      entry[key] = [];
    }
  };
  /**
   * Check if one of the selected regoptions is in array of reg options for specific slide.
   * @param {type} regOptions
   * @param {type} selectedRegOption
   * @returns {Boolean}
   */
  $scope.hasSelectedRegOptions = function(regOptions, selectedRegOption) {
    return !_.isEmpty(_.intersection(_.values(selectedRegOption),regOptions));
  };

  /**
   * When we change team reg type we want to clear out any existing team info.
   */
  $scope.teamRegTypeChange = function teamRegTypeChange() {
    $scope.entries[$scope.currentEntry].team = TeamService.defaultTeam();
  };

  /**
   * Adding require to Emergency slide for input phone
   */
  $scope.emergencyPhoneRequired = function emergencyPhoneRequired(requiredRegOptions) {
    var entry = $scope.entries[$scope.currentEntry];
    if (typeof $scope.regOptions[entry.regOptionID] !== 'undefined') {
      return requiredRegOptions.indexOf($scope.regOptions[entry.regOptionID].id) !== -1 ? 1 : 0;
    }
    else {
      return 0;
    }
  };

  // Signal that we have finished initialization. We hvaen't really, but with
  // angular there is no point where we know for sure.
  $(window).on('load',function(){
    $rootScope.$broadcast('ctlive.reg.init',{"step":"Initialization"});
  });

  /**
   * Check if password is valid for group.
   * @returns {undefined}
   */
  $scope.checkGroupPassword = function() {
    var entryID = $scope.currentEntry;
    var entry = $scope.entries[entryID];
    if(!entry.group.hasPassword || entry.groupRegType !== 'JOIN_GROUP') {
      return StepService.next("groupGroupRegistration");
    }
    var passwordElement = $scope.regForm.entryForm.groupForm.groupJoin_password;
    var password = passwordElement.$viewValue;

    LoaderService.show();
    GroupService.validatePassword(
      $rootScope.eventInfo.eventID,
      entry.group.id,
      password
    )
    .then(function(reason) {
      passwordElement.$setValidity('ctRevalidate', reason.valid);
      if (reason.valid) {
        StepService.next("groupGroupRegistration");
      }
      return reason;
    })
    .finally(function() {
      LoaderService.hide();
    });
  };

  /**
   * Method will reset group password fields - so we dont save password if captain
   * decide not to set password for group
   * @returns {undefined}
   */
  $scope.resetGroupPassword = function() {
    var entryID = $scope.currentEntry;
    var entry   = $scope.entries[entryID];
    entry.group.password         = "";
    entry.group.password_confirm = "";
  };

  /**
   * Method will check if group password option is enabled
   * @returns {Boolean}
   */
  $scope.isGroupPasswordEnabled = function() {
    var entryID = $scope.currentEntry;
    var entry   = $scope.entries[entryID];
    return $scope.regOptions[entry.regOptionID].groupInfo.show_group_password === '1';
  };
  
  window.recaptchaResponse = function(key) {
    $rootScope.wrongCaptchaResponse = false;
    $rootScope.$apply();
  };
 
}]);