angular.module('regFormApp').factory('LoaderService', function() {
  return {
    // This is the delay. Anything !== false will start loader.
    loading: 0,
    show: function(delay) {
      if (typeof delay === "undefined") {
        // default delay
        delay = 350;
      }
      this.loading = delay;
    },
    hide: function() { this.loading = false; }
  };
})
.directive('ctLoader', ['LoaderService', '$timeout', function loaderDirective(LoaderService, $timeout) {
  return {
    // we want this to run late after everything else is finished loading
    priority: 10000,
    restrict: 'C',
    link: function($scope, element) {
      $scope.LoaderService = LoaderService;
      var delayPromise = null;
      var duration = "slow";
      $scope.$watch('LoaderService.loading', function(loading) {
        if (loading !== false) {
          // Delay the loading. This is so if show/hide is called in quick succession 
          // we don't show it if not necessary.
          delayPromise = $timeout(function() { element.fadeIn(duration); }, loading);
        }
        else {
          if (delayPromise) {
            $timeout.cancel(delayPromise);
          }
          delayPromise = null;
          element.fadeOut(duration);
        }
      });
      // Presumably page is finished loading so hide the loader.
      LoaderService.hide();
    }
  };
}]);


