angular.module('regFormApp').factory('StorefrontService', ['StepService', '$rootScope', function(StepService, $rootScope) {

  var shippingCountry = 'US'
  var remainingQty = 1000
  /**
   * Storefront defaults for reg options. Fills in missing data.
   * @return {Object}
   */
   function regOptionDefaults() {
    return {
      products: [],
    };
  }

  /**
   * Return reg option. If no reg option is available, return defaults.
   * @param {string} regOptionID
   * @return {Object} 
   */
   function _getRegOption(regOptionID) {
    var ro;
    if (regOptionID) {
      ro = regFormApp.resources.regOptions[regOptionID];
    }
    return ro || regOptionDefaults();
  }

  /**
   * Decide if storefront slide should be shown
   * 
   * @param {Object} entry
   * @returns {Boolean}
   */
   function showStorefrontSlide(entry) {
    return !!_getRegOption(entry.regOptionID).products.filter((product) => product.pricingType === 'SELL').length;
  }


  /**
   * Decide if product should be shown on slide depending on selected reg option
   * 
   * @param int productID
   * @param {Object} entry
   * @returns {Boolean}
   */
  function shouldShowProduct(productID, entry) {
    return !!getProduct(productID, entry.regOptionID)
  }

  function getProduct(productID, regOptionID) {
    var ro = _getRegOption(regOptionID)
    if (typeof ro.products !== 'undefined' && ro.products.length) {
      var filtered = ro.products.find(product => product.id === productID)
      if (typeof filtered !== 'undefined') {
        return filtered
      }
    }
    return false
  }

  function getShippingFee(country) {
    return regFormApp.resources.eventInfo.storeSettings
      ? country === regFormApp.resources.eventInfo.locationCountry
        ? parseFloat(regFormApp.resources.eventInfo.storeSettings.shipping_amount)
        : parseFloat(regFormApp.resources.eventInfo. storeSettings.international_shipping_amount)
      : 0
  }

  function getProductOptionRemainingQty(option, productId, entries, wantsUnsigned = false) {
    var entriesProductOptionQuantity = 0;
    angular.forEach(entries, function(entry) {
      var entryProduct = entry.storefront.products[productId];
      if (entryProduct && entryProduct.option) {
        if (Number(entryProduct.option.id) === Number(option.id)) {
          if (entryProduct.option.selectedQuantity) {
            entriesProductOptionQuantity += entryProduct.option.selectedQuantity;
          }  
        }
      }
    });
    remainingQty = Number(option.remaining) - Number(entriesProductOptionQuantity)
    return wantsUnsigned ? remainingQty < 0 ? 0 : remainingQty : remainingQty
  }


  function setEntryProductOptionQuantity(entry,  productId, optionId, resetOptionId = false) {
    if (resetOptionId) {
      if (StepService.getCurrent().step === 'payment') {
        console.log('remove-store-product-payment')
        $rootScope.$broadcast("ctlive.reg.remove-store-product", {
          optionId,
          productId,
          entry
        })
      } else {
        console.log('remove-store-product')
        entry.storefront.products[productId].optionsInCart = entry.storefront.products[productId].optionsInCart.filter(o => o.id != optionId);
      }
    }
  }

  function addStoreProductToCart(newEntry, entry, entries, product, newCart) {
    var selectedProduct = entry.storefront.products[product.id]
    if (!selectedProduct || !selectedProduct.optionsInCart) return;

    selectedProduct.price = 0;
    var option = product.options.find(x => Number(x.id) === Number(selectedProduct.option.id));
    selectedProduct.price = product.pricingType === 'SELL' ? parseFloat(option.price) : 0

    angular.forEach(selectedProduct.optionsInCart, function(selectedOption) {
      if (product.deliveryType === 'USER_DECIDES' && !selectedProduct.deliveryType) return
      var optionForCart = product.options.find(x => Number(x.id) === Number(selectedOption.id));
      if (!selectedOption.quantity) return
      if (getProductOptionRemainingQty(optionForCart, product.id, entries) < 0 && StepService.getCurrent().entryID === entry._id) return
     // selectedOption.price = selectedProduct.price;
      var itemPrice= bcround(selectedOption.price * selectedOption.quantity, 2)
      var numerifyOptionId = Number(selectedOption.id)

      newEntry.items.push({
        name: product.name + ` ${optionForCart.name} (${selectedOption.quantity})`,
        translate: true,
        price: itemPrice,
        taxed: bcround(0, 2),
        track: product.sku + '_' + entry._id + '_' + selectedOption.id,
        storeFrontProductID: product.id,
        storeFrontOptionID: numerifyOptionId,
        productDeliveryType: selectedProduct.deliveryType ? selectedProduct.deliveryType : product.deliveryType,
        totalAfterTax: itemPrice,
        hideVatData: true
      }); 
  
      var wantsShipping = product.deliveryType === 'SHIP_ITEM' || selectedProduct.deliveryType === 'SHIP_ITEM';
      if (!newEntry.shippingFee) {
        newEntry.shippingFee = wantsShipping ? getShippingFee(shippingCountry) : 0
      }
      if (!newEntry.items.filter(item => item.productDeliveryType === 'SHIP_ITEM').length) {
        newEntry.shippingFee = 0;
      }
      if (wantsShipping) {
        newCart.wantsShippingAddress = wantsShipping
      }
    });
  }

  function setShippingCountry(country) {
    shippingCountry = country
  }

  function filterProductsByPricingType(products, pricingType) {
    return  products ? products.filter((product) => product.pricingType === pricingType) : []
  }
  
  function getFeaturedImage(product) {
    if (!product || !product.images.length) {
      return ""
    }
    // noinspection JSUnresolvedVariable
    if (product.featuredImageId) {
      const featuredImage = product.images.find(toCheck => toCheck.id === product.featuredImageId)
      return !featuredImage ? "" : featuredImage.url
    }
    return product.images[0].url
  }

  function checkIfMaxItemsPerPersonIsExceeded(productID, regOptionID, quantityToCheck) {
    return quantityToCheck > getMaxItemsPerPersonForProduct(productID, regOptionID)
  }

  function getMaxItemsPerPersonForProduct(productID, regOptionID) {
    const maxItems = getProduct(productID, regOptionID).maxItemsPerPerson
    return maxItems > 0 ? maxItems : remainingQty
  }

  return {
    showStorefrontSlide,
    shouldShowProduct,
    getShippingFee,
    getProductOptionRemainingQty,
    setEntryProductOptionQuantity,
    addStoreProductToCart,
    setShippingCountry,
    filterProductsByPricingType,
    getFeaturedImage,
    checkIfMaxItemsPerPersonIsExceeded,
    getMaxItemsPerPersonForProduct
  };
}]);