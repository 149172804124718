angular.module('regFormApp').directive('ssoLink', ['$window', '$location', function($window, $location) {
  return {
    link: function(scope, element, attrs) {
      var returnUrl = encodeURIComponent($location.absUrl());
      var newValue = attrs.ssoLink === 'logout' ? ('/sso/logout?redirectUri=' + returnUrl) : ('/sso/reg-form-login?returnUrl=' + returnUrl);
      element.attr('href', newValue);
      element.click(function() { $window.onbeforeunload = null; });
    }
  };
}]);
