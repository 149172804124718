angular.module('regFormApp').factory('DateService', [function() {

  var AMERICAN_DATE = /^(0?[1-9]|1[012]{0,1})\/(0?[1-9]|[12][0-9]{0,1}|3[01]{0,1})\/([0-9]{2}|[0-9]{4})$/;
  var EU_DATE = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012]{0,1})\/([0-9]{2}|[0-9]{4})$/;
  var Y_DATE = /^([0-9]{2}|[0-9]{4})\/(0?[1-9]|1[012]{0,1})\/(0?[1-9]|[12][0-9]{0,1}|3[01]{0,1})$/;
  var euDateFormat = "dd/mm/yyyy";
  var usDateFormat = "mm/dd/yyyy";
  /**
   *
   * @param {String} date
   * @returns {String}
   */
  function dateformat(date, format) {
    if (!date) {
      return null;
    }
    if(!format) {
      format = 'm/d/Y';
    }
    var dateobj   = new Date();
    var fullYear  = dateobj.getFullYear().toString();
    var yearBreak = fullYear.substr(2,3);
    var dateParams = date.split("/"); 
    var length = dateParams.length;
    if (length < 3) {
      return date;
    }
    var monthIndex = 0, dayIndex = 1;
    if(format === 'd/m/Y' || format === 'dd/mm/yyyy') {
      monthIndex = 1;dayIndex = 0;
    }
    if(format === 'Y/m/d' || format === 'yyyy/mm/dd') {
      monthIndex = 1;dayIndex = 2;
    }
    return _.map(date.split('/'), function(part, index) {
      if ([dayIndex,monthIndex].indexOf(index) !== -1 ) {
        part = parseInt(part, 10);
        return part < 10 ? "0" + part : part;
      }
      part = part.length === 4 && parseInt(part) < 1000 ? -2000 : part;
      if (part.length === 2) {
        if (part <= yearBreak || part === 0) {
          part = "20" + part;
        }
        else {
          part = "19" + part;
        }
      }
      return part;
    }).join('/');
  }

  /**
   * Converts date string to given format.
   *
   * @param {String} date
   * @param {String} dateFormat Available formats are 'dd/mm/yyyy' and 'mm/dd/yyyy'
   * @returns {Date}
   */
  function returnDateInFormat(date, dateFormat) {
    if (!date || !dateFormat) {
      return null;
    }
    var dateParams = date.split("/");
    if(dateFormat === 'm/d/Y' || dateFormat === 'mm/dd/yyyy' || dateParams.length !== 3) {
      return new Date(dateformat(date));
    }
    if(dateFormat === 'd/m/Y' || dateFormat === 'dd/mm/yyyy') {
      return new Date(dateParams[2], dateParams[1] -1, dateParams[0]);
    }
    if(dateFormat === 'Y/m/d' || dateFormat === 'yyyy/mm/dd') {
      return new Date(dateParams[0], dateParams[1] -1, dateParams[2]);
    }
  }

  /**
   * Validate that a date matches the format
   *
   * @param {string} date
   * @param {String} dateFormat Available formats are 'dd/mm/yyyy', 'mm/dd/yyyy' and 'yyyy/dd/mm'
   * @return {Boolean}
   */
  function validate(date, dateFormat) {
    if(dateFormat === 'd/m/Y' || dateFormat === 'dd/mm/yyyy') {
      return EU_DATE.test(date);
    }
    else if(dateFormat === 'Y/m/d' || dateFormat === 'yyyy/mm/dd') {
      return Y_DATE.test(date);
    }
    else {
      return AMERICAN_DATE.test(date);
    }
  }

  /**
   * Compute age
   *
   * @param {Date} dob Date of Birth
   * @param {Date} relativeTo Relative date to compute age.
   * @return {Int}
   */
  function age(dob, relativeTo) {
    if (!dob) {
      return null;
    }
    var birthDate = new Date(dob);
    var ageForReturn = relativeTo.getFullYear() - birthDate.getFullYear();
    var m = relativeTo.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && relativeTo.getDate() < birthDate.getDate())) {
        ageForReturn--;
    }
    return ageForReturn;
  }

  function returnDateStringInFormat(month, day, year, format) {
    switch(format) {
      default:
      case 'm/d/Y':
      case 'mm/dd/yyyy':
        return month + '/' + day + '/' + year;
      case 'd/m/Y':
      case 'dd/mm/yyyy':
        return day + '/' + month + '/' + year;
      case 'Y/m/d':
      case 'yyyy/mm/dd':
        return year + '/' + month + '/' + day;
    }
  }

  return {
    dateformat: dateformat,
    returnDateInFormat: returnDateInFormat,
    validate: validate,
    age: age,
    defaultDateFormat: usDateFormat,
    returnDateStringInFormat: returnDateStringInFormat
  };
}]);

angular.module('regFormApp').directive('ctPicker', ['$rootScope', 'DateService', function ($rootScope, DateService) {
  var defaultMinDate = '01/01/1910';
  return {
    require: 'ngModel',
    scope: {
      seriesEvents: '=',
      isSeries: '=',
    },
    link: function(scope, elem, attr, ngModel) {
      var dateFormat, minDate, maxDate, minAge, maxAge;
      // set defaults
      watchDateFormat();
      watchMinDate();
      watchMaxDate();
      watchMinAge();
      watchMaxAge();
      function watchDateFormat(newDateFormat) {
        if (!newDateFormat) {
          dateFormat = DateService.defaultDateFormat;
        }
        else {
          dateFormat = newDateFormat;
        }
        ngModel.$validate();
      }
      function processAgeRef(ageRefTimes, value, minAge, maxAge) {
        var date     = DateService.returnDateInFormat(value, dateFormat);
        var validAge = true;
        var age      = null;
        if(ageRefTimes === null) {
          age = DateService.age(date, new Date($rootScope.eventInfo.universalDate));
          return age >= minAge && age <= maxAge;
        }
        _.each(ageRefTimes, function(ageRefTime, index) {
          age = DateService.age(date, new Date(ageRefTime));
          if(age < minAge || age > maxAge) {
            validAge = false;
          }
        });
        return validAge;
      } 
      function watchMinDate(newMinDate) {
        if (!attr.minDate || attr.minDate === 'today') {
          minDate = new Date(defaultMinDate);
        }
        else {
          minDate = DateService.returnDateInFormat(newMinDate, dateFormat);
        }
        ngModel.$validate();
      }
      function watchMaxDate(newMaxDate) {
        if (!newMaxDate || newMaxDate === 'today') {
          maxDate = new Date();
        }
        else {
          maxDate = DateService.returnDateInFormat(newMaxDate, dateFormat);
        }
        ngModel.$validate();
      }
      function watchMinAge(newMinAge) {
        if (!newMinAge) {
          minAge = 0;
        }
        else {
          minAge = parseInt(newMinAge);
        }
        ngModel.$validate();
      }
      function watchMaxAge(newMaxAge) {
        if (!newMaxAge) {
          maxAge = 9999;
        }
        else {
          maxAge = parseInt(newMaxAge);
        }
        ngModel.$validate();
      }
      function validateDob() {
        ngModel.$validate();
      } 
      attr.$observe('dateFormat', watchDateFormat);
      attr.$observe('minDate', watchMinDate);
      attr.$observe('maxDate', watchMaxDate);
      attr.$observe('minAge', watchMinAge);
      attr.$observe('maxAge', watchMaxAge);
      scope.$on('validateAge', validateDob);
      ngModel.$validators.picker = function validateDateFormat(modelValue, viewValue) {
        var value = modelValue || viewValue;
        return !value || DateService.validate(value, dateFormat);
      };
        /**
         * Validates if date is between/or equal to min and max  date
         * 
         * @param {String} modelValue
         * @param {String} viewValue
         * @returns {Boolean}
         */
      ngModel.$validators.dateMinMax = function validateDateMinMax(modelValue, viewValue) {
        var value = modelValue || viewValue;
        if(!value) {
          return true;
        }
        var date = DateService.returnDateInFormat(value, dateFormat);
        return date >= minDate && date <= maxDate;
      };
      ngModel.$validators.ageMinMax = function validateAgeMinMax(modelValue, viewValue) {
        var validAge = true;
        var value = modelValue || viewValue;
        if (scope.isSeries) {
          if (!Object.keys(scope.seriesEvents).length) {
            return true;
          }
          angular.forEach(scope.seriesEvents, function(regOptionId, eventID) {
            if (validAge) {
              var ageRefTimes = typeof regFormApp.resources.series_events[eventID].reg_options[regOptionId] === "undefined" ||
                                typeof regFormApp.resources.series_events[eventID].reg_options[regOptionId].ageRefTimes === "undefined" ?
                                null : regFormApp.resources.series_events[eventID].reg_options[regOptionId].ageRefTimes;
              var minAgeTemp = regFormApp.resources.series_events[eventID].reg_options[regOptionId].minAge;
              var maxAgeTemp = regFormApp.resources.series_events[eventID].reg_options[regOptionId].maxAge;
              validAge = processAgeRef(ageRefTimes, value, minAgeTemp, maxAgeTemp);
            }
          });
        } else {
          if(!value || (!attr.minAge && !attr.maxAge)) {
            return true;
          }
          var regOptionId = attr.regOptionId;
          var ageRefTimes = typeof regFormApp.resources.regOptions[regOptionId] === "undefined" ||
                            typeof regFormApp.resources.regOptions[regOptionId].ageRefTimes === "undefined" ?
                            null : regFormApp.resources.regOptions[regOptionId].ageRefTimes;

          validAge = processAgeRef(ageRefTimes, value, minAge, maxAge);
        }
        return validAge;
      }; 
      ngModel.$parsers.unshift(function (value) {
        return DateService.dateformat(value, dateFormat);
      }); 
    }
  };
}]);
