var module = angular.module('regFormApp');

/** 
 * Filter to  order an object of objects by a key
 */
module.filter('orderObjectBy', function() {
  return function(items, field, reverse) {
    var filtered = [];
    angular.forEach(items, function(item) {
      filtered.push(item);
    });
    filtered.sort(function (a, b) {
      return (a[field] > b[field]) ? 1 : ((a[field] < b[field]) ? -1 : 0);
    });
    if (reverse) {
      filtered.reverse();
    }
    return filtered;
  };
});

/** 
 * Filter to  order events in event series by start time
 */
module.filter('orderEventsByStartTime', function() {
  return function(items) {
    var filtered = [];
    angular.forEach(items, function(item) {
      filtered.push(item);
    });
    filtered.sort(function(e1, e2) {
      if(e1.event.order === e2.event.order) return 0;
      return e1.event.order - e2.event.order;
    });
    return filtered;
  };
});
 
/** 
 * Filter to make a string suitable for a css class
 */
module.filter('cssclass', function() {
  return function(input) {
    return input.toUpperCase().replace(/[^A-Z0-9]/g, '');
  };
});

/**
 * Convert an object to an array
 */
module.filter("toArray", function(){
  return function(obj) {
    var result = [];
    angular.forEach(obj, function(val, key) {
      result.push(val);
    });
    return result;
  };
});


/**
 * Conditionally translate a string.
 *
 * @example
 *    $scope.do_translation = true;
 *    
 *    {{ TRANSLATION_ID | translate:do_translation }}
 */
module.filter('translateIf', ['$filter', function($filter) {
  var filter = function (translationId, cond, interpolateParams, interpolation) {
    if (cond) {
      return $filter('translate')(translationId, interpolateParams, interpolation);
    }
    else {
      return translationId;
    }
  };
  // translate filter is stateful, so we must be too.
  filter.$stateful = true;
  return filter;
}]);

/**
 * Conditionally set currency.
 * @example 
 * 
 *    {{ price | currencyIf:"USD": apply_currency_filter }}
 */
module.filter('currencyIf', ['$filter', function ($filter) {
  return function (amount, currencySymbol, noCurrency, decimal) {
    return (noCurrency) ? amount : $filter("currency")(amount, currencySymbol, decimal);
  };
}]);


module.filter('localeLongDate', [function() {
  return function (date, locale, format, timeZone, wantsTimeZone) {
    var formatedDate;
    var rowDate;
    
    rowDate = new Date(date * 1000).toLocaleDateString();
    
    if(date) {
            rowDate = new Date(date * 1000).toLocaleDateString();
      try {
        if (typeof moment !== "undefined") {
          moment.locale(locale);
          if(timeZone) {
            if(wantsTimeZone) {
              formatedDate  = moment.unix(date).tz(timeZone).format(format + ' z');
            } else {
                formatedDate = moment.unix(date).tz(timeZone).format(format);
            }         
          } else {
              formatedDate = moment.unix(date).format(format);
          }  
        } else {
            formatedDate = rowDate; 
        }
        return formatedDate;
      }
      catch (e) { }
    } else {
        return '';
    }
    return rowDate;
  };
}]);

