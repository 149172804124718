angular.module('regFormApp').factory('GroupService', ['$q', '$http', '$rootScope', 
  function($q, $http, $rootScope) {

    /**
    * @type {Object}
    */
    var _defaultGroup = {
      id: null,
      name: '',
      owner_id: null
    };

    function defaultGroup() {
      return angular.copy(_defaultGroup);
    }

    /**
    * Quote regular expresions
    * @param {string} str
    * @return string
    *
    * @see http://stackoverflow.com/questions/2593637/how-to-escape-regular-expression-in-javascript
    */
    function regExpQuote(str) {
      return (str+'').replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
    }

    /**
    * Search for groups. Search is done in database and in angular data scope.
    * 
    * @param {String} query Query to search.
    * @param {Object} entry The entry who is searching for the group. Needed so we can filter out
    * @param {Array} entries All the entries, so we find groups already added/joined.
    * @returns {Promise}
    */
    function searchGroups(query, currentEntry, entries) {

      var uniqueSearch = (currentEntry.groupRegType === 'CREATE_GROUP' ? 1 : 0);
      query            = query.trim();
      var deferred     = $q.defer();
      if (query.length === 0) {
        deferred.resolve([]);
        return deferred.promise;
      }

      var pattern        = new RegExp(regExpQuote(query),"i");
      var groupsToReturn = _.filter(entries, function(e) {
        // Don't match the current entry.
        return e._id !== currentEntry._id &&
          //check only entries with group
          e.group &&
          // We only care about created groups, these won't show up in search because
          // they haven't hit the server.
          e.groupRegType === 'CREATE_GROUP' &&
          // And finally, the group name matches our search query.
          pattern.test(e.group.name);
      });
      groupsToReturn = _.pluck(groupsToReturn, 'group');
      if (uniqueSearch && groupsToReturn.length > 0) {
        // If all we want is to find if this group is unique, and we already know it
        // isn't, let's not bother with the ajax request.
        deferred.resolve(groupsToReturn);
        return deferred.promise;
      }

      // query doesn't need escaped.
      var opts = {
        method: 'POST',
        url: '/reg/group-complete/',
        data: $.param({eventID: $rootScope.eventInfo.eventID,
              q: query, limit: 9, exactMatch: uniqueSearch
        })
      };
      $http(opts).then(function(response) {
        angular.forEach(response.data, function(group, key){
          // entries count comes in as string.
          group.entries = parseInt(group.entries, 10);
          groupsToReturn.push(group);
        });
         deferred.resolve(groupsToReturn);
      }, function(error){
        deferred.resolve([]);
      });
      return deferred.promise;
    }

    function resetGroupData(entry) {
      entry.group = defaultGroup();
      entry.groupRegType = "REGISTER_AS_INDIVIDUAL";
    }
    
    /**
   * Verify group password
   *
   * @param {Number} eventID
   * @param {Number} groupID
   * @param {String} password for group
   * @return {Object} Promise which resolves to {groupValid: ...}
   */
  function validatePassword(eventID, groupID, password) {
    var deferred = $q.defer();
    if (!password) {
       return $q.when({valid: false});
    }    
    var opts = {
      method: 'POST',
      url: '/reg/validate-group-password/',
      data: $.param({password: password, groupID: groupID, eventID: eventID})
    };
    http = $http(opts)
      .then(function(response) {
        var valid = response.data.groupValid;
        if (!valid) {
          return $q.reject();
        }
        deferred.resolve({valid: true});
      })
      .catch(function(reason) {
        deferred.resolve({valid: false});
      });
    return deferred.promise;
  }

    return {
      defaultGroup: defaultGroup,
      searchGroups: searchGroups,
      resetGroupData: resetGroupData,
      validatePassword: validatePassword
    };
  }
]);

/**
 * Directive that converts group name to object with name attribute equals to 
 * group name
 */
angular.module('regFormApp').directive('ctCreateGroup', ['$q', 'GroupService', function($q, GroupService) {
  return {
    restrict: 'A',
    require: 'ngModel',
    controller: function() {
      this.searchName = '';
    },
    controllerAs: 'groupCreate',
    templateUrl: 'ctGroupCreate.html',
    priority: 200,
    link: function (scope, element, attrs, ngModel) {
      ngModel.$isEmpty = function(value) {
        return !angular.isObject(value) || !value.name;
      };

      scope.groupCreate.searchChange = function() {
        var group  = GroupService.defaultGroup();
        group.name = scope.groupCreate.searchName.trim();
        group.owner_id = scope.entry._id;
        ngModel.$setViewValue(group);
      };

      ngModel.$validators.required = function createGroupNameRequired(modelValue, viewValue) {
        var group = modelValue || viewValue;
        return angular.isObject(group) && angular.isString(group.name) && group.name.trim().length > 0;
      };

      ngModel.$asyncValidators.unique = function(group) {
        var deferred = $q.defer();
        GroupService.searchGroups(
          scope.groupCreate.searchName,
          scope.entry,
          scope.entries
        ).then(function(groups) {
          if (groups.length === 0) {
            deferred.resolve(true);
          }
          else {
            deferred.reject('not unique');
          }
        })
        .catch(function(e) {
          deferred.reject('unknown error');
        });
        deferred.promise.then(
          function() { scope.groupCreateForm.$setValidity('unique', true); },
          function(reason) {
            var uniqueError = reason === 'not unique';
            scope.groupCreateForm.$setValidity('unique', !uniqueError);
            scope.groupCreateForm.$setValidity('unknown', uniqueError);
          }
        );
        return deferred.promise;
      };
    }
  };
}]);

angular.module('regFormApp').directive('ctGroupJoin', ['GroupService', function(GroupService) {

  /**
   * Sets the defaults for our controller variables.
   * 
   * @param {Object} obj
   */
  function resetControllerVars(obj) {
    obj.searchName     = '';
    obj.matchingGroups = [];
    obj.pending        = false;
    obj.noMatches      = false;
  }

  return {
    require: ['ngModel', '^form'],
    controller: function() {
      resetControllerVars(this);
    },
    controllerAs: 'groupJoin',
    templateUrl: 'ctGroupJoin.html',
    link: function (scope, elem, attrs, controllers) {
      var ngModel = controllers[0];

      scope.$watch('entry.regOptionID', function regOptionIdChange() {
        resetControllerVars(scope.groupJoin);
        ngModel.$setPristine();
        ngModel.$setUntouched();
      });

      ngModel.$validators.required = function joinGroupNameRequired(modelValue, viewValue) {
        var group = modelValue || viewValue;
        return angular.isObject(group) && angular.isString(group.name) && group.name.trim().length > 0;
      };

      scope.isGroupSelected = function( group ) {
        if ( ! scope.entry.group ) {
          return false;
        }
        if ( group.owner_id ) {
          return group.name === scope.entry.group.name;
        }
        
        return scope.entry.group.id === group.id;
      };

      scope.groupJoin.searchChange = function searchChange() {
        if (scope.groupJoin.searchName.trim().length >= 3) {
          scope.groupJoin.search();
        }
      };

      ngModel.$isEmpty = function(value) {
        return !angular.isObject(value) || !value.id || !value.name;
      };

      scope.groupJoin.search = function search() {
        // @todo we should delay setting pending for a few ms, 
        // if the search returns fast don't show a spinner.
        scope.groupJoin.pending = true;
        GroupService.searchGroups(
          scope.groupJoin.searchName,
          scope.entry,
          scope.entries
        ).then(function(groups) {
          scope.groupJoin.noMatches = (groups.length === 0);
          if (scope.groupJoin.noMatches && !ngModel.$valid) {
            // If no matches and we don't already have a valid group selected, reset the group
            // so the errors reset. If we don't do this past validation errors displayed even though
            // they are no longer pertinent.
            ngModel.$setPristine();
            ngModel.$setUntouched();
          }
          scope.groupJoin.matchingGroups = groups;
        })
        .finally(function() {
          scope.groupJoin.pending = false;
        });
      };

      elem.find('.ct-group-join-list').on('click', '.ct-group-join-group', function (event) {
        var groupData = $(this).data('group');
        ngModel.$setViewValue(groupData);
        scope.$apply();
      });
    }
  };
}]);