angular.module('regFormApp').factory('LoginService', 
  ['$rootScope', '$q', '$http', '$interval', '$modal', '$translate', 'LoaderService', '$location',
  function($rootScope, $q, $http, $interval, $modal, $translate, LoaderService, $location)
{

  /**
   * @type bool
   */
  var _loaded = false;

  /**
   * @type {Object}
   */
  var conf = {
    signIDs: true,
    enabledProviders: 'facebook,yahoo,google,twitter',
    sessionExpiration: 1209600, // 2 weeks
    newUsersPendingRegistration: true,
    APIKey: $('meta[name=bz-gigya-api-key]').attr('content')
  };

  /**
   * Default empty user
   * 
   * @returns {Object}
   */
  function noUser() {
    return {UID: "", isLoggedIn: false, isGuest: false};
  }

  /**
   * Currently logged in user
   * 
   * @type {Object}
   */
  var user = noUser();
 
  /**
   * If we have a cookie with the user's id, set it.
   * This is set in Bazu_Utils in the PHP application.
   */
  user.UID = $.cookie("CT_ACCOUNT_ID");

  /**
   * @type {Object}
   */
  var gigyaDeferred = $q.defer();

  /**
   * A promise where we wait for the gigya external library to be loaded
   * 
   * @type @exp;gigyaDeferred@pro;promise
   */
  var gigyaPromise = gigyaDeferred.promise;

  /**
   * Wait for gigya to be loaded
   * 200 iterations of 1/100th of a second = 20 seconds
   */
  var iterations = 200;
  var checkGigyaPromise;
  function checkGigya(iteration) {
    function done() {
      $interval.cancel(checkGigyaPromise);
      checkGigyaPromise = undefined;
    }
    if (typeof gigya === 'object') {
      gigyaDeferred.resolve(gigya);
      $rootScope.$broadcast('gigya.library.load-success');
      _loaded = true;
      done();
    }
    else if (iteration === (iterations - 1)) {
      gigyaDeferred.reject('Unable to load gigya library');
      $rootScope.$broadcast('gigya.library.load-fail');
      done();
    }
  }
  checkGigyaPromise = $interval(checkGigya, 100, iterations, false);


  /**
   * Social networking accounts ("Notifications")
   * @type Object
   *
   * This is used in Account::addServiceAccounts to associate facebook and twitter 
   * to a registering user. 
   */
  var notifications = null;

  /**
   * Return social network notifications.
   *
   * @return Object
   */
  function getNotifications() {
    return notifications;
  }

  /**
   * Generate events in response to gigya event, or to simulate a gigya event.
   *
   * @param gigyaResponse Response object from gigya.
   */
  function dispatch(gigyaResponse) {
    if (gigyaResponse.user.identities) {
      notifications = {identities: _.object(_.map(
        _.pick(gigyaResponse.user.identities, 'twitter', 'facebook'),
        function (val, key) { return [key, _.pick(val, 'providerUID', 'nickname', 'country')]; }
      ))};
    }
    $rootScope.$broadcast('gigya.event.' + gigyaResponse.eventName, gigyaResponse);
  }

  /**
   * Generate a login event. Closes the loader if open.
   *
   * @param gigyaResponse Response object from gigya.
   */
  function dispatchLogin(gigyaResponse) {
    LoaderService.hide();
    dispatch(gigyaResponse);
  }


  /**
   * Display an error message.
   *
   * @param messge string
   */
  function error(message) {
    if (!angular.isString(message)) {
      // A mess to make generic error handler. This handles strings and response objects.
      if (message.message) {
        message = message.message;
      }
      else if (message.data && message.data.message) {
        message = message.data.message;
      }
      else {
        message = 'Unknown error.';
      }
    }
    // @todo flash the error message, don't use alert
    alert(message);
  }

  function mergeAccounts(gigyaResponse, socialLoginData) {
    LoaderService.hide();
    var dialog = $modal.open({
      templateUrl: 'mergeAccountsModal.html',
      controller: 'mergeAccountsController',
      resolve: {
        mergeAccountList: function(){
          return _.uniq(_.map(socialLoginData.ambiguous, function(u) {
            var dob = '';
            if (typeof u.birthdate == 'string') {
              dob = ' (' + u.birthdate.substr( 5, 2 ) + 
                  '/' + u.birthdate.substr( 8, 2 ) + '/' + u.birthdate.substr( 2, 2 ) + ')';
            }
            var sexage = _.filter([u.sex, u.age]).join('/');
            return {
              id: u.id,
              name: u.first_name +" "+u.last_name,
              sexage: sexage,
              dob : dob,
              hometown : u.hometown
            };
          // make sure it is uniq
          }), false, function(x) { return x.id; });
        },
        provider: function(){
          return gigyaResponse.provider;
        }
      }
    });
    dialog.result.then(function mergeAccountsClose(selectedAccounts) {
      LoaderService.show();
      gigyaResponse.mergeAccounts = selectedAccounts.join(",");
      $http.post('/user/account/merge-accounts', $.param(gigyaResponse))
        .then(function mergeAccountResult(mergeResult){
          if (mergeResult.data.code === 0) {
            gigyaResponse.user = mergeResult.data.user;
            dispatchLogin(gigyaResponse);
          }
          else {
            error(mergeResult.data.message);
            dispatchLogin(gigyaResponse);
          }
        })
        .catch(error);
    }).catch(function mergeAccountsCancel() {
      // If they chose not to merge accounts, just do a normal dispatch of the original
      // results. We can still log in.
      dispatchLogin(gigyaResponse);
    });
  }

  /**
   * Check if gigya is loaded.
   * @return bool
   */
  function gigyaLoaded() {
    return _loaded;
  }


  /** 
   * Parameters for showShareUI, set by setupShare.
   */
  var shareParams = null;

  /**
   * Open the share UI if parameters have been set.
   */
  function _share() {
    if (shareParams) {
      gigyaPromise.then(function(gigya) {
        gigya.services.socialize.showShareUI(conf, shareParams);
      });
    }
  }


  /** 
   * Set up share parameters.
   *
   * @param providerInfo Information returned by /reg/register/ with instructions
   *    for gigya on how to share an event.
   */
  function setupShare(providerInfo) {
    if (!providerInfo) {
      return;
    }
    var params = {
      snapToElementID:        'share-button',
      operationMode:          'multiSelect',
      showMoreButton:         false,
      showEmailButton:        false,
      grayedOutScreenOpacity: 50,
      sessionExpiration:      0,
      showTooltips:           true,
      shortURLs:              'whenRequired'
    };
    providers = ['facebook', 'twitter', 'default'];
    gigyaPromise.then(function(gigya) {
      _.each(providers, function(provider) {
        var info = providerInfo[provider];
        if (typeof info !== "object") {
          return;
        }
        var ua = new gigya.services.socialize.UserAction();
        var uaKey = provider == 'default' ? 'userAction' : (provider + 'UserAction');
        ua.setUserMessage(info.message || $translate.instant("LOGIN.I_FOUND_THIS_INTERESTING"));
        ua.setTitle(info.title);
        if (info.subtitle) {
          ua.setSubtitle(info.subtitle);
        }
        ua.setDescription(info.description);
        ua.setLinkBack(info.href);
        _.each(info.actionLinks, function(actionLink) {
          ua.addActionLink(actionLink.title, actionLink.href);
        });
        _.each(info.mediaItems, function(mediaItem) {
          ua.addMediaItem(mediaItem);
        });
        params[uaKey] = ua;
      });
      shareParams = params;
    });
  }


  /**
   * Initialize gigya
   * 
   * @param {Object} params
   * @returns {undefined}
   */
  function init() {
    // Add handlers for gigya events.
    gigyaPromise.then(function(gigya) {
      function onLogin(gigyaResponse) {
        LoaderService.show();
        function socialLogin(socialLoginResponse) {
          switch (socialLoginResponse.data.code) {
            // Normal login.
            case 0:
              dispatchLogin(gigyaResponse);
              break;
            // This social media account is associated with more than one 
            // person in our accounts table. Let's try and match them up.
            case 1:
              mergeAccounts(gigyaResponse, socialLoginResponse.data);
              break;
            // case 2: confirmCreateAccount - but we always create
            default:
              error(socialLoginResponse.data.message);
              dispatchLogin(gigyaResponse);
              break;
          }
        }
        gigyaResponse.autoCreateAccount = true;
        $http.post('/user/account/social-login', $.param(gigyaResponse))
          .then(socialLogin)
          .catch(error);
      }
      gigya.socialize.addEventHandlers({
        onLogin: onLogin,
        onLogout: dispatch,
        onConnectionAdded: dispatch,
        onConnectionRemoved: dispatch
      });
    });
    // Check if user is already logged in through some gigya method and get their credentials.
    // This could trigger a login event.
    getUserInfo();
    
    // Open the gigya share dialog when share button clicked.
    // Have to attach to body because #share-button may not be loaded yet.
    $('body').on('click', '#share-button', _share);
  }


  /**
   * Get user info from gigya
   * 
   * @return Promise resolves to a gigya response
   * event gigya.event.login
   */
  function getUserInfo() {
    if (!gigyaLoaded()) {
      getUserInfoFromApplication({});
    } else {
      gigyaPromise.then(function(gigya) {
        gigya.services.socialize.getUserInfo(conf, {callback: getUserInfoFromApplication});
      });
    }
  }
  
  function getUserInfoFromApplication(response) {
    // We deleted requestParams in the original code. I don't know why we only do it here
    // and not elsewhere.
    if (response.requestParams) {
      delete response.requestParams;
    }
    var d = $q.defer();
    // User is newly logged in. Update the info.
    if (response.user && response.user.isConnected) {
      // Must use $.param() to coerce to jQuery format expecte by back-end
      // @see http://stackoverflow.com/a/12191613/895588 
      $http.post("/user/account/update-user-info", $.param(response))
        .success(function(data) {
          d.resolve(data);
        });
    }
    else {
      var params = $location.search();
      if (params.p && params.e) {
        $http.post('/user/account/check-token-validity', $.param(params))
        .success(function(data) {
          if (data.code !== 0) {
            error(data);
            return;
          }
          d.notify({code: 0, message: $translate.instant('LOGIN.AUTHENTICATED')});
          user = data.user;
          if (data.cookie) {
            setCookie(data.cookie);
          }
          // emulate the gigya getUserInfo() event
          $rootScope.$broadcast('gigya.event.login', {
            context: undefined,
            errorCode: 0,
            errorDetails: "",
            errorMessage: "",
            operation: "login",
            'status': "OK",
            statusMessage: "",
            user: data.user
          });
          d.resolve(data);
        });
      }
      else {
        $http.post('/user/account/sync-gigya-login', $.param(params))
        .success(function(data) {
          d.resolve(data);
        });
      }
    }

    d.promise.then(function(data) {
      var user = data.code === 0 && data.user ? data.user : noUser();
      user.isGuest = false;
      user.photoUrl = user.photoUrl ? user.photoUrl : '/img/user-no-pic.gif';
      response.user = user;
      // Re-use gigya.event.login. Getting the data is effectively the same as a login.
      response.eventName= 'login';
      dispatch(response);
    });
  }
    

  /**
   * Login
   * 
   * @param {Object} loginData
   * @returns {Object}
   */
  function login(loginData) {
    var d = $q.defer();
    d.notify({code: 0, message: $translate.instant('LOGIN.SIGNING_IN')});

    function setCookie(c) {
      var expires = null;
      if (c.expires) {
        var d = new Date();
        d.setDate(d.getDate() + c.expires);
        expires = d.toUTCString();
      }
      document.cookie = encodeURIComponent(c.name) + '=' + encodeURIComponent(c.value) +
        '; path=/; domain=' + c.domain + (expires ? ('; expires=' + expires) : '');
    }

    function error(data) {
      d.notify(_.pick(data, 'code', 'message'));
      d.reject(data);
    }

    $http.post('/user/account/bazu-login', $.param(loginData))
      .success(function(data) {
        if (data.code !== 0) {
          error(data);
          return;
        }
        d.notify({code: 0, message: $translate.instant('LOGIN.AUTHENTICATED')});
        user = data.user;
        if (data.cookie) {
          setCookie(data.cookie);
        }
        // emulate the gigya getUserInfo() event
        $rootScope.$broadcast('gigya.event.login', {
          context: undefined,
          errorCode: 0,
          errorDetails: "",
          errorMessage: "",
          operation: "login",
          'status': "OK",
          statusMessage: "",
          user: data.user
        });
        if (data.refreshPage) {
          location.reload();
        }
        d.resolve(data);
      })
      .error(function(data) {
        error({code: 1, message: $translate.instant('LOGIN.PROBLEM')});
      });
    return d.promise;
  }

  /**
   * Logout
   * 
   * @returns {Undefined}
   */
  function logout() {
    return gigya.services.socialize.logout(conf, {callback: applicationLogout});
  }

  /**
   * Logs out from application.
   * 
   * @param {Object} response
   * @returns {undefined}
   */
  function applicationLogout(response) {
    if (!response) {
      // Sometimes response is empty. I don't know why.
      // It seems I get called once with the correct logout response 
      // then get called immediately a second time with no response object. 
      // Just ignore the one with no response.
      return;
    }
    response.eventName = 'logout';
    user = {
      UID: '',
      isLoggedIn: false,
      // If you were logged in but then log out, we'll treat you as a guest
      // so you don't loose your place in the pages.
      isGuest: true
    };
    response.user = angular.copy(user);
    $http.post("/user/account/logout", $.param(response));
    dispatch(response);
  }

  /**
   * Adds social connection.
   * 
   * @param {String} provider
   * @returns {undefined}
   */
  function addConnection(provider) {
    gigyaPromise.then(function(gigya) {
      gigya.socialize.addConnection({provider: provider});
    });
  }

  /**
   * Removes social connection.
   * 
   * @param {String} provider
   * @returns {undefined}
   */
  function removeConnection(provider) {
    gigyaPromise.then(function(gigya) {
      gigya.socialize.removeConnection({provider: provider});
    });
  }

  /**
   * Embedes social login html in html tag with privided id.
   * 
   * @param {String} id
   * @returns {undefined}
   */
  function setSocLogin(id) {
    var params = {
      width:265,
      enabledProviders : 'facebook,googleplus,twitter',
      containerID: id,
      headerText: 'Use a Social Media Account',
      buttonsStyle:'fullLogo',
      lastLoginIndiciation: 'welcome',
      lastLoginButtonSize: 25,
      facepilePosition: 'bottom',
      autoDetectUserProviders: 'facebook',
      showTooltips: true,
      showTermsLink:false,
      hideGigyaLink:true
    };
    gigyaPromise.then(function(gigya) {
      gigya.services.socialize.showLoginUI(params);
    });
  }

  return {
    init: init,
    getUserInfo: getUserInfo,
    login: login,
    logout: logout,
    addConnection: addConnection,
    removeConnection: removeConnection,
    setupShare: setupShare,
    gigyaLoaded: gigyaLoaded,
    setSocLogin: setSocLogin,
    getNotifications: getNotifications,
    // We cannot unit test them if they are not public
    applicationLogout: applicationLogout,
    getUserInfoFromApplication: getUserInfoFromApplication,
    checkGigya: checkGigya
  };
}]);

regFormAppControllers.directive('socCon', ['LoginService', '$rootScope', 
    function (LoginService, $rootScope) {
  var providers = ['facebook', 'twitter'];
	return {
		restrict: 'E',
    require: 'ngModel',
    scope: {
      entry: '=ngModel'
    },
		template:
      '<div class="overflow">' +
        '<input ' +
           'type="button" ' +
           'class="twitterUpdate pull-left cb" ' +
           'ng-click="addConnection(' + "'twitter'" + ')" ' +
           'ng-disabled="entry.athleteUpdate.twitter">' +
        '<span ng-show="entry.athleteUpdate.twitter" class="tw-connected notification-container">' +
           '<button type="button" class="close" ' +
               'ng-click="removeConnection(' + "'twitter'" + ')">&times;</button>' +
           '<label>{{entry.firstName}} {{entry.lastName}}</label>' +
        '</span>' +
      '</div>',
    link: function($scope) {
      $scope.addConnection = function addConnection(provider) {
        LoginService.addConnection(provider);
      };
      $scope.removeConnection = function removeConnection(provider) {
        LoginService.removeConnection(provider);
      };
      function connectionAdded(value) {
        return function eventHandler(event, response) {
          $scope.$apply(function() {
            $scope.entry.athleteUpdate[response.provider] = value;
          });
        };
      }
      $rootScope.$on('gigya.event.connectionAdded', connectionAdded(true));
      $rootScope.$on('gigya.event.connectionRemoved', connectionAdded(false));
    }
  };
}]);
