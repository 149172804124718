angular.module('regFormApp').factory('AthlinksService', 
  ['$q', '$http', 'LoaderService', '$modal', 
  function($q, $http, LoaderService, $modal) {
  
  /**
   * Do not make any calls to athlinks if sync is disabled
   * 
   * @type Boolean
   */
  var syncEnabled = false;
  
  /**
   * partial athlinks racer data
   * @type {Object} 
   */
  var _defaultData = {
    firstname     : null,
    lastname      : null,
    email         : null,
    dob           : null,
    sex           : null,
    reg_option_id : null,
    partial_entry_id : null,
    eventID       : null,
    race_id       : null,
    city          : null,
    region_id     : null
  };
  
  /**
   * Registrant's partial_entry_id. The one we choosed to connect to athlinks.
   * @type {Number}
   */
  var partialEntryID = null;
  
  /**
   * All registrans partial data recorder after leaving location slide.
   * @type {Object}
   */
  var partialEntries  = {};
  
  /**
   * After data are matched to athlinks we will need to record tokens returned.
   * @type {Object}
   */
  var athlinksEntries = {};
  
  /**
   * Event id
   * @type {Number}
   */
  var eventID = null;
  
  /**
   * We are only connecting only one registrant.
   * -1 - no thanks
   *  0 - not connected
   *  1 - connected
   * 
   * @type Number
   */
  var athleteConnected = 0; 
  
  /**
   * Check if sync is enabled.
   * 
   * @returns {Boolean}
   */
  function isSyncEnabled() {
    return syncEnabled;
  }
  
  /**
   * Mark athlete as connected
   * @returns {undefined}
   */
  function setConnected() {
    athleteConnected = 1;
  }
  
  /**
   * Mark athlete as No thanks (reject connecting)
   * @returns {undefined}
   */
  function setNoThanks() {
    athleteConnected = -1;
  }
  
  /**
   * Check if athlete is connected
   * @returns {Boolean}
   */
  function isConnected() {
    return athleteConnected === 1;
  }
  
  /**
   * Check if athlete rejected athlinks connection offer.
   * @returns {Boolean}
   */
  function isNoThanks() {
    return athleteConnected === -1;
  }
  
  /**
   * If athlete didn't yet decided what he want then this will return TRUE.
   * @returns {Boolean}
   */
  function connectionPending() {
    return athleteConnected === 0;
  }
  
  /**
   * Initialize AthlinksService
   * 
   * @param {Number} appEventID
   * @returns {undefined}
   */
  function init(appEventID, canSync) {
    eventID     = appEventID;
    syncEnabled = canSync;
  }

  /**
   * Sets partial_entry_id of athlete selected for syncing
   * 
   * @param {Number} partialID
   * @returns {undefined}
   */
  function setEntry(partialID) {
    partialEntryID = partialID;
  }
  
  /**
   * Fetch selected partial entry id
   * 
   * @returns {Number}
   */
  function getPartialEntryId() {
    if(!partialEntryID) {
      //if partialEntryID is not set then take first one from athlinskEntry object
      return _.keys(getAthlinksEntries())[0];
    }
    return partialEntryID;
  }
  
  /**
   * Check if registrant selected entry for syncing. 
   * 
   * @returns {Boolean}
   */
  function isEntrySelected() {
    return partialEntryID !== null || getAthlinksEntryCount() === 1;
  }
  
  /**
   * Set partial entries.
   * 
   * @param {Object} entries
   * @returns {undefined}
   */
  function setPartialEntries(entries) {
    partialEntries = entries;
  }
  
  /**
   * Get partial entries.
   * 
   * @returns {Object}
   */
  function getPartialEntries() {
    return partialEntries;
  }
  
  /**
   * Build default athlinks data
   * 
   * @returns {Object}
   */
  function defaultData() {
    return angular.copy(_defaultData);
  }
  
  /**
   * Match ct-live athlete with athlinks database. If it is found then update 
   * athlinksEntries object with athlins response.
   * 
   * @param {Number} entry
   * @param {Number} raceID
   * @returns {$q@call;defer.promise}
   */
  function matchEntry(entry, raceID) {
    var deferred = $q.defer();
    if(!syncEnabled) {
      deferred.resolve(true);
      return deferred.promise;
    }
    $http.post('/reg/match-athlinks-entry', $.param({
      firstname     : entry.firstName,
      lastname      : entry.lastName,
      email         : entry.email,
      dob           : entry.dob,
      sex           : entry.sex,
      reg_option_id : entry.regOptionID,
      partial_entry_id : entry.partialEntryID,
      eventID       : eventID,
      race_id       : raceID,
      city          : entry.city,
      region_id     : entry.region
    })).then(function (response) {
      if (response.data.status === 0) {
        var key = response.data.partial_entry_id;
        athlinksEntries[key]           = response.data; 
        athlinksEntries[key].firstName = entry.firstName;
        athlinksEntries[key].lastName  = entry.lastName;
      }
      deferred.resolve(true);
    });
    return deferred.promise;
  }
  
  /**
   * No mather if registrant choosed to sync data or not we will send his data 
   * to athlinks.
   * 
   * @param {Boolean} noThanks - If set to true then entry will be marked as rejected
   * @returns {$q@call;defer.promise}
   */
  function sendData(noThanks) {
    var deferred = $q.defer();
    if(!syncEnabled) {
      deferred.resolve(false);
      return deferred.promise;
    }
    var partialId = getPartialEntryId();
    var entry = athlinksEntries[partialId];
    if(!noThanks) {
      LoaderService.show();
    }
    $http.post('/reg/entry-athlinks-response', $.param({
      eventID  : eventID,
      entry_id : partialEntries[partialId],
      token    : entry.token,
      atlinksAction : noThanks ? 'reject' : entry.action
    })).then(function (response) {
      deferred.resolve(response.data.accepted);
    }).finally(function() { 
      LoaderService.hide(); 
    });
    return deferred.promise;
  }
  
  /**
   * Check if any of the registered antries can be connected to athlinks.
   * This can happend if event or race are not linked to athlinks.
   * 
   * @returns {Boolean}
   */
  function hasAthlinksEntries() {
    return !_.isEmpty(athlinksEntries);
  }
  
  /**
   * Get entries ready to be connected to athlinks
   * 
   * @returns {Object}
   */
  function getAthlinksEntries() {
    return athlinksEntries;
  }
  
  /**
   * Get number of connectable entries.
   * 
   * @returns {Number}
   */
  function getAthlinksEntryCount() {
    return _.keys(getAthlinksEntries()).length;
  }
  
  /**
   * Opens dialogue for syncing athletes to athlinks.
   * 
   * @param {Object} scope instance to be used in modal
   * @returns {undefined}
   */
  function openAthleteSyncModal(scope) {
    if(!syncEnabled || !hasAthlinksEntries()) {
      return;
    }
    $modal.open({
      templateUrl: 'athlinksModal.html',
      controller: 'athlinksController',
      scope: scope
    });
  }
 
  return {
    defaultData: defaultData,
    matchEntry:  matchEntry,
    sendData: sendData,
    setEntry: setEntry,
    getPartialEntryId: getPartialEntryId,
    setPartialEntries: setPartialEntries,
    getPartialEntries: getPartialEntries,
    getAthlinksEntries: getAthlinksEntries,
    getAthlinksEntryCount: getAthlinksEntryCount,
    hasAthlinksEntries: hasAthlinksEntries,
    init: init,
    isEntrySelected: isEntrySelected,
    isSyncEnabled: isSyncEnabled,
    openAthleteSyncModal: openAthleteSyncModal,
    athlete: {
      setConnected: setConnected,
      setNoThanks: setNoThanks,
      isConnected: isConnected,
      isNoThanks: isNoThanks,
      connectionPending: connectionPending
    }
  };
}]);
